import { Modal } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import images from '../../../Utils/images';
import CheerioButton from '../../../Components/CheerioButton';
import colors from '../../../Utils/colors';
import Button from 'react-bootstrap/Button';
import SuccessModal from './SuccessModal';
import SpinnerLoader from '../../../Components/SpinnerLoader';

import '../../../../src/Pages/Templates/styles.css';
import { toast } from 'react-toastify';
import 'primeicons/primeicons.css';
import {
    setupautorecharge,
    orderPayment,
    setupautorechargepayment,
} from '../../../Services';

const RechargeAfterAutoTopUpsetModal = ({
    setAutomaticrechargemodalopen,
    isAutomaticrechargemodalopen,
    setIsPaymentSuccessModalOpen,
    setIsonetimerechargeSucceesmodelopen,
    isPartner = false,
    updatePartnerDashboard,
}) => {
    const handleClose = () => {
        console.log("button clicled")
        setAutomaticrechargemodalopen(false);
    };
    const authtoken = useSelector((state) => state.main.auth_token);
    const localdata = useSelector((state) => state.main.localdata);
    const [clicked, setClicked] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [totalAmount, SetTotalamount] = useState('')
    const [showAmountBreakup, setShowAmountBreakup] = useState(false)


    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }
    const handleInputChange = (event) => {
        if (/^\d*$/.test(event.target.value)) {
            setInputValue(event.target.value);
            setIsButtonEnabled(true);
            console.log(event.target.value)
            setClicked(null);
        }
        const total = (event.target.value * 1.18).toFixed(2);
        SetTotalamount(total);
        console.log("Total amount with GST", total);
    };

    const MobileWidth = window.innerWidth < 700 ? true : false;
    useEffect(() => {
        if (inputValue === '') {
            setClicked(null);
        }
    }, [inputValue]);


    const handleClick = (amount) => {
        const numericValue = parseInt(amount.replace('INR', ''), 10);
        if (clicked === amount) {
            setInputValue('');
            setClicked(null);
        } else {
            setInputValue(numericValue);
            setClicked(amount);
            const total = (numericValue * 1.18).toFixed(2);
            SetTotalamount(total);
            setIsButtonEnabled(true);

        }
    };


    async function displayonetimetopup(totalAmount) {
        setIsLoading(true);
        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');
        if (!res) {
            setIsLoading(false)
            alert('Razorpay SDK failed to load. Are you online?');
            return;
        }
        // const numericAmount = parseInt(totalAmount, 10);
        const jsonPlan = {
            amount: totalAmount,
        };
        if (totalAmount > 0) {
            const data = await orderPayment(jsonPlan, authtoken);
            console.log("payment data", data);
            const options = {
                key: 'rzp_live_1IEjlHGI3q0yMR',
                order_id: data?.response?.orderId,
                amount: data?.response?.amount,
                currency: data?.response?.currency,
                name: 'Order',
                description: 'Order',
                handler: function (response) {
                    setIsLoading(false)
                    toast.success('Your recharge is done successfully.', { position: 'top-center' });
                    // window.location.reload();
                    setIsonetimerechargeSucceesmodelopen(true);
                    setAutomaticrechargemodalopen(false);
                },
            };

            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
            setIsLoading(false)
        } else {
            setIsLoading(false)
            toast.error('Amount has to be greater than 0');
        }
    }
    const handleonetimetopup = () => {
        displayonetimetopup(totalAmount);
    }

    return (
        <div>
            {isLoading && (
                <div className="d-flex flex-column justify-content-center align-items-center w-100"
                    style={{
                        position: 'fixed', // Make it fixed to overlay the entire screen
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                        zIndex: 9999,
                    }}
                >
                    <SpinnerLoader></SpinnerLoader>
                </div>
            )}
            <Modal
                show={isAutomaticrechargemodalopen}
                onHide={handleClose}
                className="automatic-modal-after-model"
            >
                <Modal.Body className="automatic-modal-body" style={{ padding: '3%', marginTop: '-10px' }}>
                    <div style={{ width: '100%', padding: '.5%', }}>
                        <div style={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                            <img
                                src={images.WalletIcon}
                                style={{
                                    padding: '2%',
                                    height: MobileWidth ? '45%' : 'auto',
                                    width: MobileWidth ? '40%' : 'auto',
                                }}
                            />
                            <p style={{ fontWeight: 700, fontSize: '20px', paddingLeft: '10px' }}>
                                Never run out of credits
                                <span
                                    style={{
                                        display: 'block',
                                        fontWeight: 400,
                                        fontSize: '16px',
                                        color: '#666666',
                                        paddingTop: '2%',
                                    }}
                                >
                                    {isPartner ? 'Add balance to your wallet' : 'Add money to your wallet'}
                                </span>
                            </p>
                            <img
                                src={images.CMClose}
                                style={{
                                    cursor: 'pointer',
                                    height: 26,
                                    width: 26,
                                    marginLeft: 'auto',
                                    marginTop: '-20px'
                                }}
                                onClick={handleClose}
                            />
                        </div>
                        <div style={{ marginBottom: '6px', marginLeft: '2%' }}>
                            <p style={{ fontWeight: 600, fontSize: '16px', marginLeft: '0%', marginTop: '8px', }}>
                                Description
                            </p>
                            <ul style={{ color: '#666666', paddingLeft: '20px', paddingRight: '2%', marginBottom: '6px' }}>
                                <li style={{ paddingBottom: '8px' }}>
                                    This balance will be used as your WhatsApp Conversations & SMS Balance
                                </li>
                                <li style={{ paddingBottom: '8px' }}>
                                    The amount will be deducted from your wallet as per the conversation & SMS usage
                                </li>
                                <li style={{ paddingBottom: '8px' }}>
                                    Per conversation & SMS pricing will vary as per the country terms.
                                </li>
                                <li style={{ paddingBottom: '8px' }}>
                                    You will be able to track & monitor each transaction by accessing your wallet history
                                </li>
                            </ul>
                        </div>
                        <label style={{ display: 'block', marginBottom: '8px', fontWeight: 700, marginLeft: '10px' }}>
                            Top Up Amount
                        </label>
                        <input
                            type="number"
                            style={{
                                width: '95%',
                                padding: '10px',
                                marginBottom: '6px',
                                marginLeft: '10px',
                                borderRadius: '8px',
                                borderColor: '#ddd',
                            }}
                            className="form-control shadow-none"
                            placeholder="₹ 0"
                            inputMode="numeric"
                            value={inputValue}
                            onChange={handleInputChange}
                            min="0"
                            step="1"
                        />
                        <label style={{ display: 'block', marginBottom: '6px', fontWeight: 700, marginLeft: '10px' }}>
                            Quick selectors
                        </label>
                        <div style={{ paddingLeft: '2%', display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                            {['5000', '10000', '25000'].map((amount) => (
                                <Button
                                    key={amount}
                                    variant="outline-secondary"
                                    style={{
                                        borderRadius: '8px',
                                        cursor: 'pointer',
                                        color: clicked === `INR${amount}` ? '#8E53F7' : '#666666',
                                        borderColor: clicked === `INR${amount}` ? '#8E53F7' : '#666666',
                                        backgroundColor: clicked === `INR${amount}` ? '#ebd6fd' : 'white',
                                        width: '67px',
                                        height: '25px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    onClick={() => handleClick(amount)}
                                >
                                    ₹{amount}
                                </Button>
                            ))}
                        </div>
                        <div style={{ alignItems: 'center', marginTop: showAmountBreakup ? '1.2%' : '22.5%', marginBottom: '4%' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    padding: '5px 10px',
                                    fontWeight: 600,
                                    fontSize: '16px',
                                    marginLeft: '0px',
                                    marginBottom: '6px'
                                }}
                                onClick={() => setShowAmountBreakup(!showAmountBreakup)}
                            >
                                Amount Breakup
                                <i
                                    className={`pi ${showAmountBreakup ? 'pi-angle-up' : 'pi-angle-down'}`}
                                    style={{ paddingLeft: '68.5%' }}
                                ></i>
                            </div>
                            {showAmountBreakup && (
                                <div
                                    style={{
                                        padding: '10px 10px 10px 10px',
                                        marginLeft: '10px',
                                        backgroundColor: '#f9f9f9',
                                        border: '1px solid #ddd',
                                        borderRadius: '8px',
                                        width: '94%',
                                        marginBottom: '8px'
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            lineHeight: '26px',
                                        }}
                                    >
                                        <p style={{ fontWeight: 400 }}>Top-up amount</p>
                                        <p style={{ fontWeight: 400, }}> ₹{inputValue}</p>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            lineHeight: '26px',
                                        }}
                                    >
                                        <p style={{ fontWeight: 400 }}>GST</p>
                                        <p style={{ fontWeight: 400 }}> ₹{(inputValue * 0.18).toFixed(2)}</p>
                                    </div>
                                    <hr
                                        style={{
                                            marginBottom: '2px',
                                            marginTop: '2px'
                                        }}
                                    ></hr>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            lineHeight: '26px',
                                        }}
                                    >

                                        <p style={{ fontWeight: 400 }}>Total amount</p>
                                        <p style={{ fontWeight: 400 }}>₹{totalAmount}</p>
                                    </div>
                                </div>
                            )}
                            <CheerioButton
                                borderStyle={{ borderColor: colors.primary03, width: '96%', marginLeft: '6px' }}
                                textStyle={{ color: '#fff' }}
                                btnText={`Recharge now ₹(${totalAmount})`}
                                backColor={isButtonEnabled ? colors.primary03 : '#9370db'}
                                onclick={handleonetimetopup}
                                disabled={!isButtonEnabled}
                            />
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );

};

export default RechargeAfterAutoTopUpsetModal;