import React, { useEffect, useState } from 'react';
import './styles.css';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import CheerioButton from '../../../Components/CheerioButton';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { createAssistant } from '../../../Services';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { NewLoader } from '../../../Components/SpinnerLoader';
import { eventsend } from '../../../Config/analyticsFunctions';

const CreateAssistantsModal = ({ setOpenCreateAssistantsModal, getAllAssistantsAPIcall }) => {
    const [instructions, setInstructions] = useState('');
    const [name, setName] = useState('');
    const [tool, setTool] = useState('');
    const [success, setSuccess] = useState(false);
    const [failure, setFailure] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const localdata = useSelector((state) => state.main.localdata);
    const _id = useSelector((state) => state.main._id);
    const authtoken = useSelector((state) => state.main.auth_token);

    useEffect(() => {
        eventsend("Create_assistant_ChatGPT_integration_page_clicked");
    }, [])

    useEffect(() => {
        if (name) {
            console.log('assistant name ===> ', name);
        }
    }, [name]);
    useEffect(() => {
        if (tool) {
            console.log('assistant tool ===> ', tool);
        }
    }, [tool]);
    useEffect(() => {
        if (instructions) {
            console.log('instructions value ===> ', instructions);
        }
    }, [instructions]);

    const OKtoProceed = instructions && name && tool;

    useEffect(() => {
        if (OKtoProceed) {
            console.log('It is OK to Proceed');
            console.log('Final Instructions ===> ', instructions);
            console.log('Final Name ===> ', name);
            console.log('Final Tool ===> ', tool);
        }
    }, [OKtoProceed]);

    const OpenAiCreateAssistantsAPIcall = () => {
        let data = {
            user_id: localdata?._id,
            name: name,
            instructions: instructions,
            tool: tool,
        };
        let token = authtoken;
        setIsLoading(true);
        createAssistant(token, data).then((res) => {
            if (res?.flag) {
                console.log('Successfully Created Assistant ===> ', res);
                setSuccess(true);
            } else {
                toast.error('Error Creating Assistant');
                setFailure(true);
            }
        });
        setIsLoading(false);
    };

    return (
        <>
            <div className="DarkBg" onClick={() => { }}>
                {isLoading ?
                    <div
                        className="d-flex flex-column align-items-center"
                        style={{ marginTop: '30%', height: '100%', width: '100%', overflowY: 'hidden' }}
                    >
                        <NewLoader></NewLoader>
                        <p style={{ fontWeight: '700', fontSize: '18px' }}>Processing your request</p>
                        <CheerioButton
                            borderStyle={{
                                marginBlock: 8,
                                borderColor: colors.primary03,
                                width: '25%',
                                paddingBlock: 10,
                                marginInlineEnd: 16,
                            }}
                            textStyle={{
                                fontSize: 14,
                                fontWeight: 600,
                                color: colors.white,
                            }}
                            btnText={'Back to request'}
                            backColor={colors.primary03}
                            onclick={() => {
                                // navigate('/creator/contacts/labels');
                            }}
                        />
                    </div> :
                    <div className="Center">
                        <div
                            className="d-flex flex-column justify-content-between align-items-center"
                            style={{
                                width: 600,
                                height: 570,
                                backgroundColor: colors.white01,
                                borderRadius: 16,
                                zIndex: 0,
                                border: `1px solid ${colors.borderwhite}`,
                                paddingBlock: 30,
                                paddingInline: 30,
                                overflowY: 'auto',
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            {success ? (
                                <>
                                    <div>
                                        <img
                                            src={images.CMClose}
                                            style={{
                                                height: 32,
                                                width: 32,
                                                objectFit: 'contain',
                                                cursor: 'pointer',
                                                position: 'absolute',
                                                right: '10px',
                                                top: '10px'
                                            }}
                                            onClick={() => {
                                                getAllAssistantsAPIcall();
                                                setOpenCreateAssistantsModal(false);
                                            }}
                                        />
                                    </div>
                                    <div className="d-flex flex-column justify-content-center align-items-center w-100">
                                        <img
                                            src={images.CATSuccess}
                                            style={{ height: 160, width: 160, objectFit: 'contain' }}
                                        ></img>
                                        <p
                                            style={{
                                                padding: 0,
                                                margin: 0,
                                                fontSize: 24,
                                                fontWeight: 600,
                                                color: colors.black,
                                                marginBlock: 16,
                                                textAlign: 'center',
                                            }}
                                        >
                                            {'Your Custom GPT Assistant has been created successfully'}
                                        </p>
                                    </div>
                                    <div className="d-flex flex-row justify-content-center align-items-center w-100">
                                        <CheerioButton
                                            borderStyle={{
                                                marginBlock: 8,
                                                borderColor: colors.primary03,
                                                width: '40%',
                                                paddingBlock: 10,
                                                marginInlineEnd: 16,
                                            }}
                                            textStyle={{ fontSize: 14, fontWeight: 600, color: colors.white }}
                                            btnText={'Back to Integration'}
                                            backColor={colors.primary03}
                                            onclick={() => {
                                                navigate('/creator/discoverPlatforms/setupChatGpt');
                                            }}
                                        />
                                        <CheerioButton
                                            borderStyle={{
                                                marginBlock: 8,
                                                width: '40%',
                                                paddingBlock: 10,
                                                marginInlineStart: 16,
                                                border: `1px solid ${colors.primary03}`,
                                            }}
                                            textStyle={{ fontSize: 14, fontWeight: 600, color: colors.primary03 }}
                                            btnText={'Back to home'}
                                            backColor={colors.white}
                                            onclick={() => {
                                                navigate('/creator');
                                            }}
                                        />
                                    </div>
                                </>
                            ) : failure ? (
                                <>
                                    <div></div>
                                    <div className="d-flex flex-column justify-content-center align-items-center w-100">
                                        <img
                                            src={images.CATFail}
                                            style={{ height: 160, width: 160, objectFit: 'contain' }}
                                        ></img>
                                        <p
                                            style={{
                                                padding: 0,
                                                margin: 0,
                                                fontSize: 24,
                                                fontWeight: 600,
                                                color: colors.black,
                                                marginBlock: 16,
                                                textAlign: 'center',
                                            }}
                                        >
                                            {
                                                'Failed to create Assistant from Chat GPT at this time. Please try again later.'
                                            }
                                        </p>
                                    </div>
                                    <CheerioButton
                                        borderStyle={{
                                            marginBlock: 8,
                                            width: '40%',
                                            paddingBlock: 10,
                                            marginInlineStart: 16,
                                            border: `1px solid ${colors.primary03}`,
                                        }}
                                        textStyle={{ fontSize: 14, fontWeight: 600, color: colors.primary03 }}
                                        btnText={'Back to Assistants'}
                                        backColor={colors.white}
                                        onclick={() => {
                                            console.log('back to home clicked . . .');
                                            setOpenCreateAssistantsModal(false);
                                        }}
                                    />
                                </>
                            ) : (
                                <>
                                    <div className="d-flex flex-column justify-content-center align-items-start w-100">
                                        <div className="d-flex flex-row justify-content-between align-items-center w-100">
                                            <h2
                                                style={{
                                                    margin: 0,
                                                    padding: 0,
                                                    fontSize: 20,
                                                    fontWeight: 700,
                                                    color: colors.black,
                                                }}
                                            >
                                                {'Create a custom assistant from Chat GPT'}
                                            </h2>
                                            <img
                                                src={images.CMClose}
                                                style={{ height: 32, width: 32, objectFit: 'contain', cursor: 'pointer' }}
                                                onClick={() => {
                                                    setOpenCreateAssistantsModal(false);
                                                }}
                                            ></img>
                                        </div>
                                        <p
                                            style={{
                                                padding: 0,
                                                margin: 0,
                                                marginBlock: 16,
                                                fontSize: 16,
                                                fontWeight: 400,
                                                color: colors.greys04,
                                            }}
                                        >
                                            {
                                                'You can create your Custom GPT Assistants here'
                                            }
                                        </p>
                                    </div>
                                    <div
                                        className="d-flex flex-column justify-content-center align-items-start w-100"
                                        style={{ marginBlock: 16 }}
                                    >
                                        <h4
                                            style={{
                                                padding: 0,
                                                margin: 0,
                                                fontSize: 16,
                                                fontWeight: 600,
                                                color: colors.black,
                                            }}
                                        >
                                            {"Set your Assistant's Name"}
                                        </h4>
                                        <InputGroup className="" style={{ marginBlockStart: 16 }}>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter here"
                                                className="w-100 btncustom"
                                                style={{ borderRadius: 8 }}
                                                value={name}
                                                onChange={(e) => {
                                                    setName(e.target.value);
                                                }}
                                            />
                                        </InputGroup>
                                    </div>
                                    <div
                                        className="d-flex flex-column justify-content-center align-items-start w-100"
                                        style={{ marginBlock: 16 }}
                                    >
                                        <h4
                                            style={{
                                                padding: 0,
                                                margin: 0,
                                                fontSize: 16,
                                                fontWeight: 600,
                                                color: colors.black,
                                            }}
                                        >
                                            {'Tool'}
                                        </h4>
                                        <InputGroup className="btncustom" style={{ width: '100%', marginBlockStart: 16 }}>
                                            <Form.Select
                                                className="btncustom"
                                                aria-label="Set assistant tool"
                                                style={{ fontSize: 16, fontWeight: 400, color: colors.greys04 }}
                                                value={tool}
                                                onChange={(e) => {
                                                    setTool(e.target.value);
                                                }}
                                            >
                                                <option>Select tool</option>
                                                <option value="code_interpreter">Code Interpreter</option>
                                                <option value="file_search">File Search</option>
                                            </Form.Select>
                                        </InputGroup>
                                    </div>

                                    <div
                                        className="d-flex flex-column justify-content-center align-items-start w-100"
                                        style={{ marginBlock: 16 }}
                                    >
                                        <h4
                                            style={{
                                                padding: 0,
                                                margin: 0,
                                                fontSize: 16,
                                                fontWeight: 600,
                                                color: colors.black,
                                            }}
                                        >
                                            {'Give Instructions to your Assistant'}
                                        </h4>
                                        <InputGroup className="" style={{ marginBlockStart: 16 }}>
                                            <Form.Control
                                                type="text" 
                                                as={'textarea'}
                                                rows={2}
                                                placeholder="Enter here"
                                                className="w-100 btncustom"
                                                style={{ borderRadius: 8, fontSize: 16, fontWeight: 400 }}
                                                value={instructions}
                                                onChange={(e) => {
                                                    setInstructions(e.target.value);
                                                }}
                                            />
                                        </InputGroup>
                                    </div>
                                    <div className="d-flex">
                                        <CheerioButton
                                            borderStyle={{ paddingBlock: 10, marginBlock: 24 }}
                                            textStyle={{ fontSize: 12, fontWeight: 600, color: colors.white }}
                                            btnText={'Create Assistant'}
                                            backColor={colors.black}
                                            onclick={() => {
                                                OpenAiCreateAssistantsAPIcall();
                                            }}
                                            disabled={OKtoProceed ? false : true}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>}
            </div>
        </>
    );
};

export default CreateAssistantsModal;
