import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import colors from '../../Utils/colors';
import images from '../../Utils/images';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Button from 'react-bootstrap/esm/Button';
import Dropzone from 'react-dropzone';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useSelector } from 'react-redux';
import { contactlabellistAPI, contactlabellistWithoutCountAPI, importContactsCSVApi } from '../../Services';
import ReactGA from 'react-ga4';
import { eventsend } from '../../Config/analyticsFunctions';
import ProgressBar from 'react-bootstrap/ProgressBar';
import './index.css';
import { FileUploader } from 'react-drag-drop-files';
import Alert from 'react-bootstrap/Alert';

const ImportContacts = () => {
  const navigate = useNavigate();
  const [fileUpload, setFileUpload] = useState(false);
  const [addLabel, setAddLabel] = useState(false);
  const [CSVfile, setCSVfile] = useState();
  const [label, setLabel] = useState('');
  const [resmessage, setresmessage] = useState('');
  const csvref = useRef();
  // const drop = React.useRef(null)
  const _id = useSelector((state) => state.main._id);
  const authtoken = useSelector((state) => state.main.auth_token);

  const [countOfProgress, setCountOfProgress] = useState(0);
  const [dragActive, setDragActive] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const [labelList, setLabelList] = useState([]);

  useEffect(() => {
    ReactGA.event('creator_import_contacts_pageview');
    eventsend('creator_import_contacts_pageview');
    getlabellistapicall();
  }, []);

  const getlabeldate = (e) => {
    const date = new Date(e);
    const dd = date.getDate();
    const mm = date.getMonth() + 1;
    const MM = mm < 10 ? '0' + mm : mm;
    const yy = date.getFullYear();
    return dd + '-' + MM + '-' + yy;
  };

  const getlabellistapicall = () => {
    let token = authtoken;
    // contactlabellistAPI(token).then((res) => {
    contactlabellistWithoutCountAPI(token).then((res) => {
      if (res?.flag) {
        setLabelList(res.data.labelList);
        console.log('6666666666', res);
      } else {
        console.log('err===>', res);
      }
    });
  };

  useEffect(() => {
    if (labelList) {
      console.log('list =====> ', labelList);
    }
  }, [labelList]);

  // useEffect(()=>{
  //   drop.current.addEventListener('dragover', handleDragOver);
  //   drop.current.addEventListener('drop', handleDrop);
  //   return () => {
  //     drop.current.removeEventListener('dragover', handleDragOver);
  //     drop.current.removeEventListener('drop', handleDrop);
  //   };

  // },[])

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (
      e.dataTransfer.files &&
      e.dataTransfer.files[0] &&
      e.dataTransfer.files[0].type == 'text/csv'
    ) {
      setCSVfile(e.dataTransfer.files[0]);
    } else {
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 2000);
    }
  };

  const importContactsapicall = () => {
    let data = {
      file: CSVfile,
      user_id: _id,
      label: label,
    };
    let token = authtoken;
    // importContactsCSVApi(data, token).then((res) => {
    importContactsCSVV2Api(data, token).then((res) => {
      if (res.flag) {
        console.log('==================================');
        console.log('res =======> ', res);
        console.log('==================================');
        setresmessage(res.data.resultMessage);
      } else {
        console.log(res);
      }
    });
  };

  useEffect(() => {
    if (resmessage.length > 0) {
      setTimeout(() => {
        setAddLabel(false);
        setFileUpload(false);
        navigate('/creator');
      }, 2000);
    }
  }, [resmessage]);

  useEffect(() => {
    if (CSVfile) {
      ReactGA.event('upload_csv_clicked', { file: CSVfile });
      eventsend('upload_csv_clicked', { file: CSVfile });
      console.log('==========================================');
      console.log('==========================================');
      console.log('=======>', CSVfile);
      console.log('==========================================');
      console.log('==========================================');
      setFileUpload(!fileUpload);
    }
  }, [CSVfile]);

  useEffect(() => {
    if (CSVfile) {
      const timer = setInterval(() => {
        setCountOfProgress((oldProgress) => {
          if (oldProgress == 100) clearInterval(timer);
          return Math.min(oldProgress + Math.random() * 10, 100);
        });
      }, 200);
    }
  }, [CSVfile]);

  useEffect(() => {
    if (label) {
      ReactGA.event('import_contacts_label_added', { label: label });
      eventsend('import_contacts_label_added', { label: label });
    }
  }, [label]);

  // const handleDrag = function(e){

  //   if(e.type === 'dragenter' || e.type === 'dragover'){
  //     setDragActive(true);
  //   }else if(e.type === 'dragleave'){
  //     setDragActive(false);
  //   }
  // }

  return (
    <>
      <div
        className="w-100 d-flex flex-row justify-content-between align-items-center pb-3"
        style={{ height: 'calc(100% - 80px)' }}
      >
        <div
          className="w-50 d-flex flex-column p-3 justify-content-between align-items-center h-100 ms-4 me-2"
          style={{ backgroundColor: colors.white, borderRadius: 16 }}
        >
          {showAlert && (
            <>
              <Alert
                variant="danger"
                onClose={() => setShowAlert(false)}
                dismissible
                style={{ position: 'absolute', top: 20 }}
              >
                <Alert.Heading>Wrong file format uploaded</Alert.Heading>
                <p>
                  Please upload only .csv files. Download the sample contacts file to know more.
                </p>
              </Alert>
            </>
          )}
          <div className="w-100 d-flex flex-column justify-content-center align-items-center">
            <text className="my-2" style={Styles.txtHeader}>
              {'Import Contacts'}
            </text>
            <text
              className="my-2"
              style={{ ...Styles.txtBody, flexWrap: 'wrap', textAlign: 'center' }}
            >
              {
                'Import contacts on Cheerio via CSV file and send them Emails, SMS & WhatsApp messages easily.'
              }
            </text>
          </div>

          {addLabel ? (
            <>
              <div
                className="d-flex flex-row justify-content-between align-items-center"
                style={{ width: '60%', marginTop: 48 }}
              >
                <text style={Styles.txtBody}>{`${CSVfile.name}`}</text>
                <img
                  src={images.CreatorClose}
                  style={{ height: 30, width: 30, objectFit: 'contain', cursor: 'pointer' }}
                  onClick={() => {
                    setAddLabel(false);
                    setCSVfile(null);
                    setFileUpload(false);
                    setCountOfProgress(0);
                  }}
                ></img>
              </div>
              <text
                className=""
                style={{
                  ...Styles.txtBody,
                  width: '60%',
                  marginBottom: 48,
                  marginTop: 16,
                  color: colors.primary03,
                }}
              >
                {resmessage ? resmessage : ''}
              </text>
              <text className="" style={{ ...Styles.txtBody, width: '60%', fontWeight: 700 }}>
                {'Add label'}
              </text>
              <InputGroup className="" style={{ width: '60%' }}>
                <Form.Control
                  // style={{height:40,backgroundColor:colors.white04}}
                  style={{ ...Styles.inputStyle, ...Styles.txtBody, color: colors.greys01 }}
                  placeholder="Enter label name"
                  aria-label="Add label"
                  aria-describedby="Label for contacts csv file"
                  className="btncustom"
                  value={label}
                  onInput={(e) => setLabel(e.target.value)}
                />
              </InputGroup>
              {/* <div className='d-flex flex-row justify-content-between align-items-center px-2' style={{...Styles.inputStyle}}>
            <text style={{...Styles.txtBody,color:colors.greys01}}>{'Enter label name'}</text>
          </div> */}
              <text
                className=""
                style={{
                  ...Styles.txtBody,
                  color: colors.greys04,
                  width: '60%',
                  fontWeight: 400,
                  marginBottom: 48,
                }}
              >
                {
                  'Create a label for each customer segment. You can add multiple sheets under same label.'
                }
              </text>
            </>
          ) : (
            <>
              <div
                className="d-flex flex-column justify-content-center align-items-center p-3"
                style={{
                  cursor: 'pointer',
                  height: '30vh',
                  width: '70%',
                  marginTop: 32,
                  marginBottom: 32,
                  backgroundColor: colors.primary01,
                  ...Styles.borderDashed,
                }}
                // onClick={()=>{setFileUpload(!fileUpload)}}
                // onDrop={()=>{
                //   if(!CSVfile){
                //     csvref.current.click();
                //   }
                //   }}
                // ref={drop}
                onDragEnter={handleDrag}
                onDragOver={handleDrag}
                onDragExit={handleDrag}
                onDrop={handleDrop}
                onClick={() => {
                  if (!CSVfile) {
                    csvref.current.click();
                  }
                }}
              >
                {fileUpload ? (
                  <>
                    <div
                      className="d-flex flex-row justify-content-center align-items-center"
                      style={{ width: '80%' }}
                    >
                      <text
                        style={{
                          ...Styles.txtBody,
                          paddingInline: 10,
                          width: '100%',
                          textAlign: 'center',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          wordBreak: 'break-all',
                        }}
                      >{`${CSVfile.name}`}</text>
                      <img
                        onClick={() => {
                          setCSVfile(null);
                          setCountOfProgress(0);
                          setFileUpload(!fileUpload);
                        }}
                        src={images.CreatorClose}
                        style={{ height: 30, width: 30, objectFit: 'contain' }}
                      ></img>
                    </div>
                    <div
                      className="w-100 py-3 px-2 d-block flex-column justify-content-center align-items-center"
                      style={{ height: 'auto' }}
                    >
                      <ProgressBar
                        variant="customcolor"
                        style={{ color: colors.primary03 }}
                        now={countOfProgress}
                      />
                    </div>
                    <text className="my-3" style={Styles.txtBody}>{`File uploaded (${parseInt(
                      countOfProgress
                    )}%)`}</text>
                  </>
                ) : (
                  <>
                    <text style={Styles.txtBody}>
                      <text style={{ color: colors.linkblue }}>{'Upload '}</text>
                      {'or Drag and drop here'}
                    </text>
                  </>
                )}
              </div>
              <Link
                onClick={() => {
                  ReactGA.event('import_contacts_download_sample_csv_clicked');
                  eventsend('import_contacts_download_sample_csv_clicked');
                }}
                to="/sample-contacts-list.csv"
                target="_blank"
                download
                className="my-2"
                style={{
                  ...Styles.txtBody,
                  color: colors.linkblue,
                  cursor: 'pointer',
                  textDecoration: 'none',
                }}
              >
                {'Download sample file'}
              </Link>

              {/* <text className='my-2' style={{...Styles.txtBody,color:colors.linkblue,cursor:'pointer'}} onClick={()=>{onclickdownloadsample()}}>{'Download sample file'}</text> */}
            </>
          )}

          {/* { fileUpload ? (
          <> */}
          <Button
            style={{
              height: 40,
              backgroundColor: colors.primary03,
              marginTop: 16,
              marginBottom: 16,
              width: '20%',
              borderRadius: 8,
              borderWidth: 0,
              color: colors.white,
              fontSize: 16,
              fontWeight: 600,
            }}
            // disabled={addLabel ? label ? false : true : false }
            className="btncustom"
            disabled={(fileUpload && !addLabel) || label ? false : true}
            onClick={() => {
              if (addLabel) {
                ReactGA.event('import_contacts_save_button_clicked');
                eventsend('import_contacts_save_button_clicked');
                importContactsapicall();
                // setAddLabel(false);
                // setFileUpload(false);
                // navigate('/creator');
              } else {
                ReactGA.event('import_contacts_next_button_clicked');
                eventsend('import_contacts_next_button_clicked');
                setAddLabel(true);
              }
            }}
          >
            {addLabel ? 'Save' : 'Next'}
          </Button>
          {/* </> */}
          {/* ):(<></>)} */}

          <input
            type="file"
            style={{ display: 'none' }}
            ref={csvref}
            accept=".csv"
            value=""
            onChange={(e) => {
              const file = e.target.files[0];
              if (file) {
                setCSVfile(file);
              } else {
                setCSVfile(null);
              }
            }}
          />

          {/* <button onClick={()=>{navigate('/creator')}}>BACK TEST</button> */}
        </div>
        <div
          className="w-50 d-flex flex-column px-4 py-3 justify-content-between align-items-center h-100 ms-2 me-4"
          style={{ backgroundColor: colors.white, borderRadius: 16 }}
        >
          <text className="my-2 w-100" style={Styles.txtHeader}>
            {'Uploaded Contacts'}
          </text>

          <div
            className="w-100 d-flex flex-column align-items-center justify-content-start verythinscroll px-1"
            style={{ height: '70vh', overflowY: 'auto' }}
          >
            <div
              className="d-flex flex-row justify-content-center align-items-center w-100"
              style={{ height: 40, backgroundColor: colors.white03 }}
            >
              <div
                className="d-flex h-100 justify-content-center align-items-center"
                style={{ width: '40%', borderRight: '1px solid #E6E6E6' }}
              >
                <text style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                  {'Label'}
                </text>
              </div>
              <div
                className="d-flex h-100 justify-content-center align-items-center"
                style={{ width: '30%', borderRight: '1px solid #E6E6E6' }}
              >
                <text style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                  {'Contacts'}
                </text>
              </div>
              <div
                className="d-flex h-100 justify-content-center align-items-center"
                style={{ width: '30%' }}
              >
                <text style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                  {'Added on'}
                </text>
              </div>
            </div>
            {labelList &&
              labelList.map((item) => {
                return (
                  <>
                    <div
                      key={`key${item?._id}`}
                      className="d-flex flex-row justify-content-center align-items-center w-100"
                      style={{ height: 40 }}
                    >
                      <div
                        className="d-flex h-100 justify-content-start align-items-center ps-2"
                        style={{ width: '40%', borderRight: '1px solid #E6E6E6' }}
                      >
                        <text
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.black,
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            wordBreak: 'break-all',
                          }}
                        >
                          {item?.label}
                        </text>
                      </div>
                      <div
                        className="d-flex h-100 justify-content-center align-items-center"
                        style={{ width: '30%', borderRight: '1px solid #E6E6E6' }}
                      >
                        <text style={{ fontSize: 16, fontWeight: 400, color: colors.black }}>
                          {item?.count}
                        </text>
                      </div>
                      <div
                        className="d-flex h-100 justify-content-center align-items-center"
                        style={{ width: '30%' }}
                      >
                        <text style={{ fontSize: 16, fontWeight: 400, color: colors.black }}>
                          {getlabeldate(item?.createdAt)}
                        </text>
                      </div>
                    </div>
                  </>
                );
              })}
          </div>

          <div style={{ width: '100%', height: 10 }}></div>
        </div>
      </div>
    </>
  );
};

export default ImportContacts;

const Styles = {
  txtHeader: {
    fontSize: 24,
    fontWeight: 700,
    color: colors.black,
  },
  txtBody: {
    fontSize: 16,
    fontWeight: 600,
    color: colors.black,
  },
  borderDashed: {
    borderStyle: 'dashed',
    borderWidth: 1,
    borderColor: colors.primary04,
    borderRadius: 40,
  },
  inputStyle: {
    width: '60%',
    height: 48,
    borderStyle: 'solid',
    borderColor: '#CCCCCC',
    backgroundColor: colors.white,
    borderWidth: 1,
    borderRadius: 8,
    marginBlock: 16,
  },
};
