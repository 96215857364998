import { FiEdit2 } from 'react-icons/fi';
import { BsTrash } from 'react-icons/bs';
import { IoDuplicateOutline } from 'react-icons/io5';
import styled from '@emotion/styled';
import images from '../../Utils/images';
import colors from '../../Utils/colors';
import {
  V2CreateFlows,
  V2EditFlows,
  V2GetFlowsByID,
  deleteWorkflow,
  getUserApi,
  toggleFlowsStatus,
} from '../../Services';
import { useDispatch, useSelector } from 'react-redux';
import { triggerReRender } from '../ContactModule/Labels/redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { EnableFlowModalWorkflow } from './EnableFlowModalWorkflow';
import { Oval } from 'react-loader-spinner';
import { v4 as uuidv4 } from 'uuid';
import { Modal, Box } from '@mui/material';
import { eventsend } from '../../Config/analyticsFunctions';
import CheerioButton from '../../Components/CheerioButton';
export const workFlowCols = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    align: 'left',
    headerAlign: 'left',
  },
  {
    field: 'nodeType',
    headerName: 'Event Type',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'created_at',
    headerName: 'Created At',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'last_updated',
    headerName: 'Last Updated',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'actions',
    headerName: 'Actions',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params) => {
      // return <EditableSection params={params} />;
      return <NewEditableSection params={params} />;
    },
  },
];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '700px',
  bgcolor: 'background.paper',
  borderRadius: 5,
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const getId = () => uuidv4();

export const EdiSection = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 15px;
  div {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
  }

  & > div {
    color: var(--links-blue, #36c);
  }

  & > div:nth-child(2) {
    display: flex;
    align-items: center;
  }
`;

export const NewEditableSection = ({ params }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const localdata = useSelector((state) => state.main.localdata);
  const authtoken = useSelector((state) => state.main.auth_token);

  const [duplicateData, setDuplicateData] = useState([]);
  const [createData, setCreateData] = useState([]);
  const [checkedValue, setCheckedValue] = useState(false);
  const [enableFlow, setEnableFlow] = useState(false);
  const [apiValues, setApiValues] = useState({ token: '', data: '', id: '' });
  const [isLoading, setIsLoading] = useState(false);

  const isPremiumUser = localdata?.premium;

  const handleOpen = () => {
    setOpen(true);
  };
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const getuserapicall = () => {
    let token = authtoken;
    getUserApi(token).then((res) => {
      console.log('get user api response ==> ', res);
      if (res?.flag) {
        dispatch({ type: 'addLocaldata', payload: res?.data });
        setIsLoading(false);
      } else {
        console.log('error get user api ===> ', res);
        setIsLoading(false);
      }
    });
  };

  const duplicateAction = (_id) => {
    let token = authtoken;
    V2GetFlowsByID(_id, token).then((res) => {
      if (res) {
        setDuplicateData(res?.data);
        createFlowAPICall(res?.data?.name + ' (copy)');
      } else {
        // console.log("error ===> ",res);
      }
    });
  };

  const createFlowAPICall = (flowName) => {
    let data = {
      name: flowName,
    };
    let token = authtoken;
    V2CreateFlows(data, token).then((res) => {
      if (res?.data) {
        setCreateData(res?.data);
      } else {
        // console.log('create flow failed ===> ', res);
      }
    });
  };

  useEffect(() => {
    if (createData?.id && createData?.name && duplicateData?.nodes && duplicateData?.edges) {
      modifyDuplicateData(duplicateData?.nodes, duplicateData?.edges);
      // editFlowAPICall(createData?.id, createData?.name);
    }
  }, [createData, duplicateData]);

  const modifyDuplicateData = (dupNodes, dupEdges) => {
    let idMapping = {};
    dupNodes.forEach((node, index) => {
      let newId = getId();
      idMapping[node.id] = newId;
      node.id = newId;
    });
    dupEdges.forEach((edge) => {
      edge.source = idMapping[edge.source];
      edge.target = idMapping[edge.target];
    });
    // console.log("modified nodes ---> ",duplicateData?.nodes);
    // console.log("modified edges ---> ",duplicateData?.edges);
    editFlowAPICall();
  };

  const editFlowAPICall = () => {
    let data = {
      name: createData?.name,
      nodes: duplicateData?.nodes,
      edges: duplicateData?.edges,
      duplicate: true,
    };
    let token = authtoken;
    let id = createData?.id;
    V2EditFlows(data, token, id).then((res) => {
      if (res?.data) {
        // console.log('edit flow successfully ===> ', res);
        toast.success('Flow duplicated successfully!', { autoClose: true });
        setTimeout(() => {
          dispatch(triggerReRender());
        }, 500);
      } else {
        //console.log('edit flow failed ===> ', res);
        toast.error(res?.message, { autoClose: true });
      }
    });
  };

  const handleChange = () => {
    const data = {
      value: !checkedValue,
      eventType: params.row.nodeType,
      fbAdId: params.row.fbAdId,
      formId: params.row.formId,
    };

    toggleFlowsStatus(authtoken, params.row.id, data).then((res) => {
      if (res?.flag) {
        if (data.value) {
          toast.success('Flow Enabled', { position: 'top-right', autoClose: true });
        } else {
          toast.success('Flow Disabled', { position: 'top-right', autoClose: true });
          setIsLoading(false);
        }
        if (params.row.nodeType === 'incomingWebhook') {
          dispatch(triggerReRender());
        }
        getuserapicall();
      } else {
        toast.error('Unexpected error occurred, please try again', {
          position: 'top-right',
          autoClose: true,
        });
        setIsLoading(false);
      }
    });
  };

  const toggleValue = () => {
    if (params.row.nodeType === 'incomingWA') {
      if (params.row.id === localdata?.whatsapp_credentials?.defaultWorkflowId?.toString()) {
        return localdata?.whatsapp_credentials?.workflow;
      } else {
        return false;
      }
    } else if (params.row.nodeType === 'Shopify Prepaid Order Create') {
      if (
        params.row.id ===
        localdata?.whatsapp_credentials?.shopifyWorflowId?.orderCreatePrepaid?.defaultWorkflowId?.toString()
      ) {
        return localdata?.whatsapp_credentials?.shopifyWorflowId?.orderCreatePrepaid?.status;
      } else {
        return false;
      }
    } else if (params.row.nodeType === 'Shopify COD Order Create') {
      if (
        params.row.id ===
        localdata?.whatsapp_credentials?.shopifyWorflowId?.orderCreateCOD?.defaultWorkflowId?.toString()
      ) {
        return localdata?.whatsapp_credentials?.shopifyWorflowId?.orderCreateCOD?.status;
      } else {
        return false;
      }
    } else if (params.row.nodeType === 'Shopify Abandoned Cart') {
      if (
        params.row.id ===
        localdata?.whatsapp_credentials?.shopifyWorflowId?.abandonedCart?.defaultWorkflowId?.toString()
      ) {
        return localdata?.whatsapp_credentials?.shopifyWorflowId?.abandonedCart?.status;
      } else {
        return false;
      }
    } else if (params.row.nodeType === 'Shopify Order Cancel') {
      if (
        params.row.id ===
        localdata?.whatsapp_credentials?.shopifyWorflowId?.orderCancelled?.defaultWorkflowId?.toString()
      ) {
        return localdata?.whatsapp_credentials?.shopifyWorflowId?.orderCancelled?.status;
      } else {
        return false;
      }
    } else if (params.row.nodeType === 'Shopify Order Fullfill') {
      if (
        params.row.id ===
        localdata?.whatsapp_credentials?.shopifyWorflowId?.orderFulfilled?.defaultWorkflowId?.toString()
      ) {
        return localdata?.whatsapp_credentials?.shopifyWorflowId?.orderFulfilled?.status;
      } else {
        return false;
      }
    } else if (params.row.nodeType === 'Shopify Order Feedback') {
      if (
        params.row.id ===
        localdata?.whatsapp_credentials?.shopifyWorflowId?.orderFeedback?.defaultWorkflowId?.toString()
      ) {
        return localdata?.whatsapp_credentials?.shopifyWorflowId?.orderFeedback?.status;
      }
    } else if (params.row.nodeType === 'facebookLead') {
      const fbLeadWorkflow = localdata?.whatsapp_credentials?.fbAdsWorkflowId?.find(
        (ad) => ad.fbAdId === params.row.fbAdId
      );
      if (params.row.id === fbLeadWorkflow?.main.defaultWorkflowId?.toString()) {
        return fbLeadWorkflow?.main.status;
      } else {
        return false;
      }
    } else if (params.row.nodeType === 'kylasUpdate') {
      if (
        params.row.id ===
        localdata?.whatsapp_credentials?.kylas?.kylasUpdate?.defaultWorkflowId?.toString()
      ) {
        return localdata?.whatsapp_credentials?.kylas?.kylasUpdate?.defaultWorkflowId?.toString();
      } else {
        return false;
      }
    } else if (params.row.nodeType === 'kylasCreate') {
      if (
        params.row.id ===
        localdata?.whatsapp_credentials?.kylas?.kylasCreate?.defaultWorkflowId?.toString()
      ) {
        return localdata?.whatsapp_credentials?.kylas?.kylasCreate?.defaultWorkflowId?.toString();
      } else {
        return false;
      }
    } else if (params.row.nodeType === 'pabblyEvent') {
      if (params.row.id === localdata?.whatsapp_credentials?.pabblyWorkflowId?.toString()) {
        return localdata?.whatsapp_credentials?.workflow;
      } else {
        return false;
      }
    } else if (params.row.nodeType === 'incomingWebhook') {
      if (params.row.id && params.row.status.toLowerCase() === 'active') {
        return params.row.status;
      } else if (params.row.status.toLowerCase() === 'inactive') {
        return false;
      }
    } else if (params.row.nodeType === 'Woo Abandoned Cart') {
      if (
        params.row.id ===
        localdata?.whatsapp_credentials?.wooWorflowId?.abandonedCart?.defaultWorkflowId?.toString()
      ) {
        return localdata?.whatsapp_credentials?.wooWorflowId?.abandonedCart?.status;
      } else {
        return false;
      }
    } else if (params.row.nodeType === 'Woo Order Cancel') {
      if (
        params.row.id ===
        localdata?.whatsapp_credentials?.wooWorflowId?.orderCancelled?.defaultWorkflowId?.toString()
      ) {
        return localdata?.whatsapp_credentials?.wooWorflowId?.orderCancelled?.status;
      } else {
        return false;
      }
    } else if (params.row.nodeType === 'Woo Order Fullfill') {
      if (
        params.row.id ===
        localdata?.whatsapp_credentials?.wooWorflowId?.orderFulfilled?.defaultWorkflowId?.toString()
      ) {
        return localdata?.whatsapp_credentials?.wooWorflowId?.orderFulfilled?.status;
      } else {
        return false;
      }
    } else if (params.row.nodeType === 'Woo Order Feedback') {
      if (
        params.row.id ===
        localdata?.whatsapp_credentials?.wooWorflowId?.orderFeedback?.defaultWorkflowId?.toString()
      ) {
        return localdata?.whatsapp_credentials?.wooWorflowId?.orderFeedback?.status;
      } else {
        return false;
      }
    } else if (params.row.nodeType === 'Woo Order Create') {
      if (
        params.row.id ===
        localdata?.whatsapp_credentials?.wooWorflowId?.orderCreate?.defaultWorkflowId?.toString()
      ) {
        return localdata?.whatsapp_credentials?.wooWorflowId?.orderCreate?.status;
      } else {
        return false;
      }
    } else if (params.row.nodeType === 'Woo Prepaid Order Create') {
      if (
        params.row.id ===
        localdata?.whatsapp_credentials?.wooWorflowId?.orderCreatePrepaid?.defaultWorkflowId?.toString()
      ) {
        return localdata?.whatsapp_credentials?.wooWorflowId?.orderCreatePrepaid?.status;
      } else {
        return false;
      }
    } else if (params.row.nodeType === 'contactWFNode') {
      if (localdata?.whatsapp_credentials?.contactWorkflowId !== null) {
        return true;
      } else {
        return false;
      }
    } else if (params.row.nodeType === 'formsWFNode') {
      const formWorkflow = localdata?.whatsapp_credentials?.formsWFNode.find(
        (form) => form.formId === params.row.formId
      );
      if (params?.row?.id === formWorkflow?.main?.defaultWorkflowId?.toString()) {
        return formWorkflow?.main?.status;
      } else {
        return false;
      }
    }
    else if (params.row.nodeType === 'instagramEvent') {
      if (localdata?.whatsapp_credentials?.instagramWorkflows.find((flow)=>flow.main.defaultWorkflowId===params.row.id) ) {
        return true;
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    setCheckedValue(toggleValue());
  }, [params]);
  const handleDelete = async (paramId) => {
    eventsend('Delete__Workflows_page_clicked');

    try {
      await deleteWorkflow(authtoken, paramId);
      dispatch(triggerReRender());
    } catch (error) {
      console.error('Error deleting workflow:', error);
    }
  };

  return (
    <>
      <EdiSection>
        {params?.row?.nodeType !== 'campaign' && (
          <>
            {!isLoading ? (
              <div class="form-check form-switch " style={{ paddingTop: '2px' }}>
                <input
                  class="form-check-input shadow-none"
                  style={{}}
                  type="checkbox"
                  role="switch"
                  id={params.row.id}
                  checked={checkedValue}
                  onChange={() => {
                    eventsend('Local_toggle_Workflows_page_clicked');
                    setIsLoading(true);
                    let flow = false;
                    if (
                      (params.row.nodeType === 'Shopify Order Create' &&
                        params.row.existingShopifyFlow?.orderCreateWebhook) ||
                      params.row.nodeType === 'Woo Order Create'
                    ) {
                      flow = true;
                    } else if (
                      (params.row.nodeType === 'Shopify Abandoned Cart' &&
                        params.row.existingShopifyFlow?.abandonCartWebhook) ||
                      params.row.nodeType === 'Woo Abandoned Cart'
                    ) {
                      flow = true;
                    } else if (
                      (params.row.nodeType === 'Shopify Order Cancel' &&
                        params.row.existingShopifyFlow?.orderCancelWebhook) ||
                      params.row.nodeType === 'Woo Order Cancel'
                    ) {
                      flow = true;
                    } else if (
                      (params.row.nodeType === 'Shopify Order Feedback' &&
                        params.row.existingShopifyFlow?.orderFeedbackWebhook) ||
                      params.row.nodeType === 'Woo Order Feedback'
                    ) {
                      flow = true;
                    } else if (
                      (params.row.nodeType === 'Shopify Order Fullfill' &&
                        params.row.existingShopifyFlow?.orderFullfillWebhook) ||
                      params.row.nodeType === 'Woo Order Fullfill'
                    ) {
                      flow = true;
                    }
                    if (flow && !checkedValue) {
                      const obj = {
                        token: authtoken,
                        data: {
                          value: !checkedValue,
                          eventType: params.row.nodeType,
                        },
                        id: params.row.id,
                      };
                      setApiValues(obj);
                      setEnableFlow(true);
                    } else {
                      handleChange();
                    }
                  }}
                ></input>
              </div>
            ) : (
              <Oval radius="1" height={18} width={30} color="blue"></Oval>
            )}
          </>
        )}
        <Tooltip title="Edit">
          <div
            onClick={() => {
              eventsend('Edit_flow_Workflows_page_clicked');
              navigate('../../workflow/' + params.row.id, { relative: 'path' });
            }}
          >
            <FiEdit2 />
          </div>
        </Tooltip>
        <Tooltip title="Duplicate" style={{ cursor: !isPremiumUser ? 'not-allowed' : 'default' }}>
          <div
            onClick={() => {
              if (!isPremiumUser) return;
              eventsend('Duplicate_flow_Workflows_page_clicked');
              duplicateAction(params.row.id);
            }}
          >
            <IoDuplicateOutline />
          </div>
        </Tooltip>
        <Tooltip title="Delete" style={{ cursor: !isPremiumUser ? 'not-allowed' : 'default' }}>
          <div
            // onClick={async () => {
            //   if (!isPremiumUser) return;
            //   eventsend("Delete__Workflows_page_clicked");
            //   let waitConst = await deleteWorkflow(authtoken, params.row.id);
            //   dispatch(triggerReRender());
            // }}
            onClick={handleOpen}
          >
            <BsTrash />
          </div>
        </Tooltip>
        {enableFlow && (
          <EnableFlowModalWorkflow
            show={enableFlow}
            setShow={setEnableFlow}
            apiCall={toggleFlowsStatus}
            apiValues={apiValues}
            userApiCall={getuserapicall}
            setIsLoading={setIsLoading}
          ></EnableFlowModalWorkflow>
        )}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box sx={{ ...style, width: 550 }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: '10px',
              }}
            >
              <h2 id="child-modal-title">Delete Workflow?</h2>
              <img
                src={images?.CMClose}
                style={{ width: '25px', cursor: 'pointer' }}
                onClick={handleClose}
              ></img>
            </div>
            <p id="child-modal-description" style={{ color: colors?.darkgrey }}>
              Any content which you've entered will be deleted.
            </p>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: '10px',
                gap: '40px',
              }}
            >
              <CheerioButton
                borderStyle={{
                  borderColor: colors.black,
                  width: '100%',
                  height: '6%',
                  marginBottom: '5px',
                }}
                textStyle={{ fontSize: 14, fontWeight: 600, color: colors.black }}
                btnText={'Close'}
                backColor={'transparent'}
                onclick={handleClose}
              />
              <CheerioButton
                borderStyle={{
                  borderColor: colors.black,
                  width: '100%',
                  height: '6%',
                  marginBottom: '5px',
                }}
                textStyle={{ fontSize: 14, fontWeight: 600, color: colors.white }}
                btnText={'Delete'}
                backColor={colors.red49}
                onclick={() => {
                  if (!isPremiumUser) return;
                  handleDelete(params.row.id);
                }}
              />
            </div>
          </Box>
        </Modal>
      </EdiSection>
    </>
  );
};

export const EditableSection = ({ params }) => {
  const localdata = useSelector((state) => state.main.localdata);
  const authtoken = useSelector((state) => state.main.auth_token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [checkedValue, setCheckedValue] = useState(false);
  //const [existingShopifyFlow, setExistingShopifyFlow] = useState(false);
  const [enableFlow, setEnableFlow] = useState(false);
  const [apiValues, setApiValues] = useState({ token: '', data: '', id: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [duplicateData, setDuplicateData] = useState([]);
  const [createData, setCreateData] = useState([]);
  const isPremiumUser = localdata?.premium;
  const [wooEnableFlow, setWooEnableFlow] = useState(false);

  const getuserapicall = () => {
    let token = authtoken;
    getUserApi(token).then((res) => {
      console.log('get user api response ==> ', res);
      if (res?.flag) {
        dispatch({ type: 'addLocaldata', payload: res?.data });
        setIsLoading(false);
      } else {
        console.log('error get user api ===> ', res);
        setIsLoading(false);
      }
    });
  };

  const duplicateAction = (_id) => {
    let token = authtoken;
    V2GetFlowsByID(_id, token).then((res) => {
      if (res) {
        setDuplicateData(res?.data);
        createFlowAPICall(res?.data?.name + ' (copy)');
      } else {
        // console.log("error ===> ",res);
      }
    });
  };

  const createFlowAPICall = (flowName) => {
    let data = {
      name: flowName,
    };
    let token = authtoken;
    V2CreateFlows(data, token).then((res) => {
      if (res?.data) {
        setCreateData(res?.data);
      } else {
        // console.log('create flow failed ===> ', res);
      }
    });
  };

  useEffect(() => {
    if (createData?.id && createData?.name && duplicateData?.nodes && duplicateData?.edges) {
      modifyDuplicateData(duplicateData?.nodes, duplicateData?.edges);
      // editFlowAPICall(createData?.id, createData?.name);
    }
  }, [createData, duplicateData]);

  const modifyDuplicateData = (dupNodes, dupEdges) => {
    let idMapping = {};
    dupNodes.forEach((node, index) => {
      let newId = getId();
      idMapping[node.id] = newId;
      node.id = newId;
    });
    dupEdges.forEach((edge) => {
      edge.source = idMapping[edge.source];
      edge.target = idMapping[edge.target];
    });
    // console.log("modified nodes ---> ",duplicateData?.nodes);
    // console.log("modified edges ---> ",duplicateData?.edges);
    editFlowAPICall();
  };

  const editFlowAPICall = () => {
    let data = {
      name: createData?.name,
      nodes: duplicateData?.nodes,
      edges: duplicateData?.edges,
      duplicate: true,
    };
    let token = authtoken;
    let id = createData?.id;
    V2EditFlows(data, token, id).then((res) => {
      if (res?.data) {
        // console.log('edit flow successfully ===> ', res);
        toast.success('Flow duplicated successfully!', {});
        setTimeout(() => {
          dispatch(triggerReRender());
        }, 500);
      } else {
        //console.log('edit flow failed ===> ', res);
        toast.error(res?.message, {});
      }
    });
  };
  // const getShopifyDetailsAPIcall = () => {
  //   let token = authtoken;
  //   getShopifyDetailsApi(token).then((res) => {
  //     if (res?.flag) {
  //       setExistingShopifyFlow(res?.data?.result);
  //     } else {
  //       // console.log("no shopify details exist ===> ",res)
  //     }
  //   });
  // };

  // useEffect(() => {
  //   getShopifyDetailsAPIcall();
  // }, [checkedValue]);

  const handleChange = () => {
    const data = {
      value: !checkedValue,
      eventType: params.row.nodeType,
      fbAdId: params.row.fbAdId,
      formId: params.row.formId,
    };

    toggleFlowsStatus(authtoken, params.row.id, data).then((res) => {
      if (res?.flag) {
        if (data.value) {
          toast.success('Flow Enabled', { position: 'top-right' });
        } else {
          toast.success('Flow Disabled', { position: 'top-right' });
          setIsLoading(false);
        }
        if (params.row.nodeType === 'incomingWebhook') {
          dispatch(triggerReRender());
        }
        getuserapicall();
      } else {
        toast.error('Unexpected error occurred, please try again', {
          position: 'top-right',
        });
        setIsLoading(false);
      }
    });
  };

  const toggleValue = () => {
    if (params.row.nodeType === 'incomingWA') {
      if (params.row.id === localdata?.whatsapp_credentials?.defaultWorkflowId?.toString()) {
        return localdata?.whatsapp_credentials?.workflow;
      } else {
        return false;
      }
    } else if (params.row.nodeType === 'Shopify Prepaid Order Create') {
      if (
        params.row.id ===
        localdata?.whatsapp_credentials?.shopifyWorflowId?.orderCreatePrepaid?.defaultWorkflowId?.toString()
      ) {
        return localdata?.whatsapp_credentials?.shopifyWorflowId?.orderCreatePrepaid?.status;
      } else {
        return false;
      }
    } else if (params.row.nodeType === 'Shopify COD Order Create') {
      if (
        params.row.id ===
        localdata?.whatsapp_credentials?.shopifyWorflowId?.orderCreateCOD?.defaultWorkflowId?.toString()
      ) {
        return localdata?.whatsapp_credentials?.shopifyWorflowId?.orderCreateCOD?.status;
      } else {
        return false;
      }
    } else if (params.row.nodeType === 'Shopify Abandoned Cart') {
      if (
        params.row.id ===
        localdata?.whatsapp_credentials?.shopifyWorflowId?.abandonedCart?.defaultWorkflowId?.toString()
      ) {
        return localdata?.whatsapp_credentials?.shopifyWorflowId?.abandonedCart?.status;
      } else {
        return false;
      }
    } else if (params.row.nodeType === 'Shopify Order Cancel') {
      if (
        params.row.id ===
        localdata?.whatsapp_credentials?.shopifyWorflowId?.orderCancelled?.defaultWorkflowId?.toString()
      ) {
        return localdata?.whatsapp_credentials?.shopifyWorflowId?.orderCancelled?.status;
      } else {
        return false;
      }
    } else if (params.row.nodeType === 'Shopify Order Fullfill') {
      if (
        params.row.id ===
        localdata?.whatsapp_credentials?.shopifyWorflowId?.orderFulfilled?.defaultWorkflowId?.toString()
      ) {
        return localdata?.whatsapp_credentials?.shopifyWorflowId?.orderFulfilled?.status;
      } else {
        return false;
      }
    } else if (params.row.nodeType === 'Shopify Order Feedback') {
      if (
        params.row.id ===
        localdata?.whatsapp_credentials?.shopifyWorflowId?.orderFeedback?.defaultWorkflowId?.toString()
      ) {
        return localdata?.whatsapp_credentials?.shopifyWorflowId?.orderFeedback?.status;
      }
    } else if (params.row.nodeType === 'facebookLead') {
      const fbLeadWorkflow = localdata?.whatsapp_credentials?.fbAdsWorkflowId.find(
        (ad) => ad.fbAdId === params.row.fbAdId
      );
      if (params.row.id === fbLeadWorkflow?.main.defaultWorkflowId?.toString()) {
        return fbLeadWorkflow?.main.status;
      } else {
        return false;
      }
    } else if (params.row.nodeType === 'kylasUpdate') {
      if (
        params.row.id ===
        localdata?.whatsapp_credentials?.kylas?.kylasUpdate?.defaultWorkflowId?.toString()
      ) {
        return localdata?.whatsapp_credentials?.kylas?.kylasUpdate?.defaultWorkflowId?.toString();
      } else {
        return false;
      }
    } else if (params.row.nodeType === 'kylasCreate') {
      if (
        params.row.id ===
        localdata?.whatsapp_credentials?.kylas?.kylasCreate?.defaultWorkflowId?.toString()
      ) {
        return localdata?.whatsapp_credentials?.kylas?.kylasCreate?.defaultWorkflowId?.toString();
      } else {
        return false;
      }
    } else if (params.row.nodeType === 'pabblyEvent') {
      if (params.row.id === localdata?.whatsapp_credentials?.pabblyWorkflowId?.toString()) {
        return localdata?.whatsapp_credentials?.workflow;
      } else {
        return false;
      }
    } else if (params.row.nodeType === 'incomingWebhook') {
      if (params.row.id && params.row.status.toLowerCase() === 'active') {
        return params.row.status;
      } else if (params.row.status.toLowerCase() === 'inactive') {
        return false;
      }
    } else if (params.row.nodeType === 'Woo Abandoned Cart') {
      if (
        params.row.id ===
        localdata?.whatsapp_credentials?.wooWorflowId?.abandonedCart?.defaultWorkflowId?.toString()
      ) {
        return localdata?.whatsapp_credentials?.wooWorflowId?.abandonedCart?.status;
      } else {
        return false;
      }
    } else if (params.row.nodeType === 'Woo Order Cancel') {
      if (
        params.row.id ===
        localdata?.whatsapp_credentials?.wooWorflowId?.orderCancelled?.defaultWorkflowId?.toString()
      ) {
        return localdata?.whatsapp_credentials?.wooWorflowId?.orderCancelled?.status;
      } else {
        return false;
      }
    } else if (params.row.nodeType === 'Woo Order Fullfill') {
      if (
        params.row.id ===
        localdata?.whatsapp_credentials?.wooWorflowId?.orderFulfilled?.defaultWorkflowId?.toString()
      ) {
        return localdata?.whatsapp_credentials?.wooWorflowId?.orderFulfilled?.status;
      } else {
        return false;
      }
    } else if (params.row.nodeType === 'Woo Order Feedback') {
      if (
        params.row.id ===
        localdata?.whatsapp_credentials?.wooWorflowId?.orderFeedback?.defaultWorkflowId?.toString()
      ) {
        return localdata?.whatsapp_credentials?.wooWorflowId?.orderFeedback?.status;
      } else {
        return false;
      }
    } else if (params.row.nodeType === 'Woo Order Create') {
      if (
        params.row.id ===
        localdata?.whatsapp_credentials?.wooWorflowId?.orderCreate?.defaultWorkflowId?.toString()
      ) {
        return localdata?.whatsapp_credentials?.wooWorflowId?.orderCreate?.status;
      } else {
        return false;
      }
    } else if (params.row.nodeType === 'Woo Prepaid Order Create') {
      if (
        params.row.id ===
        localdata?.whatsapp_credentials?.wooWorflowId?.orderCreatePrepaid?.defaultWorkflowId?.toString()
      ) {
        return localdata?.whatsapp_credentials?.wooWorflowId?.orderCreatePrepaid?.status;
      } else {
        return false;
      }
    } else if (params.row.nodeType === 'contactWFNode') {
      if (localdata?.whatsapp_credentials?.contactWorkflowId !== null) {
        return true;
      } else {
        return false;
      }
    } else if (params.row.nodeType === 'formsWFNode') {
      const formWorkflow = localdata?.whatsapp_credentials?.formsWFNode.find(
        (form) => form.formId === params.row.formId
      );
      if (params?.row?.id === formWorkflow?.main?.defaultWorkflowId?.toString()) {
        return formWorkflow?.main?.status;
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    setCheckedValue(toggleValue());
  }, [params]);

  return (
    <EdiSection>
      {params?.row?.nodeType !== 'campaign' && (
        <>
          {!isLoading ? (
            <div class="form-check form-switch " style={{ paddingTop: '2px' }}>
              <input
                class="form-check-input shadow-none"
                style={{}}
                type="checkbox"
                role="switch"
                id={params.row.id}
                checked={checkedValue}
                onChange={() => {
                  eventsend('Local_toggle_Workflows_page_clicked');
                  setIsLoading(true);
                  let flow = false;
                  if (
                    (params.row.nodeType === 'Shopify Order Create' &&
                      params.row.existingShopifyFlow?.orderCreateWebhook) ||
                    params.row.nodeType === 'Woo Order Create'
                  ) {
                    flow = true;
                  } else if (
                    (params.row.nodeType === 'Shopify Abandoned Cart' &&
                      params.row.existingShopifyFlow?.abandonCartWebhook) ||
                    params.row.nodeType === 'Woo Abandoned Cart'
                  ) {
                    flow = true;
                  } else if (
                    (params.row.nodeType === 'Shopify Order Cancel' &&
                      params.row.existingShopifyFlow?.orderCancelWebhook) ||
                    params.row.nodeType === 'Woo Order Cancel'
                  ) {
                    flow = true;
                  } else if (
                    (params.row.nodeType === 'Shopify Order Feedback' &&
                      params.row.existingShopifyFlow?.orderFeedbackWebhook) ||
                    params.row.nodeType === 'Woo Order Feedback'
                  ) {
                    flow = true;
                  } else if (
                    (params.row.nodeType === 'Shopify Order Fullfill' &&
                      params.row.existingShopifyFlow?.orderFullfillWebhook) ||
                    params.row.nodeType === 'Woo Order Fullfill'
                  ) {
                    flow = true;
                  }
                  if (flow && !checkedValue) {
                    const obj = {
                      token: authtoken,
                      data: {
                        value: !checkedValue,
                        eventType: params.row.nodeType,
                      },
                      id: params.row.id,
                    };
                    setApiValues(obj);
                    setEnableFlow(true);
                  } else {
                    handleChange();
                  }
                }}
              ></input>
            </div>
          ) : (
            <Oval radius="1" height={18} width={30} color="blue"></Oval>
          )}
        </>
      )}

      <Tooltip title={'Edit'}>
        <div
          className="mx-1"
          onClick={() => {
            console.log('555555555555');
            eventsend('Edit_flow_Workflows_page_clicked');
            navigate('../../workflow/' + params.row.id, { relative: 'path' });
          }}
        >
          <FiEdit2 />
        </div>
      </Tooltip>
      <Tooltip title={'Duplicate'} style={{ cursor: !isPremiumUser ? 'not-allowed' : 'default' }}>
        <div
          className="mx-1"
          onClick={() => {
            if (!isPremiumUser) return;
            eventsend('Duplicate_flow_Workflows_page_clicked');
            duplicateAction(params.row.id);
            // navigate('../../workflow/' + params.row.id , { state : {duplicate: true}, relative: 'path' });
          }}
        >
          <IoDuplicateOutline />
        </div>
      </Tooltip>
      <Tooltip title={'Delete'} style={{ cursor: !isPremiumUser ? 'not-allowed' : 'default' }}>
        <div
          className="mx-1"
          onClick={async () => {
            if (!isPremiumUser) return;
            eventsend('Delete__Workflows_page_clicked');
            let waitConst = await deleteWorkflow(authtoken, params.row.id);
            dispatch(triggerReRender());
          }}
        >
          <BsTrash />
        </div>
      </Tooltip>
      {/* <Tooltip title={'Set as default'}>
        <div className="mx-1">
          {localdata.whatsapp_credentials?.defaultWorkflowId === params.row.id ? (
            <>
              <img
                src={images.WFFlagSel}
                style={{ height: 24, width: 24, objectFit: 'contain' }}
              ></img>
            </>
          ) : (
            <>
              <img
                src={images.WFFlag}
                style={{ height: 24, width: 24, objectFit: 'contain', cursor: 'pointer' }}
                onClick={() => {
                  defaultWorkflow(authtoken, params.row.id).then((res) => {
                    if (res) {
                      toast.success('set as default', { position: 'top-right' });
                      getuserapicall();
                    } else {
                      toast.error('Unexpected error occurred, please try again', {
                        position: 'top-right',
                        
                      });
                    }
                  });
                }}
              ></img>
            </>
          )}
        </div>
      </Tooltip> */}
      {enableFlow && (
        <EnableFlowModalWorkflow
          show={enableFlow}
          setShow={setEnableFlow}
          apiCall={toggleFlowsStatus}
          apiValues={apiValues}
          userApiCall={getuserapicall}
          setIsLoading={setIsLoading}
        ></EnableFlowModalWorkflow>
      )}
    </EdiSection>
  );
};
