export default {
  USERS_V2: 'users/v2/',
  USERS: 'users/',
  PAIDLINK: 'paid-link/',
  ADMINS: 'admins/',
  CATEGORIES: 'categories/',
  CHAT: 'chat/',
  EVENT: 'event/',
  POLL: 'poll/',
  S3: 's3/',
  CONTACT: 'contact/',
  V2_CONTACT: 'v2/contact/',
  TEMPLATE: 'template/',
  V2_TEMPLATE: 'v2/template/',
  PROMOTION: 'promotion/',
  V2_PROMOTION: 'v2/promotion/',
  CONFIG: 'config/',
  V2_AUTO_RECHARGE:'autoRecharge/',
  MAIL: 'mail/',
  PREMIUM: 'premium/',
  SHORT_URL: 'short-url/',
  SHOPIFY: 'shopify/',
  ANALYTICS: 'analytics/',
  WHATSAPP: 'whatsapp/',
  GENERATE: 'generate/',
  DIALOG_FLOW: 'dialogflow/',
  AISENSY: 'Aisensy/',
  V2_ANALYTICS: 'v2/analytics/',
  V2_WHATSAPP: 'v2/whatsapp/',
  V2_ANNOUNCEMENT: 'v2/announcement/',
  V2_EMAILBUILDER: 'v2/email-builder',
  V2_SETTINGS: 'v2/settings/',
  V2_WORKFLOW: 'v2/workflow',
  V2_SCHEDULER: 'v2/scheduler/',
  REPLIES: 'replies/',
  V2_AGENT: 'v2/agent/',
  WA_LINK: 'waLink/',
  V2_SHOPIFY: 'v2/shopify/',
  AUTH: 'auth',
  WooCommerce: 'woocommerce/',
  AI: 'ai/',
  KYLAS: 'kylas/',
  ENTERPRISE: 'enterprise/',
  V2_KEYWORD_ACTIONS: 'v2/keyword-actions/',
  V2_KEYWORD_ACTIONS_TIME_FRAME: 'v2/keyword-actions/time-trigger',
  PAYMENTS: 'payments/',
  VERIFY: 'verify/',
  PARTNER: 'partner/',
  REFER: 'refer/',
  BREVO: 'brevo/',
  V2_DRAFT: '/v2/draft',
  PIXEL: "pixel/",
  NOTIFICATION: 'v2/webNotifications',
  FORM: 'form/',
  EXCEL: 'excel/',
  UNNWRAP: 'unnwrap/',
  INSTAGRAM:'instagram/'
};
