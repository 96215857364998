import images from '../../../Utils/images';
import Form from 'react-bootstrap/Form';
import React, { useState, useEffect,useRef } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import colors from '../../../Utils/colors';
import { Checkbox } from '@mui/material';
import { V2AgentGet } from '../../../Services';
import { useSelector } from 'react-redux';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import {Tooltip} from '@mui/material';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import EmojiPicker from 'emoji-picker-react';
import { getInstagramStoriesAPI,getInstagramPostsAPI } from '../../../Services';
import { toast } from 'react-toastify';
import { FaAngleDown } from 'react-icons/fa';

export const KeywordsComponent = ({ 
  title,
  tooltipText,
  conditions,
  setConditions,
  conditionOptions,
  selectStyles,
  containerStyle = {},
  number,
  sectionTitle,
  enableEmoji=false,
}) => {
  const [newKeyword, setNewKeyword] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const emojiPickerRef = useRef(null);
  const emojiButtonRef = useRef(null);
  const [editingKeyword, setEditingKeyword] = useState({ index: null, value: '' });

  // Add this function to handle keyword updates
  const updateKeyword = (conditionIndex, messageIndex, keywordIndex, newValue) => {
    setConditions(prev => prev.map((condition, idx) => {
      if (idx === conditionIndex) {
        return {
          ...condition,
          messageConditions: condition.messageConditions.map((msgCondition, msgIdx) => {
            if (msgIdx === messageIndex) {
              const newKeywords = [...msgCondition.keywords];
              newKeywords[keywordIndex] = newValue;
              return {
                ...msgCondition,
                keywords: newKeywords
              };
            }
            return msgCondition;
          })
        };
      }
      return condition;
    }));
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        emojiPickerRef.current && 
        !emojiPickerRef.current.contains(event.target) &&
        !emojiButtonRef.current.contains(event.target)
      ) {
        setShowEmojiPicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleKeywordInput = (conditionIndex, messageIndex) => {
    setConditions(prev => prev.map((condition, idx) => {
      if (idx === conditionIndex) {
        return {
          ...condition,
          messageConditions: condition.messageConditions.map((msgCondition, msgIdx) => {
            if (msgIdx === messageIndex) {
              return {
                ...msgCondition,
                showInput: true
              };
            }
            return msgCondition;
          })
        };
      }
      return condition;
    }));
  };

  const onEmojiClick = (emojiData, conditionIndex, messageIndex) => {
    // Ensure we're getting the unified emoji character
    const emoji = emojiData.emoji; // This will give us the correct emoji character
    setNewKeyword(prev => {
      // If there's already text, add a space before the emoji
      return prev ? `${prev} ${emoji}` : emoji;
    });
    // setShowEmojiPicker(false);
  };

  const handleKeywordSubmit = (conditionIndex, messageIndex, event) => {
    if (event.key === 'Enter' && newKeyword.trim()) {
      setConditions(prev => prev.map((condition, idx) => {
        if (idx === conditionIndex) {
          return {
            ...condition,
            messageConditions: condition.messageConditions.map((msgCondition, msgIdx) => {
              if (msgIdx === messageIndex) {
                return {
                  ...msgCondition,
                  keywords: [...msgCondition.keywords, newKeyword.trim()],
                  showInput: false
                };
              }
              return msgCondition;
            })
          };
        }
        return condition;
      }));
      setNewKeyword('');
      setShowEmojiPicker(false);
    }else if (event.key === 'Escape') {
      setConditions(prev => prev.map((condition, idx) => {
        if (idx === conditionIndex) {
          return {
            ...condition,
            messageConditions: condition.messageConditions.map((msgCondition, msgIdx) => {
              if (msgIdx === messageIndex) {
                return {
                  ...msgCondition,
                  showInput: false
                };
              }
              return msgCondition;
            })
          };
        }
        return condition;
      }));
      setNewKeyword('');
    }
  };

  const removeKeyword = (conditionIndex, messageIndex, keywordIndex) => {
    setConditions(prev => prev.map((condition, idx) => {
      if (idx === conditionIndex) {
        return {
          ...condition,
          messageConditions: condition.messageConditions.map((msgCondition, msgIdx) => {
            if (msgIdx === messageIndex) {
              return {
                ...msgCondition,
                keywords: msgCondition.keywords.filter((_, kidx) => kidx !== keywordIndex)
              };
            }
            return msgCondition;
          })
        };
      }
      return condition;
    }));
  };

  const updateConditionType = (conditionIndex, newType) => {
    setConditions(prev => prev.map((condition, idx) => {
      if (idx === conditionIndex) {
        return {
          ...condition,
          type: newType
        };
      }
      return condition;
    }));
  };

  const addMessageCondition = (conditionIndex) => {
    setConditions(prev => prev.map((condition, idx) => {
      if (idx === conditionIndex) {
        return {
          ...condition,
          messageConditions: [
            ...condition.messageConditions,
            { keywords: [], isFirst: false, conditionOperator: 'contains' }
          ]
        };
      }
      return condition;
    }));
  };

  const removeMessageCondition = (conditionIndex, messageIndex) => {
    setConditions(prev => prev.map((condition, idx) => {
      if (idx === conditionIndex) {
        return {
          ...condition,
          messageConditions: condition.messageConditions.filter((_, msgIdx) => msgIdx !== messageIndex)
        };
      }
      return condition;
    }));
  };

  const renderInputSection = (conditionIndex, messageIndex) => (
    <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
      <input
        autoFocus
        value={newKeyword}
        onChange={(e) => setNewKeyword(e.target.value)}
        onKeyDown={(e) => handleKeywordSubmit(conditionIndex, messageIndex, e)}
        onBlur={(e) => {
          if (newKeyword.trim()) {
            // Save the keyword if there's content
            setConditions(prev => prev.map((condition, idx) => {
              if (idx === conditionIndex) {
                return {
                  ...condition,
                  messageConditions: condition.messageConditions.map((msgCondition, msgIdx) => {
                    if (msgIdx === messageIndex) {
                      return {
                        ...msgCondition,
                        keywords: [...msgCondition.keywords, newKeyword.trim()],
                        showInput: false
                      };
                    }
                    return msgCondition;
                  })
                };
              }
              return condition;
            }));
            setNewKeyword('');
          } else {
            // Just hide the input if no content
            if (!enableEmoji || !e.relatedTarget?.classList.contains('emoji-button')) {
              setConditions(prev => prev.map((condition, idx) => {
                if (idx === conditionIndex) {
                  return {
                    ...condition,
                    messageConditions: condition.messageConditions.map((msgCondition, msgIdx) => {
                      if (msgIdx === messageIndex) {
                        return {
                          ...msgCondition,
                          showInput: false
                        };
                      }
                      return msgCondition;
                    })
                  };
                }
                return condition;
              }));
              setNewKeyword('');
            }
          }
        }}
        style={{
          border: '1px solid #8E53F7',
          borderRadius: '20px',
          padding: '6px 12px',
          outline: 'none',
          width: '120px',
          background: 'none',
          textAlign: 'center'
        }}
        placeholder="Type and press Enter"
      />
      {enableEmoji && (
        <>
          <button
            ref={emojiButtonRef}
            className="emoji-button"
            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
            style={{
              border: 'none',
              background: 'none',
              cursor: 'pointer',
              marginLeft: '8px',
              fontSize: '20px'
            }}
          >
            😊
          </button>
          {showEmojiPicker && (
            <div 
              ref={emojiPickerRef}
              style={{
                position: 'fixed',
                top: '40px',
                left:'100%',
                zIndex: 1000,
              }}
            >
              <EmojiPicker
                onEmojiClick={(emojiObject) => 
                  onEmojiClick(emojiObject, conditionIndex, messageIndex)
                }
                width={300}
                height={400}
              />
            </div>
          )}
        </>
      )}
    </div>
  );


  return (
    <div style={{
      padding: '20px 10px',
      background: '#FAFAFA',
      borderRadius: 8,
      ...containerStyle
    }}>
     {(number && sectionTitle) &&  <div style={{ 
        display: 'flex', 
        alignItems: 'center',
        marginBottom: '10px'
      }}>
        <span style={{ 
          fontWeight: '600',
          marginRight: '10px',
          width: '32px', 
          height: '32px', 
          borderRadius: '50%', 
          backgroundColor: 'white',
          color: 'black', 
          display: 'flex', 
         alignItems: 'center', 
         justifyContent: 'center',
        }}>{number}</span>
        <span style={{ fontWeight: '500' }}>{sectionTitle}</span>
      </div>}
          { (number && sectionTitle) &&  <hr style={{
            width: 'calc(100% - 20px)',
            border: 'none', 
            borderTop: '2px solid #E6E6E6', 
            margin: '0 auto',
            marginBottom: '20px' 
            }}/>}
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '12px'
      }}>
        <span style={{ fontWeight: '500' }}>{title}</span>
        <Tooltip 
          title={tooltipText}
          arrow
          style={{
            background: '#4A148C',
            fontSize: '25px',
            paddingRight: '50px',
          }}
        >
          <button style={{
            color: '#8E53F7',
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            gap: '4px'
          }}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
          >
            Learn More
            <span style={{ fontSize: '16px' }}>ⓘ</span>
          </button>
        </Tooltip>
      </div>

      {conditions.map((condition, conditionIndex) => (
        <div key={conditionIndex} style={{ marginBottom: '20px' }}>
          {condition.messageConditions.map((messageCondition, messageIndex) => (
            <React.Fragment key={messageIndex}>
              <div style={{ marginBottom: '12px' }}>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  marginBottom: '12px'
                }}>
                  <span style={{ color: '#666' }}>If</span>
                  <Select
                    value={messageCondition.conditionOperator}
                    onChange={(e) => {
                      setConditions(prev => prev.map((condition, idx) => {
                        if (idx === conditionIndex) {
                          return {
                            ...condition,
                            messageConditions: condition.messageConditions.map((msgCondition, msgIdx) => {
                              if (msgIdx === messageIndex) {
                                return {
                                  ...msgCondition,
                                  conditionOperator: e.target.value
                                };
                              }
                              return msgCondition;
                            })
                          };
                        }
                        return condition;
                      }));
                    }}
                    sx={selectStyles}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          '& .MuiMenuItem-root': {
                            fontSize: '14px',
                            color: '#666',
                          }
                        }
                      }
                    }}
                  >
                    {conditionOptions.map((option) => (
                      <MenuItem 
                        key={option.key} 
                        value={option.key}
                        sx={{ minWidth: option.value.length * 8 }}
                      >
                        {option.value}
                      </MenuItem>
                    ))}
                  </Select>
                  {!messageCondition.isFirst && (
                    <button 
                      onClick={() => removeMessageCondition(conditionIndex, messageIndex)}
                      style={{
                        marginLeft: 'auto',
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                        color: '#666'
                      }}
                    >
                      <img src={images.DeleteBlack} width={24} height={24} color='#666666'/>
                    </button>
                  )}
                </div>

                <div style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '8px',
                  alignItems: 'center'
                }}>
                  {messageCondition.keywords.map((keyword, kidx) => (
                    <div key={kidx} style={{
                      background: '#f5f5f5',
                      borderRadius: '20px',
                      padding: '6px 12px',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                      border: '1px solid transparent', // Add transparent border by default
                      transition: 'border-color 0.2s ease',
                    }}
                   onMouseEnter={(e) => e.currentTarget.style.borderColor = '#8E53F7'} // Purple border on hover
    onMouseLeave={(e) => e.currentTarget.style.borderColor = 'transparent'}  
                    >
                    {editingKeyword.index === kidx ? (
      <input
        autoFocus
        value={editingKeyword.value}
        onChange={(e) => setEditingKeyword({ index: kidx, value: e.target.value })}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            updateKeyword(conditionIndex, messageIndex, kidx, editingKeyword.value);
            setEditingKeyword({ index: null, value: '' });
          } else if (e.key === 'Escape') {
            setEditingKeyword({ index: null, value: '' });
          }
        }}
        onBlur={() => {
          if (editingKeyword.value.trim()) {
            updateKeyword(conditionIndex, messageIndex, kidx, editingKeyword.value);
          }
          setEditingKeyword({ index: null, value: '' });
        }}
        style={{
          border: 'none',
          background: 'none',
          outline: 'none',
          width: `${Math.max(keyword.length * 8, 30)}px`,
        }}
      />
    ) : (
      <span 
        onClick={() => setEditingKeyword({ index: kidx, value: keyword })}
        style={{ cursor: 'pointer' }}
      >
        {keyword}
      </span>
    )}
    <button 
      onClick={() => removeKeyword(conditionIndex, messageIndex, kidx)}
      style={{
        border: 'none',
        background: 'none',
        cursor: 'pointer',
        fontSize: '16px',
        color: '#666'
      }}
    >
      ×
    </button>
                    </div>
                  ))}

{messageCondition.showInput ? renderInputSection(conditionIndex, messageIndex) : (
    <button 
      onClick={() => toggleKeywordInput(conditionIndex, messageIndex)}
      style={{
        border: '1px dashed #666',
        background: 'none',
        borderRadius: '20px',
        padding: '6px 12px',
        cursor: 'pointer',
        color: '#666',
        display: 'flex',
        alignItems: 'center',
        gap: '4px'
      }}
    >
      + Add keyword
    </button>
  )}

                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
      ))}
    </div>
  );
};



const InstagramEventModal = ({ data, setData ,setBtnDisabled}) => {
    const [selectedTemplate, setSelectedTemplate] = useState();
    const localdata = useSelector((state) => state.main.localdata);

    const [templateData, setTemplateData] = useState(localdata?.metaConfig?.newIGToken ? [
      'Incoming message in DM',
      'Commented on post or reel',
      'Replied to Story'
 ] : [
   'Incoming message in DM'
]);
    const [conditions, setConditions] = useState([
        {
          messageConditions: [
            {
              keywords: [],
              isFirst: true,
              showInput: false,
              conditionOperator: 'equals'
            }
          ],
          type: 'OR'
        }
      ]);
      
      const commentConditionOptions = [
        { key: 'starts_with', value: 'Message starts with' },
        { key: 'ends_with', value: 'Message ends with' },
        { key: 'equals', value: 'Message equals' }
      ];
      
      const [postConditions, setPostConditions] = useState([
        {
          messageConditions: [
            {
              keywords: [],
              isFirst: true,
              showInput: false,
              conditionOperator: 'equals'
            }
          ],
          type: 'OR'
        }
      ]);

     


    

      const conditionOptions = [
        // { key: 'contains', value: 'Message contains' },
        // { key: 'not_contains', value: 'Message does not contain' },
        { key: 'starts', value: 'Message starts with' },
        { key: 'ends', value: 'Message ends with' },
        { key: 'equals', value: 'Message equals' }
      ];


      const [replyConditions, setReplyConditions] = useState([
        {
          messageConditions: [
            {
              keywords: [],
              isFirst: true,
              showInput: false,
              conditionOperator: 'equals'
            }
          ],
          type: 'OR'
        }
      ]);
      
      const replyConditionOptions = [
        { key: 'starts_with', value: 'Reply starts with' },
        { key: 'ends_with', value: 'Reply ends with' },
        { key: 'equals', value: 'Reply equals' }
      ];



      // const handleKeywordSubmit = (conditionIndex, messageIndex, event) => {
      //   if (event.key === 'Enter' && newKeyword.trim()) {
      //     setConditions(prev => prev.map((condition, idx) => {
      //       if (idx === conditionIndex) {
      //         return {
      //           ...condition,
      //           messageConditions: condition.messageConditions.map((msgCondition, msgIdx) => {
      //             if (msgIdx === messageIndex) {
      //               return {
      //                 ...msgCondition,
      //                 keywords: [...msgCondition.keywords, newKeyword.trim()],
      //                 showInput: false
      //               };
      //             }
      //             return msgCondition;
      //           })
      //         };
      //       }
      //       return condition;
      //     }));
      //     setNewKeyword('');
      //   } else if (event.key === 'Escape') {
      //     // Handle canceling input
      //     setConditions(prev => prev.map((condition, idx) => {
      //       if (idx === conditionIndex) {
      //         return {
      //           ...condition,
      //           messageConditions: condition.messageConditions.map((msgCondition, msgIdx) => {
      //             if (msgIdx === messageIndex) {
      //               return {
      //                 ...msgCondition,
      //                 showInput: false
      //               };
      //             }
      //             return msgCondition;
      //           })
      //         };
      //       }
      //       return condition;
      //     }));
      //     setNewKeyword('');
      //   }
      // };

      
      // const removeKeyword = (conditionIndex, messageIndex, keywordIndex) => {
      //   setConditions(prev => prev.map((condition, idx) => {
      //     if (idx === conditionIndex) {
      //       return {
      //         ...condition,
      //         messageConditions: condition.messageConditions.map((msgCondition, msgIdx) => {
      //           if (msgIdx === messageIndex) {
      //             return {
      //               ...msgCondition,
      //               keywords: msgCondition.keywords.filter((_, kidx) => kidx !== keywordIndex)
      //             };
      //           }
      //           return msgCondition;
      //         })
      //       };
      //     }
      //     return condition;
      //   }));
      // };
      
      // const updateConditionType = (conditionIndex, newType) => {
      //   setConditions(prev => prev.map((condition, idx) => {
      //     if (idx === conditionIndex) {
      //       return {
      //         ...condition,
      //         type: newType
      //       };
      //     }
      //     return condition;
      //   }));
      // };
      
      // const addMessageCondition = (conditionIndex) => {
      //   setConditions(prev => prev.map((condition, idx) => {
      //     if (idx === conditionIndex) {
      //       return {
      //         ...condition,
      //         messageConditions: [
      //           ...condition.messageConditions,
      //           { keywords: [], isFirst: false,conditionOperator: 'contains' }
      //         ]
      //       };
      //     }
      //     return condition;
      //   }));
      // };
      
      // const removeMessageCondition = (conditionIndex, messageIndex) => {
      //   setConditions(prev => prev.map((condition, idx) => {
      //     if (idx === conditionIndex) {
      //       return {
      //         ...condition,
      //         messageConditions: condition.messageConditions.filter((_, msgIdx) => msgIdx !== messageIndex)
      //       };
      //     }
      //     return condition;
      //   }));
      // };
  useEffect(() => {
    if (data) {
      setSelectedTemplate(data?.instagramEvent);
    }
    if (data?.conditions) {
      setConditions(data.conditions);
  }
  if (data?.postConditions) {
      setPostConditions(data.postConditions);
  }
  if (data?.replyConditions) {
    setReplyConditions(data.replyConditions);
}
  }, []);

  useEffect(() => {
   if(selectedTemplate==='Incoming message in DM'){
    const keywords = conditions[0]?.messageConditions[0]?.keywords || [];
    setData({
      ...data,
      conditions: conditions,
      operator:"OR",
      instagramEvent: selectedTemplate,
      conditionOperator: conditions[0]?.messageConditions[0]?.conditionOperator ? conditions[0]?.messageConditions[0]?.conditionOperator :"",
      eventType:'message',
      keywordList: keywords,
    });
    setBtnDisabled(keywords?.length === 0);
  }
  console.log('options changed ---> ', conditions);
}, [conditions]);

useEffect(() => {
  if(selectedTemplate==='Replied to Story'){
    const keywords = replyConditions[0]?.messageConditions[0]?.keywords || [];
   setData({
     ...data,
     replyConditions: replyConditions,
     operator:"OR",
     instagramEvent: selectedTemplate,
     conditionOperator: replyConditions[0]?.messageConditions[0]?.conditionOperator? replyConditions[0]?.messageConditions[0]?.conditionOperator :"",
     eventType:'story_reply',
     keywordList:keywords,
   });
   setBtnDisabled(!data?.selectedStoryId || keywords?.length === 0);
  }
 console.log('options changed ---> ', replyConditions);
}, [replyConditions]);

useEffect(() => {
  if(selectedTemplate==='Commented on post or reel'){
    const keywords = postConditions[0]?.messageConditions[0]?.keywords || [];
   setData({
     ...data,
     postConditions: postConditions,
     operator:"OR",
     instagramEvent: selectedTemplate,
     conditionOperator: postConditions[0]?.messageConditions[0]?.conditionOperator? postConditions[0]?.messageConditions[0]?.conditionOperator :"",
     eventType:'comment',
     keywordList: postConditions[0]?.messageConditions[0]?.keywords ? postConditions[0]?.messageConditions[0]?.keywords :[],
   });
   setBtnDisabled(!data?.selectedPostId || keywords?.length === 0);
  }
 console.log('options changed ---> ', postConditions);
}, [postConditions]);

    // useEffect(() => {
    //     if (selectedTemplate) {
    //         setData({
    //             ...data,
    //             operator:"OR",
    //             conditionOperator:selectedTemplate === 'Incoming message in DM'? conditions[0]?.messageConditions[0]?.conditionOperator :"",
    //             eventType:'message',
    //             keywordList:selectedTemplate === 'Incoming message in DM'? conditions[0]?.messageConditions[0]?.keywords :[],
    //             instagramEvent: selectedTemplate,
    //             conditions: selectedTemplate === 'Incoming message in DM' ? conditions : undefined,
    //             postConditions: selectedTemplate === 'Commented on post or reel' ? postConditions : undefined,
    //             replyConditions: selectedTemplate === 'Replied to Story' ? replyConditions : undefined
    //         });
    //     }
    // }, [conditions, replyConditions, postConditions, selectedTemplate]);
  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <p
          style={{
            fontWeight: 400,
            fontSize: 14,
            paddingTop: '3.5%',
            color: '#666666',
            textAlign: 'left',
          }}
        >
          These are the lists of events that are possible on Instagram and based on this you can select a desired trigger
        </p>
      </div>

      <InputGroup className="mb-3" style={{ width: '100%', marginBlock: 20 }}>
          <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
            {'Select Event'}
          </Form.Label>
          <Form.Select
            type="text"
            placeholder="select"
            className="w-100 btncustom dropdown"
            style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.greys01 }}
            value={selectedTemplate}
            onChange={(e) => {
              console.log('er', e.target.value);
              setSelectedTemplate(e.target.value);
              setData({
                instagramEvent: e.target.value,
                conditions: undefined,
                postConditions: undefined,
                replyConditions: undefined,
                operator: "OR",
                conditionOperator: "",
                eventType: "",
                keywordList: [],
                selectedStoryId: null,
                storyDetails: null,
                selectedPostId: null,
                postDetails: null,
                selectedPostId: null,
                postDetails: null
              });
              
              // Reset the condition states
              setConditions([{
                messageConditions: [{
                  keywords: [],
                  isFirst: true,
                  showInput: false,
                  conditionOperator: 'equals'
                }],
                type: 'OR'
              }]);
              
              setPostConditions([{
                messageConditions: [{
                  keywords: [],
                  isFirst: true,
                  showInput: false,
                  conditionOperator: 'equals'
                }],
                type: 'OR'
              }]);
              
              setReplyConditions([{
                messageConditions: [{
                  keywords: [],
                  isFirst: true,
                  showInput: false,
                  conditionOperator: 'equals'
                }],
                type: 'OR'
              }]);
            }}
          >
            <option
              style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
              key="blankchoice"
              hidden
              value
            >
              {'Select Event'}
            </option>
            {templateData &&
              templateData
                .map((item, index) => {
                  return (
                    <option
                      style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
                      value={item.id}
                    >
                      {item}
                    </option>
                  );
                })}
          </Form.Select>
        </InputGroup>
        {selectedTemplate === templateData[0] && (
  <KeywordsComponent
    title="Keywords"
    tooltipText="Keyword feature will trigger on a searched word or phrase. Whenever a keyword is matched with set conditions it will trigger an action. To use this feature effectively use a word as a keyword rather than a phrase. For Eg: 'eBook' instead of 'I need an eBook'"
    conditions={conditions}
    setConditions={setConditions}
    conditionOptions={conditionOptions}
    selectStyles={selectStyles}
  />
)}
  {selectedTemplate === templateData[1] && localdata?.metaConfig?.newIGToken && (
    <>
      <PostSelectionComponent data={data} setData={setData}/>
      <KeywordsComponent
  title="Keywords"
  tooltipText="Keyword feature will trigger on a searched word or phrase in comments. Whenever a keyword is matched with set conditions it will trigger an action."
  conditions={postConditions}
  setConditions={setPostConditions}
  conditionOptions={commentConditionOptions}
  selectStyles={selectStyles}
  containerStyle={{ marginTop: '20px' }}
  number="2"
  sectionTitle="Condition"
  selectedTemplate={selectedTemplate}
/>
</>
    )}
     {selectedTemplate === templateData[2] && localdata?.metaConfig?.newIGToken && (
    <>
      <ReplyStorySelectionComponent data={data} setData={setData} />
      <KeywordsComponent
  title="Keywords"
  tooltipText="Keyword feature will trigger on a searched word or phrase in comments. Whenever a keyword is matched with set conditions it will trigger an action."
  conditions={replyConditions}
  setConditions={setReplyConditions}
  conditionOptions={replyConditionOptions}
  selectStyles={selectStyles}
  containerStyle={{ marginTop: '20px' }}
  number="2"
  sectionTitle="Condition"
  selectedTemplate={selectedTemplate}
  enableEmoji={true}
/>
</>
    )}
    </div>
  );
};

export const PostSelectionComponent = ({data,setData}) => {
  const [selectedPostType, setSelectedPostType] = useState('');
  const localdata = useSelector((state) => state.main.localdata);
  const authtoken = useSelector((state) => state.main.auth_token);
  const [posts, setPosts] = useState([]);
  const [isOpen, setIsOpen] = useState(false); 
  const [loading, setLoading] = useState(true);
  const [selectedPostId, setSelectedPostId] = useState(data?.selectedPostId || null); 
  const [postDetails,setPostDetails]=useState(data?.postDetails || null);
  const getInstagramPosts = () => {
    let token = authtoken;
    setLoading(true);
    getInstagramPostsAPI(token).then((res) => {
      try {
        if (res?.flag) {
          setPosts(res.data);
        } else {
         console.log(res?.message);
        }
      } catch (err) {
        toast.error(err?.message);
      } finally {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    getInstagramPosts();
  }, []);

  const renderLoader = () => (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '200px'
    }}>
      <div style={{
        width: '40px',
        height: '40px',
        border: '3px solid #f3f3f3',
        borderTop: '3px solid #8E53F7',
        borderRadius: '50%',
        animation: 'spin 1s linear infinite',
      }}>
        <style>
          {`
            @keyframes spin {
              0% { transform: rotate(0deg); }
              100% { transform: rotate(360deg); }
            }
          `}
        </style>
      </div>
    </div>
  );

  
  const handlePostSelection = (post, event) => {
    event.stopPropagation();
    setSelectedPostId(selectedPostId === post?.id ? null : post?.id);
    setPostDetails(post);
  };



  const renderPostsGrid = () => {
    return (
      <div style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gap: '15px',
        marginTop: '10px',
        padding: '10px'
      }}>
        {posts.map((post) => (
          post?.media_url && (<div 
            key={post.id} 
            onClick={(e) => handlePostSelection(post, e)}
            style={{
              position: 'relative',
              aspectRatio: '2/3', // Increased height
              borderRadius: '8px',
              overflow: 'hidden',
              cursor: 'pointer',
              border: selectedPostId === post.id ? '2px solid #8E53F7' : '2px solid transparent',
              padding: '2px'
            }}
          >
            <div style={{
              position: 'relative',
              width: '100%',
              height: '100%',
              borderRadius: '6px',
              overflow: 'hidden'
            }}>
              {post.media_type === 'VIDEO' ? (
                <video
                  src={post.media_url}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    filter: selectedPostId === post.id ? 'brightness(0.7) blur(1px)' : 'none',
                    transition: 'filter 0.3s ease'
                  }}
                />
              ) : (
                <img
                  src={post.media_url}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    filter: selectedPostId === post.id ? 'brightness(0.7) blur(1px)' : 'none',
                    transition: 'filter 0.3s ease'
                  }}
                />
              )}
              <div 
                  onClick={(e) => handlePostSelection(post, e)}
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  width: '17px',
                  height: '17px',
                  borderRadius: '50%',
                  background: 'transparent',
                  border: selectedPostId === post.id ? '2px solid #8E53F7' : '2px solid white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: 2
                }}
              >
               {selectedPostId === post.id && (
                  <span style={{ color: '#8E53F7', fontSize: '14px' }}>✓</span>
                )}
              </div>
            </div>
          </div>)
        ))}
      </div>
    );
  };

  const renderEmptyState = () => (
    <div style={{
      marginTop: '20px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '30px',
      border: 'none',
      borderRadius: '8px',
      width: '100%',
      boxSizing: 'border-box'
    }}>
      <div style={{
        width: '40px',
        height: '40px',
        border: '1px dashed #666',
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '10px'
      }}>
        +
      </div>
      <p style={{
        color: '#666',
        textAlign: 'center',
        margin: '0'
      }}>
      You don’t have any post or reel available
      </p>
      <p style={{
        color: '#999',
        fontSize: '12px',
        textAlign: 'center',
        margin: '5px 0 0 0'
      }}>
        Upload at least a post or a reel on your Instagram to view here
      </p>
    </div>
  );

  useEffect(() => {
   setData({
    ...data,
    selectedPostId:selectedPostId,
    postDetails:postDetails,
    eventType:'comment'
   })
  }, [selectedPostId]);


  return (
    <div style={{ 
      padding: '20px 0',
      background: '#FAFAFA',
      borderRadius: '8px'
    }}>
      <div style={{ 
        display: 'flex', 
        alignItems: 'center',
        marginBottom: '10px',
        paddingLeft: '10px'  
      }}>
        <span style={{ 
          fontWeight: '600',
          marginRight: '10px',
          width: '32px', 
          height: '32px', 
          borderRadius: '50%', 
          backgroundColor: 'white',
          color: 'black', 
          display: 'flex', 
         alignItems: 'center', 
         justifyContent: 'center',
         gap:10,
        }}>1</span>
        <span style={{ fontWeight: '500' }}>Select post or reel</span>
      </div>
            <hr style={{
            width: 'calc(100% - 20px)',
            border: 'none', 
            borderTop: '2px solid #E6E6E6', 
            margin: '0 auto',
            marginBottom: '20px' 
            }}/>
      <div style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        gap: '12px',
        padding: '0 10px',  
        maxWidth: '100%'
      }}>
        <div 
          onClick={() => {
            setSelectedPostType('specific');
            setIsOpen(!isOpen); 
          }}
          style={{ 
            padding: '10px',
            borderRadius: '8px',
            cursor: 'pointer',
            border: selectedPostId ? '2px solid #8E53F7' : 'none',
            background: '#FFFFFF',
            width: '100%'   
          }}
        >
          <div style={{ 
            display: 'flex', 
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <span style={{ fontWeight: '500' }}>Specific Post or Reel</span>
            {!isOpen && (<span style={{ 
                fontSize: '18px',
              }}>
                <FaAngleDown />
              </span>)}
            {selectedPostType === 'specific' && isOpen && (
              <div style={{ 
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                background: '#8E53F7',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                <span style={{ color: 'white' }}>✓</span>
              </div>
            )}
          </div>
          
          {isOpen && (
            loading ? renderLoader() : 
            posts.length > 0 ? renderPostsGrid() : renderEmptyState()
          )}
        </div>
      </div>
    </div>
  );
};

export const ReplyStorySelectionComponent = ({data,setData}) => {
  const [selectedPostType, setSelectedPostType] = useState('');
  const localdata = useSelector((state) => state.main.localdata);
  const authtoken = useSelector((state) => state.main.auth_token);
  const [stories, setStories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedStoryId, setSelectedStoryId] = useState(data?.selectedStoryId || null); 
  const [storyDetails,setStoryDetails]=useState(data?.storyDetails || null);
  const getInstagramStories = () => {
    let token = authtoken;
    setLoading(true);
    getInstagramStoriesAPI(token).then((res) => {
      try {
        if (res?.flag) {
          setStories(res.data);
        } else {
         console.log(res?.message);
        }
      } catch (err) {
        toast.error(err?.message);
      } finally {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    getInstagramStories();
  }, []);

  const renderLoader = () => (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '200px'
    }}>
      <div style={{
        width: '40px',
        height: '40px',
        border: '3px solid #f3f3f3',
        borderTop: '3px solid #8E53F7',
        borderRadius: '50%',
        animation: 'spin 1s linear infinite',
      }}>
        <style>
          {`
            @keyframes spin {
              0% { transform: rotate(0deg); }
              100% { transform: rotate(360deg); }
            }
          `}
        </style>
      </div>
    </div>
  );

  
  const handleStorySelection = (story, event) => {
    event.stopPropagation();
    setSelectedStoryId(selectedStoryId === story?.id ? null : story?.id);
    setStoryDetails(story);
  };



  const renderStoriesGrid = () => {
    return (
      <div style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gap: '15px',
        marginTop: '10px',
        padding: '10px'
      }}>
        {stories.map((story) => (
          <div 
            key={story.id} 
            onClick={(e) => handleStorySelection(story, e)}
            style={{
              position: 'relative',
              aspectRatio: '2/3', // Increased height
              borderRadius: '8px',
              overflow: 'hidden',
              cursor: 'pointer',
              border: selectedStoryId === story.id ? '2px solid #8E53F7' : '2px solid transparent',
              padding: '2px'
            }}
          >
            <div style={{
              position: 'relative',
              width: '100%',
              height: '100%',
              borderRadius: '6px',
              overflow: 'hidden'
            }}>
              {story.media_type === 'VIDEO' ? (
                <video
                  src={story.media_url}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    filter: selectedStoryId === story.id ? 'brightness(0.7) blur(1px)' : 'none',
                    transition: 'filter 0.3s ease'
                  }}
                />
              ) : (
                <img
                  src={story.media_url}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    filter: selectedStoryId === story.id ? 'brightness(0.7) blur(1px)' : 'none',
                    transition: 'filter 0.3s ease'
                  }}
                />
              )}
              <div 
                  onClick={(e) => handleStorySelection(story, e)}
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  width: '17px',
                  height: '17px',
                  borderRadius: '50%',
                  background: 'transparent',
                  border: selectedStoryId === story.id ? '2px solid #8E53F7' : '2px solid white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: 2
                }}
              >
               {selectedStoryId === story.id && (
                  <span style={{ color: '#8E53F7', fontSize: '14px' }}>✓</span>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderEmptyState = () => (
    <div style={{
      marginTop: '20px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '30px',
      border: 'none',
      borderRadius: '8px',
      width: '100%',
      boxSizing: 'border-box'
    }}>
      <div style={{
        width: '40px',
        height: '40px',
        border: '1px dashed #666',
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '10px'
      }}>
        +
      </div>
      <p style={{
        color: '#666',
        textAlign: 'center',
        margin: '0'
      }}>
       You don’t have any Stories available
      </p>
      <p style={{
        color: '#999',
        fontSize: '12px',
        textAlign: 'center',
        margin: '5px 0 0 0'
      }}>
        You can only choose stories posted in the last 24 hours due to Instagram limit. Please, create a new one and it will appear here.
      </p>
    </div>
  );

  useEffect(() => {
   setData({
    ...data,
    selectedStoryId:selectedStoryId,
    storyDetails:storyDetails,
   })
  }, [selectedStoryId]);


  return (
    <div style={{ 
      padding: '20px 0',
      background: '#FAFAFA',
      borderRadius: '8px'
    }}>
      <div style={{ 
        display: 'flex', 
        alignItems: 'center',
        marginBottom: '10px',
        paddingLeft: '10px'  
      }}>
        <span style={{ 
          fontWeight: '600',
          marginRight: '10px',
          width: '32px', 
          height: '32px', 
          borderRadius: '50%', 
          backgroundColor: 'white',
          color: 'black', 
          display: 'flex', 
         alignItems: 'center', 
         justifyContent: 'center',
         gap:10,
        }}>1</span>
        <span style={{ fontWeight: '500' }}>Select stories</span>
      </div>
            <hr style={{
            width: 'calc(100% - 20px)',
            border: 'none', 
            borderTop: '2px solid #E6E6E6', 
            margin: '0 auto',
            marginBottom: '20px' 
            }}/>
      <div style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        gap: '12px',
        padding: '0 10px',  
        maxWidth: '100%'
      }}>
        <div 
          onClick={() => setSelectedPostType('specific')}
          style={{ 
            padding: '10px',
            borderRadius: '8px',
            cursor: 'pointer',
            border: selectedPostType === 'specific' ? '2px solid #8E53F7' : 'none',
            background: '#FFFFFF',
            width: '100%'   
          }}
        >
          <div style={{ 
            display: 'flex', 
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <span style={{ fontWeight: '500' }}>Specific Story</span>
            {selectedPostType === 'specific' && (
              <div style={{ 
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                background: '#8E53F7',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                <span style={{ color: 'white' }}>✓</span>
              </div>
            )}
          </div>
          
          {loading ? renderLoader() : 
          stories.length > 0 ? renderStoriesGrid() : renderEmptyState()}
        </div>
      </div>
    </div>
  );
};

export const selectStyles = {
  backgroundColor: '#FFFFFF',
  '& .MuiSelect-select': {
    padding: '6px 12px',
    color: '#666',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  }
};

export const inputStyles = {
  border: '1px solid #8E53F7',
  borderRadius: '20px',
  padding: '6px 12px',
  outline: 'none',
  width: '120px',
  background: 'none',
  textAlign: 'center',
  '&:focus': {
    borderColor: '#8E53F7',
  }
};

export default InstagramEventModal;
