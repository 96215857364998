import React from 'react';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import './../styles.css';
import { useNavigate } from 'react-router-dom';

const SidemenuSS = ({
  openSetup,
  setOpenSetup,
  openFlow,
  setOpenFlow,
  selectedMenuItem,
  setSelectedMenuItem,
}) => {
  const navigate = useNavigate();

  const MenuSubItem = ({ title }) => {
    return (
      <>
        <div
          className="Row justify-content-start w-100"
          style={{
            paddingBlock: 8,
            paddingInlineStart: 24,
            marginBlock: 4,
            backgroundColor: title == selectedMenuItem ? '#F5F0FF' : '',
            cursor: 'pointer',
            borderRadius: 8,
          }}
          onClick={() => {
            setSelectedMenuItem(title);
            navigate(`/creator/manageIntegrations/shopify/${title}`);
          }}
        >
          <p
            style={{
              padding: 0,
              margin: 0,
              marginInline: 8,
              fontSize: 14,
              fontWeight: 600,
              color: title == selectedMenuItem ? colors.primary03 : colors.darkgrey,
            }}
          >
            {title}
          </p>
        </div>
      </>
    );
  };

  return (
    <>
      <div
        className="d-flex flex-column justify-content-start align-items-center h-100"
        style={{
          width: 220,
          backgroundColor: colors.white02,
          border: `1px solid ${colors.borderwhite}`,
        }}
      >
        <div
          className="d-flex flex-row justify-content-between align-items-center w-100"
          style={{ marginBlock: 8, paddingBlock: 8, paddingInline: 16 }}
        >
          <div
            className="d-flex flex-row justify-content-start align-items-center"
            style={{ paddingInline: 8 }}
          >
            <img src={images.SCog} style={{ height: 24, width: 24, objectFit: 'contain' }}></img>
            <p
              style={{
                padding: 0,
                margin: 0,
                marginInline: 8,
                fontSize: 14,
                fontWeight: 600,
                color: colors.black,
              }}
            >
              {'Setup'}
            </p>
          </div>
          <img
            src={images.DownArrowTip}
            style={{
              height: 24,
              width: 24,
              objectFit: 'contain',
              cursor: 'pointer',
              transform: openSetup ? 'rotate(180deg)' : '',
            }}
            onClick={() => {
              setOpenSetup(!openSetup);
            }}
          ></img>
        </div>
        <div
          className="d-flex flex-column justify-content-start align-items-center w-100"
          style={{ paddingInline: 16 }}
        >
          {openSetup ? (
            <>
              <MenuSubItem title={'Integration'} />
              <MenuSubItem title={'Message template'} />
            </>
          ) : (
            <></>
          )}
        </div>
        <div
          className="d-flex flex-row justify-content-between align-items-center w-100"
          style={{ marginBlock: 8, paddingBlock: 8, paddingInline: 16 }}
        >
          <div
            className="d-flex flex-row justify-content-start align-items-center"
            style={{
              cursor: 'pointer',
              paddingInlineStart: 8,
              paddingBlock: 8,
              borderRadius: 8,
              width: '100%',
              backgroundColor: selectedMenuItem === 'Flows' ? '#F5F0FF' : '',
            }}
            onClick={() => {
              setSelectedMenuItem('Flows');
              navigate(`/creator/manageIntegrations/shopify/Flows`);
            }}
          >
            <img
              src={selectedMenuItem === 'Flows' ? images.IconWorkFlowSel : images.IconWorkFlow}
              style={{ height: 24, width: 24, objectFit: 'contain' }}
            ></img>
            <p
              style={{
                padding: 0,
                margin: 0,
                marginInline: 8,
                fontSize: 14,
                fontWeight: 600,
                color: selectedMenuItem === 'Flows' ? colors.primary03 : colors.black,
              }}
            >
              {'Flows'}
            </p>
          </div>
          {/* <img
            src={images.DownArrowTip}
            style={{
              height: 24,
              width: 24,
              objectFit: 'contain',
              cursor: 'pointer',
              transform: openFlow ? 'rotate(180deg)' : '',
            }}
            onClick={() => {
              setOpenFlow(!openFlow);
            }}
          ></img> */}
        </div>
        {/* <div
          className="d-flex flex-column justify-content-start align-items-center w-100"
          style={{ paddingInline: 16 }}
        >
          {openFlow ? (
            <>
              {/* <MenuSubItem title={'Abandoned cart'}/> */}
        {/* <MenuSubItem title={'Order confirmation'}/> */}
        {/* <MenuSubItem title={'Order placement'} />
            </>
          ) : (
            <></>
          )} */}
        {/* </div>  */}
        <div
          className="d-flex flex-row justify-content-between align-items-center w-100"
          style={{ marginBlock: 8, paddingInline: 16 }}
        >
          <div
            className="d-flex flex-row justify-content-start align-items-center w-100"
            style={{
              cursor: 'pointer',
              paddingInlineStart: 8,
              paddingBlock: 8,
              borderRadius: 8,
              backgroundColor: selectedMenuItem === 'Customers data' ? '#F5F0FF' : '',
            }}
            onClick={() => {
              setSelectedMenuItem('Customers data');
              navigate(`/creator/manageIntegrations/shopify/Customers data`);
            }}
          >
            <img
              src={selectedMenuItem === 'Customers data' ? images.SCustomersSel : images.SCustomers}
              style={{ height: 24, width: 24, objectFit: 'contain' }}
            ></img>
            <p
              style={{
                padding: 0,
                margin: 0,
                marginInline: 8,
                fontSize: 14,
                fontWeight: 600,
                color: selectedMenuItem === 'Customers data' ? colors.primary03 : colors.black,
              }}
            >
              {'Customers data'}
            </p>
          </div>
        </div>
        <div
          className="d-flex flex-row justify-content-between align-items-center w-100"
          style={{ marginBlock: 8, paddingInline: 16 }}
        >
          <div
            className="d-flex flex-row justify-content-start align-items-center w-100"
            style={{
              cursor: 'pointer',
              paddingInlineStart: 8,
              paddingBlock: 8,
              borderRadius: 8,
              backgroundColor: selectedMenuItem === 'Analytics' ? '#F5F0FF' : '',
            }}
            onClick={() => {
              setSelectedMenuItem('Analytics');
              navigate(`/creator/manageIntegrations/shopify/Analytics`);
            }}
          >
            <img
              src={selectedMenuItem === 'Analytics' ? images.GlobalAnalyticsIconSel : images.GlobalAnalyticsIcon}
              style={{ height: 24, width: 24, objectFit: 'contain' }}
            ></img>
            <p
              style={{
                padding: 0,
                margin: 0,
                marginInline: 8,
                fontSize: 14,
                fontWeight: 600,
                color: selectedMenuItem === 'Analytics' ? colors.primary03 : colors.black,
              }}
            >
              {'Analytics'}
            </p>
          </div>
        </div>
        {/* <div
          className="d-flex flex-row justify-content-between align-items-center w-100"
          style={{ marginBlock: 8, paddingInline: 16 }}
        >
          <div
            className="d-flex flex-row justify-content-start align-items-center w-100"
            style={{
              cursor: 'pointer',
              paddingInlineStart: 8,
              paddingBlock: 8,
              borderRadius: 8,
              backgroundColor: selectedMenuItem === 'Analytics' ? '#F5F0FF' : '',
            }}
            onClick={() => {
              setSelectedMenuItem('Analytics');
            }}
          >
            <img
              src={selectedMenuItem === 'Analytics' ? images.SAnalyticsSel : images.SAnalytics}
              style={{ height: 24, width: 24, objectFit: 'contain' }}
            ></img>
            <p
              style={{
                padding: 0,
                margin: 0,
                marginInline: 8,
                fontSize: 14,
                fontWeight: 600,
                color: selectedMenuItem === 'Analytics' ? colors.primary03 : colors.black,
              }}
            >
              {'Analytics'}
            </p>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default SidemenuSS;
