import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Dropdown, CardImg, CloseButton } from 'react-bootstrap';
import images from '../../../Utils/images';
import colors from '../../../Utils/colors';
import {
  downloadRepliesCsv,
  getInboxListingAPI,
  mailFormDataCsv,
  postContact,
} from '../../../Services';
import { getImagePath } from '../../../Utils';
import Helper from '../../../Utils/Helper';
import { V2AgentGet, toggleStatus, updateAgentActivity } from '../../../Services';

import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import CheerioButton from '../../../Components/CheerioButton';
import { Autocomplete, ClickAwayListener, TextField, Tooltip } from '@mui/material';
import { DistributedLeads } from './DistributedLeads';
import '../styles.css';
import { Filters } from './Filters';
import { MailRepliesModal } from './MailRepliesModal';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { IoIosSearch } from 'react-icons/io';
import { RxCross1 } from 'react-icons/rx';
import { FiSend } from 'react-icons/fi';
import styled from 'styled-components';
import { hiddenName } from '../../ContactModule/ContactsAll/variableDependencies';
import { eventsend } from '../../../Config/analyticsFunctions';
import parsePhoneNumber from 'libphonenumber-js';
import { RiRobot2Line } from 'react-icons/ri';
import { MdOutlineSupportAgent } from 'react-icons/md';
import SpinnerLoader from '../../../Components/SpinnerLoader';
const BANNER = 'https://i.imgur.com/CaKdFMq.jpg';

export function TimeToIst(time) {
  let formattedTime = new Date(time).toLocaleString('en-GB', {
    timeZone: 'Asia/Kolkata',
    hour: '2-digit',
    minute: 'numeric',
    hour12: true,
  });
  if (formattedTime.split(':')[0] === '00') {
    formattedTime = formattedTime.replace('00', '12');
  }

  return formattedTime;
}

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    id="DropdownToggle"
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();

      onClick(e);
    }}
  >
    {children}
    <Tooltip title={'Menu'}>
      <CardImg
        className="filterToggleHover"
        src={images.ThreeDot}
        style={{
          cursor: 'pointer',
          height: 30,
          width: 30,
          borderRadius: '10px',
          padding: '6px',
          backgroundColor: colors.grey,
        }}
      />
    </Tooltip>
  </a>
));

function WAInbox({
  chatCount,
  excepted,
  filteredCount,
  setAppliedFilter,
  paneWidth = window.innerWidth * 0.3,
  list,
  setSelectedIndex,
  selectedItem,
  setSelectedItem,
  waData,
  profileImg,
  isChatLoad,
  hasNext,
  page,
  setPage,
  filters,
  setFilters,
  setInboxList,
  getInboxListing,
  agentData,
  totalChats,
  mobileChangeScreen,
  setChatData,
  searchInbox,
  setSearchInbox,
  removeFilterAction,
  sortOrder,
  setSortOrder,
  activeWorkflow,
  waInboxloading,
  setwaInboxLoading,
  searchType,
  setSearchType,
  searchDropdownOpen,
  setSearchDropdownOpen,
}) {
  const [newNumber, setNewNumber] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [showDistributeLeads, setShowDistributeLeads] = useState(false);
  //const [agentData, setAgentData] = useState();
  const authtoken = useSelector((state) => state.main.auth_token);
  const localdata = useSelector((state) => state.main.localdata);
  const [showMailCSV, setShowMailCSV] = useState(false);
  const [email, setEmail] = useState();
  const hasSeenPopup = localStorage.getItem('hasSeenDistributionPopup');
  const [showDistributePopUp, setShowDistirbutePopUp] = useState(!hasSeenPopup);
  const agentUserData = useSelector((state) => state.main.agentdata);
  const [agentActivityStatus, setAgentActivityStatus] = useState(
    agentUserData?.activity ?? 'online'
  );
  const [addNewChatPopUp, setAddNewChatPopUp] = useState(false);
  const [numberList, setNumberList] = useState([]);
  const MobileWidth = window.innerWidth < 900 ? true : false;
  const [error, setError] = useState('');
  const [mailFormData, setMailFormData] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(`All (${chatCount?.All})`);
  const [topFilter, setTopFilter] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const selectedItemRef = useRef(null);
  const containerRef = useRef(null);
  const [showButton, setShowButton] = useState(false);
  const [firstloading,setFirstloading]=useState(true);
  const loaderRef = useRef(null);
  const agentdata = useSelector((state) => state.main.agentdata);
  const isElementInViewport = (element) => {
    if (!element) return false;

    const rect = element.getBoundingClientRect();
    console.log('rect', rect);
    const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
    const viewportWidth = window.innerWidth || document.documentElement.clientWidth;

    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= viewportHeight &&
      rect.right <= viewportWidth
    );
  };

  console.log('selected ref', showButton);
  const checkIfSelectedItemInViewport = () => {
    if (selectedItemRef.current) {
      setShowButton(!isElementInViewport(selectedItemRef.current));
    }
  };



  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const handleScroll = () => {
      checkIfSelectedItemInViewport();
    };

    container.addEventListener('scroll', handleScroll);

    // Cleanup the scroll event listener
    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, [selectedItemRef]);


  useEffect(() => {
    setShowButton(false);
  }, [selectedFilter]);

  const handleClose = () => {
    localStorage.setItem('hasSeenDistributionPopup', 'true');
    setShowDistirbutePopUp(false);
  };

  useEffect(() => {
    if (agentUserData) {
      updateAgentActivityApiCall();
    }
  }, [agentActivityStatus]);

  const updateAgentActivityApiCall = () => {
    const data = {
      agentId: agentUserData?._id,
      activity: agentActivityStatus,
    };
    updateAgentActivity(authtoken, data).then((res) => {
      if (res?.flag) {
      } else {
        setAgentActivityStatus(agentUserData?.activity ?? 'online');
        toast.error(res?.message);
      }
    });
  };

  const useDebouncedEffect = (effect, deps, delay) => {
    useEffect(() => {
      const handler = setTimeout(() => effect(), delay);

      return () => clearTimeout(handler);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...(deps || []), delay]);
  };

  useDebouncedEffect(() => getNumberListApiCall(), [newNumber], 500);

  const getNumberListApiCall = (page = 1) => {
    let token = authtoken;
    let data = {
      page: page,
      searchQuery: newNumber,
      filters: filters,
      limit: 20,
      sortOrder: sortOrder,
    };
    getInboxListingAPI(token, data).then((res) => {
      if (res?.flag) {
        setNumberList(res?.data?.docs);
      } else {
      }
    });
  };

  useEffect(() => {
    const setFrtLimitBreached = (_item) => {
      if (
        _item?.agent &&
        _item.status.toLowerCase() === 'unresolved' &&
        _item?.lastReply?.by === 'User'
      ) {
        _item.frtLimitBreached =
          new Date(_item?.lastReply?.time) <
          new Date(Date.now() - localdata?.enterprise?.frtLimit * 60 * 1000);
        _item.status = _item?.frtLimitBreached ? 'Pending' : _item?.status;
      }
      return _item;
    };

    const infiniteLoop = () => {
      const tempList = [];
      for (const item of list) {
        const tempItem = setFrtLimitBreached(item);
        if(selectedItem?._id === tempItem?._id){
          setSelectedItem(tempItem)
        }
        tempList.push(tempItem);
      }
      if (JSON.stringify(tempList) !== JSON.stringify(list)) {
        setInboxList(tempList);
      }
      if (list?.length) {
        const intervalId = setInterval(() => {
          infiniteLoop();
        }, 120000);
        return () => clearInterval(intervalId);
      }
    };
  }, [list.length]);

  const handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\D/g, '').slice(0, 15);
    setNewNumber(inputValue);
    if (!/^\d{0,15}$/.test(inputValue)) {
      setError('Please enter numbers only.');
    } else {
      setError('');
    }
  };

  const mailFormDataApiCall = () => {
    const data = {
      email: email,
    };
    mailFormDataCsv(data, authtoken).then((res) => {
      if (res?.flag) {
        toast.success(res?.result);
      } else {
        toast.error('error downloading csv', res);
      }
    });
  };

  const downloadCsvApiCall = () => {
    const data = {
      showCTWA: false,
      email: email,
      filters,
    };
    downloadRepliesCsv(authtoken, data).then((res) => {
      // if (res.status == '200') {

      //   const url = window.URL.createObjectURL(new Blob([res.data]));
      //   const link = document.createElement('a');
      //   link.href = url;
      //   link.setAttribute('download', `exported-replies.csv`);
      //   document.body.appendChild(link);
      //   link.click();
      //   toast.success('File downloaded', { position: 'top-center' });
      // }
      if (res?.flag) {
        toast.success(res?.result);
      } else {
        toast.error('error downloading csv', res);
      }
    });
  };
  // const handleChange = (e, convId) => {
  //   const data = {
  //     convId: convId,
  //     status: e.target.value,
  //   };
  //   toggleStatus(authtoken, data).then((res) => {
  //     if (res?.flag) {
  //       setInboxList([]);
  //       getInboxListing(1);
  //     } else {
  //       toast.error(res?.message, { position: 'top-center' });
  //     }
  //   });
  // };

  // useEffect(() => {
  //   document
  //     .getElementById(selectedItem?._id)
  //     ?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  // }, [list]);

  let check = false;
  if (
    filters?.general?.length > 0 ||
    filters?.status?.length > 0 ||
    filters?.agents?.length > 0 ||
    filters?.showCtwa ||
    Object.values(filters?.day).filter((x) => x !== null)
  ) {
    check = true;
  }
  // const inboxList = useMemo(()=>list,[list]);

  useEffect(() => {
    if (!check) {
      setSelectedFilter(`All (${chatCount?.All})`);
    }
  }, [check]);
  const handleAddNewContact = async () => {
    try {
      const resp = await postContact(authtoken, { contactNumber: newNumber });
      let conversation = list.find((conv) => conv._id === resp.data.conversation._id);
      if (!conversation) {
        conversation = resp.data.conversation;
        setInboxList((s) => [conversation, ...s]);
      }

      setNewNumber('');
      setAddNewChatPopUp(!addNewChatPopUp);
      mobileChangeScreen('chatview');
      setSelectedItem(conversation);
    } catch (err) { }
  };

  // Validation for send input and button
  const isSendButtonDisabled = () => {
    if (newNumber.length >= 7 && error === '') {
      return false;
    }

    return true;
  };

  function allValuesFalseOrNullEmpty(obj) {
    return Object.values(obj).every((value) => {
      if (Array.isArray(value)) {
        return value.length === 0;
      } else if (value && typeof value === 'object') {
        return allValuesFalseOrNullEmpty(value);
      } else {
        return value === false || value === null;
      }
    });
  }

  const filtersExist = allValuesFalseOrNullEmpty(filters);

  const waNumber = parsePhoneNumber('+' + waData?.phone_number);

  useEffect(() => {
    if (selectedItem) {
      let selectedEle = document.getElementById(selectedItem?.msg_id ?? selectedItem._id);
      if (selectedEle) {
        selectedEle.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [selectedItem]);

  useEffect(() => {
    setError('');
  }, [addNewChatPopUp]);

  const renderItemsWithBreakers = (list, containerRef, showButton, setShowButton) => {
    let lastDate = null;

    return (
      <div style={{ position: 'relative' }}>
        {list.map((_item, _i) => {
          const messageDate = new Date(_item?.lastReply?.time);
          const formattedDate = messageDate.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          });

          const isSelected = _item?.msg_id ? selectedItem?.msg_id==_item?.msg_id :selectedItem?._id == _item?._id;

          // Determine if a breaker should be added
          let breaker = null;
          if (formattedDate !== lastDate) {
            lastDate = formattedDate;
            breaker = <DateBreakComponent date={formattedDate} key={`breaker-${_i}`} />;
          }

          return (
            <React.Fragment key={_item._id}>
              {breaker}
              <div
                id={_item?._id}
                onClick={() => {
                  mobileChangeScreen('chatview');
                  setSelectedItem(_item);
                }}
                ref={isSelected ? selectedItemRef : null}
                style={{
                  ...styles.content.listItemWrapper,
                  backgroundColor: isSelected ? colors.grey : colors.white,
                }}
                className="chatItemHover"
              >
                <div style={styles.content.listItem}>
                  <div style={styles.content.listItemTitle}>
                    <div style={{ ...styles.row, gap: '6px' }}>
                      <p style={styles.content.listItemTitleText}>
                        {excepted
                          ? hiddenName(
                            _item?.user_details?.name?.substring(0, 18) ||
                            _item.user_details?.phone
                          )
                          : _item?.user_details?.name?.substring(0, 18) ||
                          _item.user_details?.phone}
                      </p>
                      {_item?.unread_count && !isSelected ? (
                        <div style={styles.content.unreadMessageCount.container}>
                          <p style={styles.content.unreadMessageCount.p}>{_item?.unread_count}</p>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div
                      className="d-flex flex-row justify-content-start align-items-center"
                      style={{ width: 200 }}
                    >
                      {!_item?.text && _item?.image ? (
                        <>
                          <img
                            src={images.GalleryIcon}
                            style={{
                              height: 18,
                              width: 18,
                              objectFit: 'contain',
                              marginInlineEnd: 6,
                            }}
                          ></img>
                          <p style={styles.content.itemBody}>{_item?.image ? 'Media' : ''}</p>
                          {!_item?.merchant_reply && (
                            <img
                              src={images.WITick}
                              style={{
                                height: 18,
                                width: 18,
                                objectFit: 'contain',
                                marginInlineStart: 6,
                              }}
                            ></img>
                          )}
                        </>
                      ) : (
                        <>
                          <p style={styles.content.itemBody}>
                            {/* {_item?.text ?? ''} */}
                            {_item?.text.length > 15 ? _item?.text.substring(0, 15) + '...' : _item?.text}
                          </p>
                        </>
                      )}
                    </div>

                    <div style={styles.content.tagItems}>
                      {localdata.isEnterpriseUser && _item?.frtLimitBreached && (
                        <FilterToggle
                          text="FRT Exceeded"
                          bg="#FFDBDA"
                          fontSize={12}
                          textColor="#D9534F"
                        />
                      )}
                      {_item?.waReferral?.source_id && (
                        <FilterToggle text="CTWA" bg="#FFDBDA" fontSize={12} textColor="#D9534F" />
                      )}
                      {_item?.unread_count && !isSelected ? (
                        <FilterToggle text="New" bg="#ECE1FF" fontSize={12} textColor="#8E53F7" />
                      ) : (
                        <></>
                      )}
                      {_item?.status && (
                        <FilterToggle
                          text={
                            _item?.status?.toLowerCase() === 'unresolved' ? 'Open' : _item?.status
                          }
                          bg={
                            _item?.status?.toLowerCase() === 'resolved'
                              ? '#D6FFD6'
                              : _item?.status?.toLowerCase() === 'pending'
                                ? '#FFDEB8'
                                : _item?.status?.toLowerCase() === 'expired'
                                  ? '#FFDBDA'
                                  : _item?.status?.toLowerCase() === 'unresolved'
                                    ? '#D2F5FF'
                                    : ''
                          }
                          textColor={
                            _item?.status?.toLowerCase() === 'resolved'
                              ? '#5CB85C'
                              : _item?.status?.toLowerCase() === 'pending'
                                ? '#D77400'
                                : _item?.status?.toLowerCase() === 'expired'
                                  ? '#D9534F'
                                  : _item?.status?.toLowerCase() === 'unresolved'
                                    ? '#5BC0DE'
                                    : ''
                          }
                          fontSize={12}
                        />
                      )}
                    </div>
                  </div>
                  <div style={{...styles.content.listItemInfo,alignItems:'flex-end'}}>
                    {_item?.lastReply?.time && (
                      <p style={styles.content.timeStamp}>{TimeToIst(_item?.lastReply?.time)}</p>
                    )}
                    <p className="d-flex" style={styles.content.agentName}>
                      {_item?.workflowActive ? (
                        <RiRobot2Line style={{ marginInlineEnd: '5px' }} />
                      ) : (
                        <MdOutlineSupportAgent style={{ marginInlineEnd: '5px' }} />
                      )}{' '}
                      <p style={{ fontSize: 12 }}>
                        {' '}
                        {_item?.workflowActive ? 'Chatbot' : _item?.agent?.name ?? 'Unassigned'}
                      </p>
                    </p>
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
        })}
        {showButton && (
          <button
            onClick={() => {
              if (selectedItemRef.current) {
                selectedItemRef.current.scrollIntoView({ behavior: 'smooth' });
              }
            }}
            style={{
              position: 'sticky',
              bottom: '15%',
              left: '30%',
              backgroundColor: '#ECE1FF',
              borderRadius: '10px',
              padding: '10px',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              cursor: 'pointer',
              zIndex: 1,
              border: 'none',
              fontSize: '12px',
              color: '#8E53F7',
            }}
          >
            Scroll to current chat
          </button>
        )}
      </div>
    );
  };

  const loadMoreItems = () => {

    if (waInboxloading || !hasNext) return;
    setwaInboxLoading(true);
    setTimeout(() => {
      if (typeof window !== 'undefined') {
        setScrollPosition(window?.scrollY);
      }
      eventsend('Load_more_button_Inbox_page_clicked');
      setPage((prevPage) => prevPage + 1);
      setwaInboxLoading(false)
    }, 1000);
    
  };


  // useEffect(() => {
  //   const handleScroll = () => {
  //     const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight - 200;
  //     if (bottom) {
  //       loadMoreItems();
  //     }
  //   };

  //   window.addEventListener('scroll', handleScroll);
  //   return () => window.removeEventListener('scroll', handleScroll);
  // }, [waInboxloading, hasNext]);

  // useEffect(() => {
  //   const observer = new IntersectionObserver((entries) => {
  //     if (entries[0].isIntersecting ) {
  //       loadMoreItems();
  //     }
  //   });

  //   if (loaderRef.current) {
  //     observer.observe(loaderRef.current);
  //   }

  //   return () => {
  //     if (loaderRef.current) {
  //       observer.unobserve(loaderRef.current);
  //     }
  //   };
  // }, [waInboxloading, hasNext]);

  useEffect(() => {
    let initialLoadSkipped = false;
    
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && initialLoadSkipped) {
        setFirstloading(false);
        loadMoreItems();
      } else if (entries[0].isIntersecting && !initialLoadSkipped) {
        setFirstloading(false);
        initialLoadSkipped = true;
      }
    });

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [waInboxloading, hasNext]);

  // const handleLoadMore = () => {
  //   if (typeof window !== 'undefined') {
  //     setScrollPosition(window?.scrollY);
  //   }
  //   eventsend('Load_more_button_Inbox_page_clicked');
  //   setPage((prevPage) => prevPage + 1);
  // };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window?.scrollTo(0, scrollPosition);
    }
  }, [list]);

  return (
    <div style={{ ...styles.container, overflowX: 'hidden' }}>
      {!isChatLoad ? (
        <div
          style={{
            flex: 1,
            display: 'flex',
            height: `100%`,
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <p style={{ margin: 0, fontSize: 14, color: colors.greys04 }}>
            Your chat will appear here
          </p>
        </div>
      ) : (
        <>
          <div style={styles.header.container}>
            <div style={styles.header.detailsWithActions}>
              <div style={styles.header.details}>
                {!agentUserData && (
                  <div
                    style={{
                      ...styles.row,
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '8px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        waData?.phone_number.includes('+')
                          ? waData?.phone_number
                          : `+${waData?.phone_number}`
                      );
                      eventsend('Clipper__Inbox_page_clicked');
                      toast.success('Copied to clipboard');
                    }}
                  >
                    <div style={{ ...styles.column }}>
                      <h3 style={styles.header.h3}>
                        {/* +91{' '}
                        {(waData?.phone_number?.replace(/^91/, '').slice(-10) || '').replace(
                          /(\d{5})(\d{5})/,
                          '$1-$2'
                        )} */}
                        {waNumber?.countryCallingCode
                          ? `(${waNumber?.countryCallingCode})-${waNumber?.nationalNumber}`
                          : waData?.phone_number}
                      </h3>
                      <span style={styles.header.span}>WABA Number</span>
                    </div>
                    <Tooltip title="Copy Number" style={{}}>
                      <img
                        src={images.Copy}
                        height={22}
                        width={22}
                        style={styles.header.tinyIcon}
                      />
                    </Tooltip>
                  </div>
                )}
                {agentUserData && (
                  <Dropdown
                    style={{
                      borderRadius: '13px',
                      fontSize: '12px',
                    }}
                  >
                    <Dropdown.Toggle
                      className="d-flex"
                      style={{
                        backgroundColor: 'white',
                        borderRadius: '13px',
                        color: '#666666',
                        border: '1px solid #666666',
                        fontWeight: '400',
                        alignItems: 'center',
                        fontSize: '12px',
                      }}
                      variant="success"
                      id="dropdown-basic"
                    >
                      <div className="d-flex" style={{ alignItems: 'center' }}>
                        <div
                          style={{
                            width: '8px',
                            height: '8px',
                            backgroundColor:
                              agentActivityStatus == 'online'
                                ? '#04B100'
                                : agentActivityStatus == 'away'
                                  ? '#FEAF52'
                                  : '#FF0000',
                            borderRadius: '50%',
                            marginInlineEnd: '5px',
                          }}
                        ></div>
                        <p style={{ fontSize: '12px', marginInlineEnd: '10px' }}>
                          {agentActivityStatus == 'online'
                            ? 'Active'
                            : agentActivityStatus == 'away'
                              ? 'Away'
                              : 'Offline'}{' '}
                        </p>
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          setAgentActivityStatus('online');
                        }}
                      >
                        {' '}
                        <div className="d-flex" style={{ alignItems: 'center' }}>
                          <div
                            style={{
                              width: '8px',
                              height: '8px',
                              backgroundColor: '#04B100',
                              borderRadius: '50%',
                              marginInlineEnd: '10px',
                            }}
                          ></div>
                          <div>
                            <p style={{ fontSize: '16px' }}>Active</p>
                            <p style={{ fontSize: '12px', color: '#666666' }}>
                              Chat will assign automatically
                            </p>
                          </div>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setAgentActivityStatus('away');
                        }}
                      >
                        {' '}
                        <div className="d-flex" style={{ alignItems: 'center' }}>
                          <div
                            style={{
                              width: '8px',
                              height: '8px',
                              backgroundColor: '#FEAF52',
                              borderRadius: '50%',
                              marginInlineEnd: '10px',
                            }}
                          ></div>
                          <div>
                            <p style={{ fontSize: '16px' }}>Away</p>
                            <p style={{ fontSize: '12px', color: '#666666' }}>
                              Chat will not be assigned{' '}
                            </p>
                          </div>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setAgentActivityStatus('offline');
                        }}
                      >
                        <div className="d-flex" style={{ alignItems: 'center' }}>
                          <div
                            style={{
                              width: '8px',
                              height: '8px',
                              backgroundColor: '#FF0000',
                              borderRadius: '50%',
                              marginInlineEnd: '10px',
                            }}
                          ></div>
                          <div>
                            <p style={{ fontSize: '16px' }}>Offline</p>
                            <p style={{ fontSize: '12px', color: '#666666' }}>
                              Chat will not be assigned{' '}
                            </p>
                          </div>
                        </div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
              <div style={styles.header.actionItems}>
                {paneWidth > 400 ? (
                  <>
                    <Tooltip title="Add Contact">
                      <CardImg
                        className="filterToggleHover"
                        id="AddContact"
                        src={images.PlusIcon}
                        onClick={(e) => {
                          e.stopPropagation();
                          eventsend('Start_new_chat_Inbox_page_clicked');
                          setAddNewChatPopUp(!addNewChatPopUp);
                          setNewNumber('')
                        }}
                        style={{
                          cursor: 'pointer',
                          // border: '1px solid red',
                          marginLeft: '5px',
                          height: 30,
                          width: 30,
                          borderRadius: '10px',
                          padding: '6px',
                          backgroundColor: colors.grey,
                        }}
                      />
                    </Tooltip>
                    <Tooltip title="Filters">
                      <CardImg
                        className="filterToggleHover"
                        id="FilterMenu"
                        src={
                          showFilters || (check && topFilter) ? images.fillFilter : images.filter
                        }
                        onClick={() => setShowFilters(!showFilters)}
                        style={{
                          cursor: 'pointer',
                          // border: '1px solid red',
                          height: 30,
                          width: 30,
                          borderRadius: '10px',
                          padding: '6px',
                          backgroundColor:
                            showFilters || (check && topFilter) ? colors.darkPurple : colors.grey,
                        }}
                      />
                    </Tooltip>
                    <Tooltip title="Distribute Leads">
                      <CardImg
                        className="filterToggleHover"
                        src={images.DISTICON}
                        onClick={() => setShowDistributeLeads(true)}
                        style={{
                          cursor: 'pointer',
                          // border: '1px solid red',
                          height: 30,
                          width: 30,
                          borderRadius: '10px',
                          padding: '6px',
                          backgroundColor: colors.grey,
                        }}
                      />
                    </Tooltip>
                    {!excepted && (
                      <Dropdown>
                        <Dropdown.Toggle
                          as={CustomToggle}
                          style={{ display: 'none' }}
                        ></Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                              setShowMailCSV(true);
                            }}
                          >
                            Export Report
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              setMailFormData(true);
                            }}
                          >
                            Export Form Messages
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </>
                ) : (
                  <>
                    <Tooltip title="Filters">
                      <CardImg
                        className="filterToggleHover"
                        id="FilterMenu"
                        src={
                          showFilters || (check && topFilter) ? images.fillFilter : images.filter
                        }
                        onClick={() => setShowFilters(!showFilters)}
                        style={{
                          cursor: 'pointer',
                          // border: '1px solid red',
                          height: 30,
                          width: 30,
                          borderRadius: '10px',
                          padding: '6px',
                          backgroundColor:
                            showFilters || (check && topFilter) ? colors.darkPurple : colors.grey,
                        }}
                      />
                    </Tooltip>
                    <Dropdown>
                      <Dropdown.Toggle
                        as={CustomToggle}
                        style={{ display: 'none' }}
                      ></Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => {
                            eventsend('Distribute_leads_Inbox_page_clicked');
                            setShowDistributeLeads(true);
                          }}
                          className="d-flex"
                        >
                          Distribute Leads
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setAddNewChatPopUp(!addNewChatPopUp);
                          }}
                        >
                          Add Contact
                        </Dropdown.Item>
                        {!excepted && (agentdata?.permission?.reportExport || !agentdata)&&(
                          <Dropdown.Item
                            onClick={() => {
                              setShowMailCSV(true);
                            }}
                          >
                            Export Report
                          </Dropdown.Item>
                        )}
                        <Dropdown.Item
                          onClick={() => {
                            setShowFilters(!showFilters);
                          }}
                        >
                          Filters
                        </Dropdown.Item>
                        {(agentdata?.permission?.messageExport || !agentdata) &&(
                          <Dropdown.Item
                          onClick={() => {
                            setMailFormData(true);
                          }}
                        >
                          Export Form Messages
                        </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </>
                )}

                {addNewChatPopUp && (
                  <ClickAwayListener onClickAway={() => setAddNewChatPopUp(false)}>
                    <div
                      class="addNewChatDropDown"
                      style={{
                        padding: '16px',
                        width: '100%',
                        marginInlineStart: MobileWidth ? 0 : 10,
                        top: MobileWidth ? '80px' : '70px',
                        maxWidth: '300px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          marginBottom: 8,
                          alignItems: 'center',
                        }}
                      >
                        <label
                          style={{
                            fontWeight: '700',
                          }}
                        >
                          Phone
                        </label>
                        <img
                          src={images.CMClose}
                          onClick={() => {
                            setAddNewChatPopUp(!addNewChatPopUp);
                          }}
                          style={{
                            cursor: 'pointer',
                            height: 20,
                            width: 20,
                            boxShadow: 'none',
                          }}
                        ></img>
                      </div>
                      <div
                        className="d-flex justify-content"
                        style={{ alignItems: 'center', width: '100%' }}
                      >
                        {/* <Form.Control
                        type="text"
                        placeholder="Enter phone no with country code"
                        style={{
                          borderColor: '#CCCCCC',
                          paddingInlineStart: 6,
                          outline: 'none',
                          boxShadow: 'none',
                          borderRightWidth: '0px',
                        }}
                        onFocus={(e) => {
                          e.target.style.outline = 'none'; // Remove outline when focused
                          e.target.style.borderColor = '#CCCCCC';
                        }}
                        value={newNumber}
                        onChange={(e) => {
                          setNewNumber(e.target.value);
                        }}

                        // onKeyDown={(e) => {
                        //   if (e === 'Enter') {
                        //     e.preventDefault();
                        //     handleAddNewContact();
                        //   }
                        // }}
                      /> */}
                        <Autocomplete
                          clearOnEscape
                          disablePortal
                          id="combo-box-demo"
                          style={{
                            marginBottom: '5px',
                            width: '90%',
                          }}
                          size="small"
                          renderInput={(params) => (
                            <TextField
                              name="variable"
                              type='number'
                              // value={item.variable}
                              // onChange={(e) => {
                              //   handleChange(index, e);
                              // }}
                              size={'small'}
                              placeholder={'Type no. with country code'}
                              className={'restylingLabels'}
                              InputProps={{
                                notched: false,
                                style: {
                                  borderRadius: 4,
                                },
                                inputProps: {
                                  inputMode: 'numeric',
                                  maxLength: 15,
                                  pattern: '[0-9]*',
                                  style: {
                                    appearance: "textfield",
                                    MozAppearance: "textfield",
                                    WebkitAppearance: "none",
                                  },
                                },
                              }}
                              onChange={(e) => {
                                const newValue = e.target.value;
                                if (!/^\d{0,15}$/.test(newValue)) {
                                  setError('Please enter numbers only.');
                                }
                                else {
                                  setError('');
                                }
                                setNewNumber(newValue.replace(/\D/g, ''));
                              }}
                              helperText={error}
                              error={!!error}
                              {...params}
                            />
                          )}
                          value={newNumber.replace(/\D/g, '')}
                          // onInputChange={(e, newValue) => {
                          //   if (newValue) {
                          //     handleInputChange(newValue);
                          //   } else {
                          //     setNewNumber('');
                          //   }
                          // }}
                          onChange={(e, newValue) => {
                            if (newValue) {
                              handleInputChange(newValue.replace(/\D/g, ''));
                            } else {
                              setNewNumber('');
                            }
                          }}
                          freeSolo={true}
                          options={numberList.map((item) => item.user_details.phone)}
                          getOptionLabel={(option) => {
                            return `${option}`; // Since we are directly mapping the phone numbers
                          }}
                          inputMode="numeric"
                        />

                        <FiSend
                          onClick={() => {
                            if (!isSendButtonDisabled()) {
                              eventsend('Send_button_new_chat_popup_clicked');
                              handleAddNewContact();
                            }
                          }}
                          disabled={isSendButtonDisabled()}
                          style={{
                            color: isSendButtonDisabled() ? '#CCC' : '#8E53F7',
                            cursor: 'pointer',
                            marginInlineStart: '8px',
                            marginBlockEnd: error ? '24px' : '0px',
                          }}
                          size={18}
                        />
                      </div>
                      <p
                        style={{
                          fontSize: '12px',
                          color: '#666666',
                          fontWeight: '400',
                          marginBlockStart: '8px',
                        }}
                      >
                        Eg: 918876392001
                      </p>
                    </div>
                  </ClickAwayListener>
                )}
              </div>
            </div>
            <div style={styles.header.searchBox}>
      <InputGroup>
        <Dropdown
      
          onSelect={(eventKey) => setSearchType(eventKey)}
          show={searchDropdownOpen}
          onToggle={(isOpen) => setSearchDropdownOpen(isOpen)}
        >
          <Dropdown.Toggle
            variant="outline-secondary"
            style={{
              backgroundColor: 'transparent',
              borderColor: '#B3B3B3',
              borderRight: 0,
              minWidth: '120px',
              zIndex:1,
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = 'transparent';
              e.currentTarget.style.color = '#333';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = 'transparent';
              e.currentTarget.style.color = '#333';
            }}
          >
            {searchType === 'contact' ? 'Name/Phone' : 'Messages'}
          </Dropdown.Toggle>
          <Dropdown.Menu
            style={{
              zIndex:1,
            }}
          >
            <Dropdown.Item eventKey="contact">Name/Phone</Dropdown.Item>
            <Dropdown.Item eventKey="message">Messages</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <InputGroup.Text
          style={{
            borderRight: 0,
            borderLeft: 0,
            backgroundColor: 'transparent',
            borderColor: '#B3B3B3',
            paddingInline: 8,
          }}
        >
          <IoIosSearch size={16} />
        </InputGroup.Text>
        <Form.Control
          type="text"
          placeholder={searchType === 'contact' ? "Search by name or phone" : "Search in messages"}
          style={{
            borderTopRightRadius: searchInbox ? '0' : 8,
            borderBottomRightRadius: searchInbox ? '0' : 8,
            borderColor: '#B3B3B3',
            borderLeftWidth: 0,
            paddingInlineStart: 0,
            zIndex: 1,
            outline: 'none',
            boxShadow: 'none',
            borderRightWidth: searchInbox ? '0' : '',
          }}
          onFocus={(e) => {
            e.target.style.outline = 'none';
            e.target.style.borderColor = '#B3B3B3';
          }}
          value={searchInbox}
          onChange={(e) => {
            setSearchInbox(e.target.value);
            setShowButton(false);
          }}
        />
        {searchInbox && (
          <InputGroup.Text
            onClick={() => {
              setSearchInbox('');
              eventsend('Search_bar_Inbox_page_clicked');
            }}
            style={{
              borderLeftWidth: 0,
              backgroundColor: 'transparent',
              borderColor: '#B3B3B3',
              paddingInline: 8,
              cursor: 'pointer',
            }}
          >
            <RxCross1 size={16} />
          </InputGroup.Text>
        )}
      </InputGroup>
    </div>
            {topFilter && check && (
              <div style={styles.header.filterCount}>
                <p style={{ fontWeight: '700' }}>{`Filtered Chats (${filteredCount})`}</p>
                <p
                  style={{ color: colors.darkPurple, cursor: 'pointer' }}
                  onClick={() => {
                    eventsend('Remove_filters_Inbox_page_clicked');
                    removeFilterAction();
                    setAppliedFilter('All');
                    setTopFilter(false);
                    setPage(1);
                  }}
                >
                  Remove filters
                </p>
              </div>
            )}
           { searchType==='contact' && (<div style={styles.header.filterActions}>
              {!topFilter &&
                [
                  `All (${chatCount?.All})`,
                  chatCount?.Unread > -1 ? `Unread (${chatCount?.Unread})` : `Unread`,
                  chatCount?.Unresolved > -1 ? `Open (${chatCount?.Unresolved})` : `Open`,
                  chatCount?.Pending > -1 ? `Pending (${chatCount?.Pending})` : `Pending`,
                  chatCount?.Resolved > -1 ? `Resolved (${chatCount?.Resolved})` : `Resolved`,
                  // chatCount?.ChatOpen > -1 ? `Chat open (${chatCount?.ChatOpen})` : `Chat open`,
                  chatCount?.ChatClosed > -1
                    ? `Chat expired (${chatCount?.ChatClosed})`
                    : `Chat expired`,
                  chatCount?.OpenandPending > -1
                    ? `Open + Pending (${chatCount?.OpenandPending })` : `Open + Pending`,
                  chatCount?.ExpiredandPending > -1
                    ? `Expired + Pending (${chatCount?.ExpiredandPending})` : `Expired + Pending`,
                ].map((item, index) => (
                  <FilterToggle
                    text={item}
                    onClick={() => {
                      eventsend('Quick_filters_Inbox_page_clicked');
                      setSelectedFilter(item);
                      if (item.includes('All')) {
                        removeFilterAction();
                        setAppliedFilter('All');
                        setPage(1);
                        return;
                      } else if (item.includes('Open + Pending')) {
                        console.log('Applying filter for Chat open and pending', filters);
                        setFilters((prev) => {
                          return { ...prev, status: ['Pending'], general: ['true'] };
                        });
                        setAppliedFilter('OpenandPending');

                      }
                      else if (item.includes('Expired + Pending')) {
                        // console.log('Applying filter for Chat open and expired', filters);
                        setFilters((prev) => {
                          return { ...prev, general: ['false'], status: ['Pending'] };
                        });
                        setAppliedFilter('ExpiredandPending');

                      }

                      else if (item.includes('Open')) {
                        setFilters((prev) => {
                          return { ...prev, status: ['Unresolved'], general: [] };
                        });
                        setAppliedFilter('Unresolved');
                      } else if (item.includes('Pending')) {
                        setFilters((prev) => {
                          return { ...prev, status: ['Pending'], general: [] };
                        });
                        setAppliedFilter('Pending');
                      } else if (item.includes('Resolved')) {
                        setFilters((prev) => {
                          return { ...prev, status: ['Resolved'], general: [] };
                        });
                        setAppliedFilter('Resolved');
                      } else if (item.includes('Chat open')) {
                        setFilters((prev) => {
                          return { ...prev, general: ['true'], status: [] };
                        });
                        setAppliedFilter('ChatOpen');
                      } else if (item.includes('Chat expired')) {
                        setFilters((prev) => {
                          return { ...prev, general: ['false'], status: [] };
                        });
                        setAppliedFilter('ChatClosed');
                      } else if (item.includes('Unread')) {
                        setAppliedFilter('Unread');
                        setFilters((prev) => {
                          return { ...prev, general: ['Unread'], status: [] };
                        });
                        // const unreadlist = list.filter((chat) => {
                        //   return chat.unread_count > 0;
                        // });
                        // setInboxList(unreadlist);
                        // return;
                      }

                      setPage(1);
                      setInboxList([]);
                      setShowFilters(false);
                      console.log('jjjjjjj');
                      setChatData(null);
                    }}
                    key={index}
                    selected={
                     ( ()=>{
                         if(selectedFilter.includes('Open') && !(selectedFilter.includes('Pending'))){
                         if(item.includes('Open') && (item.includes('Pending'))){
                          return false
                         }
                         }
                         else if(selectedFilter.includes('Pending') && !(selectedFilter.includes('Expired')) &&  !(selectedFilter.includes('Open'))){
                          if(item.includes('Pending') && ((item.includes('Expired')) ||  (item.includes('Open')))){
                           return false
                          }
                          }
                          return item.includes(selectedFilter.split('(')[0])
                      })() 
                      ||
                      (item.includes('All') && selectedFilter.includes('All'))
                    }
                  />
                ))}
            </div>)}
            {showDistributePopUp && list.length > 0 && !check && (
              <div
                className="justify-content-between"
                style={{
                  marginTop: '-11px',
                  position: 'absolute',
                  width: '20vw',
                  backgroundColor: '#333',
                  color: '#fff',
                  left: '37%',
                  borderRadius: '0px 8px 8px 8px',
                  padding: '10px',
                  display: MobileWidth ? 'none' : 'flex',
                }}
              >
                <p style={{ fontSize: '12px' }}>
                  {' '}
                  Now you can distribute all your chats between agent by clicking on above button.
                </p>
                <IoMdCloseCircleOutline
                  style={{ width: '20px', cursor: 'pointer' }}
                  onClick={handleClose}
                />
              </div>
            )}
            {showMailCSV && (
              <MailRepliesModal
                show={showMailCSV}
                setShow={setShowMailCSV}
                mailApiCall={downloadCsvApiCall}
                email={email}
                setEmail={setEmail}
              ></MailRepliesModal>
            )}
            {mailFormData && (
              <MailRepliesModal
                show={mailFormData}
                reportName={'Export Form Messages'}
                setShow={setMailFormData}
                mailApiCall={mailFormDataApiCall}
                email={email}
                setEmail={setEmail}
              ></MailRepliesModal>
            )}
            {showFilters && (
              <Filters
                setShowFilters={setShowFilters}
                filters={filters}
                setFilters={setFilters}
                agents={agentData}
                setInboxList={setInboxList}
                // getInboxListing={getInboxListing}
                setPage={setPage}
                setChatData={setChatData}
                setAppliedFilter={setAppliedFilter}
                setTopFilter={setTopFilter}
                removeFilterAction={removeFilterAction}
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
              />
            )}
          </div>
          <div
            className="verythinscrollnew"
            ref={containerRef}
            style={{
              overflowY: 'auto',
              // height: '100%',
              width: '100%',
              flex: 1,
            }}
          >
            <div style={styles.content.listView}>
              {list?.length > 0 &&
                renderItemsWithBreakers(list, containerRef, showButton, setShowButton)
              }
              <div className="d-flex justify-content-center" ref={loaderRef}>
                {waInboxloading || firstloading ? (
                  <SpinnerLoader />
                ) : !hasNext && (
                  <p style={{fontWeight:500,width:'100%',textAlign:'center'}}>
                    {'No more chats'}
                  </p>
                )}
              </div>
                {/* {waInboxloading && <div>Loading...</div>}
                {!waInboxloading && !hasNext && <div>No more chats</div>} */}
              {/* {hasNext ? (
                <div className="d-flex justify-content-center" style={{ marginBottom: '20px' }}>
                  <CheerioButton
                    btnText={'Load More'}
                    textStyle={{ color: colors.black }}
                    backColor={colors.white}
                    borderStyle={{ width: '70%' }}
                    onclick={handleLoadMore}

                  />
                </div>
              ) : (
                <div className="d-flex justify-content-center" style={{ marginTop: '5px' }}>
                  No more chats found
                </div>
              )} */}
            </div>
          </div>
        </>
      )}
      <DistributedLeads
        show={showDistributeLeads}
        setShow={setShowDistributeLeads}
        agents={agentData}
        totalChats={totalChats}
        getInboxListing={getInboxListing}
        setInboxList={setInboxList}
      />
    </div>
  );
}

WAInbox.propTypes = {};

export default WAInbox;

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    backgroundColor: colors.white,
    // border: '1px solid red',
  },

  header: {
    container: {
      // paddingTop: '16px',
      paddingBottom: '16px',
      // border: '1px solid green',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.white,
      gap: '16px',
    },
    detailsWithActions: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'relative',
      padding: '16px',

      borderBottom: '1px solid #B3B3B3',
    },
    details: {
      // border: '1px solid red',
      display: 'flex',
      flexDirection: 'column',
      // alignItems: 'center',
      // justifyContent: 'left',
    },
    actionItems: {
      // border: '1px solid red',
      display: 'flex',
      alignItems: 'center',
      gap: 10,
      justifyContent: 'end',
    },
    h3: {
      color: colors.black,
      fontWeight: '700',
      fontSize: '18px',
      textWrap: 'nowrap',
    },
    tinyIcon: {
      // border: '1px solid red',
      cursor: 'pointer',
      // alignSelf: 'flex-start',
      // marginTop: '20px', // TODO: need better solution
    },
    span: {
      color: colors.darkgrey,
      fontSize: '12px',
      fontWeight: '500',
    },
    searchBox: {
      width: '100%',
      display: 'flex',
      paddingLeft: '16px',
      paddingRight: '16px',
    },
    filterCount: {
      width: '100%',
      display: 'flex',
      paddingLeft: '18px',
      paddingRight: '18px',
      fontSize: '14px',
      justifyContent: 'space-between',
    },
    filterActions: {
      scrollbarWidth: 'none',
      width: '100%',
      display: 'flex',
      paddingLeft: '16px',
      paddingRight: '16px',
      gap: '10px',
      flexWrap: 'wrap',
    },
  },

  content: {
    container: {
      // border: '1px solid yellow',
      overflowY: 'auto',
      scrollbarWidth: 'thin',
      scrollbarColor: '#B3B3B3 #F5F5F5',
      height: '100%',
      width: '100%',
    },
    listView: {
      display: 'flex',
      backgroundColor: colors.white,
      flexDirection: 'column',
      padding: '12px',
      marginBottom: '10px',
      gap: '4px',
    },
    listItem: {
      // border: '1px solid red',

      minHeight: '100px',
      overflow: 'hidden',
      padding: 12,
      width: '100%',

      display: 'flex',
      justifyContent: 'space-between',
    },
    listItemWrapper: {
      padding: 12,
      width: '100%',
      cursor: 'pointer',
      borderradius: '8px',
    },
    listItemTitle: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    listItemTitleText: {
      fontWeight: '600',
      fontSize: 16,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    tagItems: {
      marginTop: '8px',
      gap: '8px',
      display: 'flex',
      justifyContent: 'flex-start',
    },
    listItemInfo: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    itemBody: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: 200,
      color: '#666666',
      overflow: 'hidden',
    },
    agentName: {
      marginTop: 0,
      fontWeight: 'normal',
      fontSize: 12,
      color: '#666666',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      alignSelf: 'flex-end',
      alignItems: 'center',
    },
    timeStamp: {
      fontWeight: 'normal',
      fontSize: 13,
      color: '#666666',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    unreadMessageCount: {
      container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '100%',
        width: 24,
        height: 20,
        backgroundColor: '#9357FF',
        alignSelf: 'center',
      },
      p: {
        fontWeight: 'normal',
        fontSize: 10,
        color: colors.white,
        textAlign: 'center',
      },
    },
  },

  row: {
    display: 'flex',
    flexDirection: 'row',
  },

  column: {
    display: 'flex',
    flexDirection: 'column',
  },

  numbertitle: { marginLeft: 12, color: colors.black, fontWeight: 'bolder' },
  searchBox: {
    display: 'flex',
    flexDirection: 'row',
    margin: 12,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 'auto',
    height: 36,
    paddingLeft: 10,
    paddingRight: 10,
  },
  rowItem: {
    padding: 12,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
};

const ContactItem = styled.div`
  padding: 12;
  width: '100%';
  cursor: 'pointer';
  borderradius: '8px';

  &:hover {
    border: '1px solid black';
  }
`;

const FilterToggle = ({
  text,
  onClick,
  bg,
  textColor,
  fontSize,
  textStyle = {
    fontWeight: 500,
  },
  enableHoverEffect = true,
  selected = false,
}) => {
  const baseStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '10px',
    cursor: 'pointer',
    backgroundColor: selected ? colors.lightPurple : bg ?? colors.white03,
    padding: '5px 10px',
    maxHeight: '30px',
    boxShadow: 'none',
  };

  const hoverStyle = enableHoverEffect
    ? {
      '&:hover': {
        backgroundColor: selected ? colors.lightPurple : bg ?? colors.white03,
        boxShadow: 'none',
      },
    }
    : {};

  return (
    <div
      style={{ ...baseStyle, ...hoverStyle }}
      onClick={(e) => {
        e.preventDefault();
        onClick && onClick(e);
      }}
    >
      <p
        style={{
          textWrap: 'nowrap',
          fontSize: fontSize ?? 14,
          color: selected ? colors.darkPurple : textColor ? textColor : colors.greys01,
          ...textStyle,
        }}
      >
        {text}
      </p>
    </div>
  );
};

const DateBreakComponent = ({ date }) => {
  let textToShow = '';
  const today = new Date();
  const dateObj = new Date(date);
  if (dateObj.getDate() === today.getDate()) {
    textToShow = `Today`;
  } else if (dateObj.getDate() === today.getDate() - 1) {
    textToShow = `Yesterday`;
  } else {
    textToShow = date;
  }

  return (
    <div style={{ ...styles.row, gap: '8px', paddingInline: '16px' }}>
      <hr style={{ flex: 1 }} />
      <FilterToggle
        text={textToShow}
        key={date}
        enableHoverEffect={false}
        textStyle={{ fontWeight: 500 }}
      />
      <hr style={{ flex: 1 }} />
    </div>
  );
};
