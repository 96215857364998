import { getPaymentByUserId } from '../../../Services';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import CancelSubscriptionModal from './CancelSubscriptionModal';
import CheerioButton from '../../../Components/CheerioButton';
import { createInvoice } from '../../../Services';
import SpinnerDarkModal from '../../../Components/SpinnerDarkModal';

const planDetails = [
    // {name: "Basic", duration: "Quarterly", priceINR: '4,500', priceUSD: '56', INRID: 'plan_OBsrkWdl90hiYZ', USDID: 'plan_OSAQhGj7slsjPf'},
    { name: "Basic", duration: "Quarterly", priceINR: '4,500', priceUSD: '56', INRID: 'plan_OyCNaABf59WSMO', USDID: 'plan_OSAQhGj7slsjPf' },
    { name: "Startup", duration: "Quarterly", priceINR: '9,000', priceUSD: '113', INRID: 'plan_OyCPF8T7TiRc6A', USDID: 'plan_OSASamBeIYDFtB' },
    { name: "Growth", duration: "Quarterly", priceINR: '15,000', priceUSD: '188', INRID: 'plan_OyCQApFJFdJTqI', USDID: 'plan_OSATjFb7Apvowu' },
    { name: "Pro", duration: "Quarterly", priceINR: '20,000', priceUSD: '250', INRID: 'plan_OyCR3y5LHeMQKL', USDID: 'plan_OSAUdRUvleKmf6' },
    { name: "Enterprise", duration: "Quarterly", priceINR: '70,000', priceUSD: '875', INRID: 'plan_OyCRqbjVLaR3QG', USDID: 'plan_OSAVJLbMULJTU4' },
    { name: "Basic", duration: "Half-Yearly", priceINR: '7,500', priceUSD: '94', INRID: 'plan_OyCTRCXBKTIvg0', USDID: 'plan_OTJfFLMqpOMMQW' },
    { name: "Startup", duration: "Half-Yearly", priceINR: '15,000', priceUSD: '188', INRID: 'plan_OyCU80cFjVWIiL', USDID: 'plan_OTJigtGioONAId' },
    { name: "Growth", duration: "Half-Yearly", priceINR: '21,000', priceUSD: '263', INRID: 'plan_OyCV8j9XXqFB5z', USDID: 'plan_OTJlRB9Y4Cx8g6' },
    { name: "Pro", duration: "Half-Yearly", priceINR: '27,000', priceUSD: '338', INRID: 'plan_OyCW5dwoi4RNyw', USDID: 'plan_OTJnFdKl9FkqTY' },
    { name: "Enterprise", duration: "Half-Yearly", priceINR: '120,000', priceUSD: '1,500', INRID: 'plan_OyCWsK02DtUGGj', USDID: 'plan_OTJvKy4q6aMBqm' },
    { name: "Basic", duration: "Yearly", priceINR: '12,000', priceUSD: '150', INRID: 'plan_OyCXxb0OQKcuF2', USDID: 'plan_OTK3czSgCcIbtT' },
    { name: "Startup", duration: "Yearly", priceINR: '24,000', priceUSD: '300', INRID: 'plan_OyCa7f7nLYgGpZ', USDID: 'plan_OTK4xBePPvCsgZ' },
    { name: "Growth", duration: "Yearly", priceINR: '36,000', priceUSD: '450', INRID: 'plan_OyCac2ehZK1zsZ', USDID: 'plan_OTK7MlmvW0JCRR' },
    { name: "Pro", duration: "Yearly", priceINR: '48,000', priceUSD: '600', INRID: 'plan_OyCbEeFG2gayDo', USDID: 'plan_OTK9hXOmFvYmoo' },
    { name: "Enterprise", duration: "Yearly", priceINR: '200,000', priceUSD: '2,500', INRID: 'plan_OyCc4AbjOyMslf', USDID: 'plan_OTKBhayTDmzmth' },
]

const createPlanAmountMap = (planDetails) => {
    const planAmountMap = new Map();

    planDetails.forEach(plan => {
        // Add INR amount mapping
        const inrAmount = parseFloat(plan.priceINR.replace(',', ''));
        planAmountMap.set(inrAmount, `${plan.name} Plan (${plan.duration})`);

        // Add USD amount mapping
        const usdAmount = parseFloat(plan.priceUSD.replace(',', ''));
        planAmountMap.set(usdAmount, `${plan.name} Plan (${plan.duration})`);
    });

    return planAmountMap;
};


// Create the map
const planAmountMap = createPlanAmountMap(planDetails);

// Function to get plan name from amount
const getPlanNameFromAmount = (amount) => {
    return planAmountMap.get(parseFloat(amount)) || 'Unknown Plan';
};


const PremiumPaymentHistory = () => {
    const [paymentHistory, setPaymentHistory] = useState([]);



    const [loading, setLoading] = useState(false);
    const authtoken = useSelector((state) => state.main.auth_token);

    const localdata = useSelector((state) => state.main.localdata);
    const walletCurrency = localdata?.walletCurrency ?? null;

    function formatDate(createdAt) {
        if (!createdAt) {
            throw new RangeError('Invalid time value: Date is undefined or null');
        }

        const date = new Date(createdAt);

        if (isNaN(date.getTime())) {
            throw new RangeError('Invalid time value: Date parsing failed');
        }

        const options = { year: 'numeric', month: 'short', day: '2-digit' };

        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

        let [month, day, year] = formattedDate.split(" ");

        day = day.padStart(2, '0');

        return `Active since ${month}, ${day} ${year}`;
    }
    const data = {
        'plan': ''
    };

    async function generateInvoice(subscriptionId) {
        try {
            setLoading(true);
            data.plan = subscriptionId;
            console.log(data);
            const token = authtoken;
            const response = await createInvoice(data, token);
            window.open(response?.response?.data, '_blank');
            setLoading(false);
        }
        catch (err) {
            setLoading(false);
            console.log(err);
        }
    }

    useEffect(() => {
        const fetchPaymentHistory = async () => {
            try {
                setLoading(true);
                const data = await getPaymentByUserId(authtoken);
                if (data?.flag) {
                    setPaymentHistory(data?.response?.data);
                    setLoading(false);
                }
            } catch (error) {
                console.error("Error fetching payment history:", error);
            }
        };

        fetchPaymentHistory();
    }, [authtoken]);

    console.log(paymentHistory);

    return (

        <div style={{ height: '100%', overflowY: 'auto' }}>
            {loading && <SpinnerDarkModal setOpenDarkSpinner={setLoading} />}
            <div
                style={{
                    paddingTop: '3%'
                }}
            >
                {paymentHistory && paymentHistory
                    ?.filter(payment => payment?.description === 'Premium')
                    ?.map(payment => (
                        <div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '70vw',
                                    paddingBottom: '2%'
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex'
                                    }}
                                >
                                    <div
                                        style={{
                                            paddingBottom: '10%'
                                        }}
                                    >
                                        <img style={{ width: '60px', height: '60px', marginRight: 1 }} src={images.planLogo}></img>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            paddingTop: '3%'
                                        }}
                                    >
                                        <div
                                            style={{
                                                fontWeight: 600,
                                                fontSize: 16,
                                                lineHeight: 1,
                                                paddingLeft: '1%',
                                            }}
                                        >
                                            {getPlanNameFromAmount(4500)}
                                        </div>
                                        <p
                                            style={{
                                                fontWeight: 400,
                                                fontSize: 14,
                                                color: '#666666',
                                                paddingLeft: '1%',
                                                width: '101%'
                                            }}
                                        >
                                            {payment?.createdAt ? formatDate(payment?.createdAt) : 'No such payment done'}
                                        </p>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        marginTop: -10
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            textAlign: 'right'
                                        }}
                                    >
                                        <p

                                        >
                                            <span
                                                style={{
                                                    fontWeight: 700,
                                                    fontSize: 16,
                                                    color: 'black'
                                                }}
                                            >
                                                {walletCurrency === 'INR' ? 
                                                    `₹ ${payment?.amount}` : 
                                                    `$ ${payment?.amount}`
                                                }
                                            </span>
                                            <p
                                                style={{
                                                    color: '#3366CC',
                                                    lineHeight: 0,
                                                    cursor: 'pointer',
                                                    textDecoration: 'underline',
                                                }
                                                }
                                                onClick={() => generateInvoice(payment?.subscriptionId)}
                                            >
                                                Download Invoice
                                            </p>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
        </div >
    )
}

export default PremiumPaymentHistory