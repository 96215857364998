import React, { useEffect, useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { SpinnerComponent, WAV2Preview } from '../../AnnouncementsNew/Components/NewComponents';
import { useSelector } from 'react-redux';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import { getImagePath } from '../../../Utils';
import { V2MediaIDAPI, V2WorkflowS3Link } from '../../../Services';
import CheerioButton from '../../../Components/CheerioButton';
import UploadValidator from '../../../Components/UploadValidator';
import UploaderText from '../../../Components/UploaderText';
import { Autocomplete, TextField } from '@mui/material';
import CarouselCardsComponent from '../../AnnouncementsNew/Components/CarouselCardsComponent';

const AnnDetailsWF = ({
  item,
  setHeaderType,
  headerType,
  headerFileName,
  setHeaderFileName,
  bodyPar,
  setBodyPar,
  // setMpm,
  actionList,
  setActionList,
  headerInputText,
  setHeaderInputText,
  setAnnv2CTA,
  s3Link,
  setS3Link,
  existingData,
  quickReplyBtn,
  nodeType,
  setQuickReplyBtn,
  inputFieldDropDown,
  attributesList,
  workFlow = false,
  setCarouselCardAPI,
  isShopifyHeader,
  setHeaderOption,
  headerOption,
}) => {
  const authtoken = useSelector((state) => state.main.auth_token);
  const localdata = useSelector((state) => state.main.localdata);

  const [mpm, setMpm] = useState(false);
  const [posMPM, setPosMPM] = useState(null);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);
  const [count, setCount] = useState();
  const [countURL, setCountURL] = useState();
  const [inputFields, setInputField] = useState([]);
  const [modifiedBody, setModifiedBody] = useState();
  const [URLexists, setURLexists] = useState(false);
  const [thumbnailId, setThumbnailId] = useState();
  // const  [actionList,setActionList] = useState({
  //   thumbnail_product_retailer_id: thumbnailId,
  //   sections:[]
  // })
  const [sectionList, setSectionList] = useState([
    {
      title: '',
      product_items: [{ product_retailer_id: '', uniqueId: uuidv4() }],
    },
  ]);
  const [productList, setProductList] = useState([{}]);
  const [title, setTitle] = useState();
  const [productId, setProductId] = useState('');
  const [mcta, setMcta] = useState([
    // {
    //   index: 0,
    //   type: 'url',
    //   value: '',
    // }
  ]);

  // const [URLvalue, setURLvalue] = useState('');

  // const [mediaID, setMediaID] = useState('');

  const [upFile, setUpFile] = useState();
  const [upFile64, setUpFile64] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  // const [upFileName, setUpFileName] = useState('');
  const [dragActive, setDragActive] = useState(false);

  const [EFields, setEfields] = useState(false);
  const [UFields, setUfields] = useState(false);

  const upDocRef = useRef();
  const upImageRef = useRef();
  const upVideoRef = useRef();
  const [carouselCards, setCarouselCards] = useState([]);
  const [carouselData, setCarouselData] = useState([]);

  const BodyElement = item?.components?.filter((e) => e?.type === 'BODY');
  const HeadElement = item?.components?.filter((e) => e?.type === 'HEADER');
  const ButtonElement = item?.components?.filter((e) => e?.type === 'BUTTONS');
  const CarouselElement = item?.components?.filter((e) => e?.type === 'CAROUSEL');

  const ButtonList = ButtonElement[0]?.buttons;
  const mobileWidth = window.innerWidth < 900 ? true : false;

  const getUniqueQuickReplies = (obj) => {
    const quickReplies = new Set();

    obj?.components?.forEach((component) => {
      if (component?.type === 'CAROUSEL') {
        component?.cards?.forEach((card) => {
          card?.components?.forEach((subComponent) => {
            if (subComponent?.type === 'BUTTONS') {
              subComponent?.buttons?.forEach((button) => {
                if (button?.type === 'QUICK_REPLY') {
                  quickReplies?.add(button?.text);
                }
              });
            }
          });
        });
      }
    });

    return Array?.from(quickReplies);
  };

  const checkCarousel = () => {
    if (CarouselElement) {
      console.log('carousel exists ---> ', CarouselElement);
      console.log('carousel exists ---> ', CarouselElement[0]?.cards);
      console.log('some', existingData?.carouselCards);

      setCarouselCards(CarouselElement[0]?.cards);
      for (let i = 0; i < CarouselElement[0]?.cards?.length; i++) {
        setCarouselData((prev) => {
          console.log('prev', prev);
          const temparr = [...prev];
          temparr[i] = {
            header: {
              type:
                existingData?.carouselCards && existingData?.carouselCards[i]?.header?.type
                  ? existingData?.carouselCards[i]?.header?.type
                  : '',
              text:
                existingData?.carouselCards && existingData?.carouselCards[i]?.header?.text
                  ? existingData?.carouselCards[i]?.header?.text
                  : '',
              fileName:
                existingData?.carouselCards && existingData?.carouselCards[i]?.header?.fileName
                  ? existingData?.carouselCards[i]?.header?.fileName
                  : '',
              link:
                existingData?.carouselCards && existingData?.carouselCards[i]?.header?.link
                  ? existingData?.carouselCards[i]?.header?.link
                  : '',
            },
            cta: [],
            bodyParameters: [],
          };
          return temparr;
        });
      }
    }
  };

  useEffect(() => {
    if (carouselData) {
      console.log('carousel data updated ----> ', carouselData);
      // const quickReplies = getUniqueQuickReplies(item);
      // console.log('quickReplies', quickReplies);
      // if (quickReplies) {
      //   setQuickReplyBtn((prev) => [...prev, ...quickReplies]);
      // }

      setCarouselCardAPI(carouselData);
    }
  }, [carouselData]);

  useEffect(() => {
    // console.log("starting useEffect triggerred . . . .");
    setIsLoadingDetails(true);
    if (existingData?.mpm?.index) {
      setActionList(actionList);
    } else {
      setActionList({ index: posMPM, thumbnail_product_retailer_id: thumbnailId, sections: [] });
    }
    // console.log('++++++++++++++++++++++++++++++++++++++++');
    // console.log('+++++ ', item);
    // console.log('++++++++++++++++++++++++++++++++++++++++');
    // checkRegex();
    QuickReplyCheck();
    checkCarousel();
    LoadDynamicFields();
    if (item?.components?.filter((e) => e?.type === 'BUTTONS')?.length > 0) {
      checkURL();
    }
  }, []);

  const QuickReplyCheck = () => {
    const tempArr = [];
    ButtonElement[0]?.buttons?.forEach((e) => {
      if (e?.type === 'QUICK_REPLY') {
        tempArr.push(e?.text);
      }
    });
    let result = [];
    if (CarouselElement) {
      result = getUniqueQuickReplies(item);
    }
    setQuickReplyBtn([...tempArr, ...result]);
  };

  // Loading text input fields dynamically
  const LoadDynamicFields = () => {
    const variableRegex = /\{+\d+\}+/g;
    if (variableRegex.test(BodyElement[0].text)) {
      // console.log('text =======>', BodyElement[0].text);
      const count = BodyElement[0]?.text?.match(variableRegex).length;
      // console.log('count ======>', count);
      setCount(count);
    }
    setIsLoadingDetails(false);
  };

  // Checking url input exist
  const checkURL = () => {
    const variableRegex = /\{\{\d+\}\}/g; // Corrected regex to match {{1}}

    const allButtons = ButtonElement[0]?.buttons;
    // console.log('All Buttons Before Filtering: ', allButtons);

    const url = allButtons?.filter((e) => {
    //   const isURLType = e?.type === 'URL';
    //   const hasExample = Array.isArray(e?.example) && e?.example?.length > 0;
    //   const matchesRegex = e?.url?.match(variableRegex);
    return (
      (e?.type === 'URL' && e?.example && variableRegex.test(e?.url)) ||
      (e?.type === 'COPY_CODE' && e?.example) ||
      e?.type === 'FLOW' ||
      e?.type === 'CATALOG'
    );
      // console.log(`Button: ${JSON.stringify(e)}`);
      // console.log(`URL: ${e?.url}`);
      // console.log(
      //   `isURLType: ${isURLType}, hasExample: ${hasExample}, matchesRegex: ${matchesRegex}`
      // );
    //   return isURLType && hasExample && matchesRegex !== null;
    });

    setCountURL(url?.length);
    // console.log('CHECK URL    ///////////////////////////////////////////');
    // console.log('/////////// ', url, ButtonElement[0]?.buttons);
    // console.log('///////////////////////////////////////////');
    if (url?.length > 0) {
      setURLexists(true);
      for (let i = 0; i < url?.length; i++) {
        setMcta((prev) => {
          const tempmcta = [...prev];
          tempmcta[i] = {
            index: ButtonElement[0]?.buttons.indexOf(url[i]),
            type:
              url[i]?.type === 'URL'
                ? 'url'
                : url[i]?.type === 'CATALOG'
                  ? 'catalog'
                  : url[i]?.type === 'COPY_CODE'
                    ? 'copy_code'
                    : url[i]?.type === 'FLOW'
                      ? 'flow'
                      : '',
            value: '',
          };
          return tempmcta;
        });
        // mcta[i] = {
        //   index: ButtonElement[0]?.buttons.indexOf(url[i]),
        //   type: 'url',
        //   value: '',
        // }
      }
    } else {
      setMcta([]);
    }
  };

  useEffect(() => {
    if (!isShopifyHeader && workFlow) {
      setHeaderOption('uploadShopify');
    }
  }, [inputFieldDropDown]);

  useEffect(() => {
    setActionList({
      index: posMPM,
      thumbnail_product_retailer_id: thumbnailId,
      sections: sectionList,
    });
  }, [thumbnailId, sectionList]);

  useEffect(() => {
    if (ButtonElement?.length > 0) {
      checkMPM();
    }
  }, [ButtonElement]);

  const checkMPM = () => {
    const mpm = ButtonElement[0]?.buttons?.filter((e) => e?.type === 'MPM');
    console.log('mpmm', mpm);
    if (mpm[0]?.text) {
      setMpm(true);
      setPosMPM(ButtonElement[0]?.buttons.indexOf(mpm[0]));
    } else {
      setMpm(false);
      setPosMPM(null);
    }
  };

  useEffect(() => {
    // console.log("mctalength === count ---> ",mcta.length === countURL)
    // console.log("existing data ---> ",existingData)
    if (mcta.length === countURL && existingData?.cta) {
      setExistingURL();
    }
    console.log('mcta changed ===> ', mcta);
    console.log('culprit 2....');
    setAnnv2CTA(mcta);
  }, [mcta]);

  useEffect(() => {
    if (count > 0) {
      let demoObj = [];
      for (let i = 0; i < count; i++) {
        // console.log(' for loop count ======>', i);

        if (i === 0) {
          if (workFlow) {
            demoObj.push({ variable: `%name%` });
          } else {
            demoObj.push({ variable: `{{${i + 1}}}` });
          }
        } else {
          demoObj.push({ variable: `{{${i + 1}}}` });
        }
      }
      setInputField(demoObj);
      if (existingData?.bodyParameters) {
        setExistingFields();
      }
    }
  }, [count]);

  const setExistingFields = () => {
    if (existingData && !EFields) {
      setInputField((prev) => {
        const tempIF = [...prev];

        for (let i = 0; i < prev.length; i++) {
          // console.log('Loop Iteration:', i);
          // console.log('Existing Data:', existingData);
          // console.log('Body Parameters:', existingData.bodyParameters);

          if (existingData.bodyParameters && i < existingData.bodyParameters.length) {
            tempIF[i].variable = existingData.bodyParameters[i];
            // console.log('Updated Value:', tempIF[i].variable);
          } else {
            console.log('Index out of bounds or missing bodyParameters data.');
            // Handle missing data or out of bounds index
          }
        }

        return tempIF;
      });
    }

    setEfields(true);
  };

  const setExistingURL = () => {
    // console.log("problem mcta ---> ",mcta)
    // console.log("problem Ufields ---> ",UFields)
    if (mcta && !UFields) {
      const tmcta = mcta;
      // console.log("problem tmcta ---> ",tmcta)
      for (let i = 0; i < mcta?.length; i++) {
        tmcta[i].value = existingData?.cta[i]?.value;
      }
      // console.log("changed url ---> ",tmcta);
      setMcta(tmcta);
    }
    setUfields(true);
  };

  // useEffect(()=>{
  //   console.log("body par useeffect called.....")
  //   if(bodyPar){
  //     setInputField((prev)=>{
  //       const tempIF = [...prev];
  //       tempIF.map((item,index)=>{
  //         return{
  //           ...item,
  //           variable: bodyPar[index],
  //         }
  //       });
  //       return tempIF;
  //     })
  //   }
  // },[])

  useEffect(() => {
    if (inputFields) {
      // if (inputFields.length === count && existingData?.bodyParameters) {
      //   setExistingFields();
      // }
      modifyBodyElement();
    }
  }, [inputFields]);

  const handleChange = (index, e) => {
    const tempObj = [...inputFields];

    tempObj[index][e.target.name] = e.target.value;
    setInputField(tempObj);
    //console.log('newww', tempObj, e.target.name, index);
  };

  const handleAddProduct = (index) => {
    const newArr = [...sectionList[index].product_items];
    console.log('new', newArr);
    sectionList[index].product_items = [...newArr, { product_retailer_id: '', uniqueId: uuidv4() }];
    setSectionList([...sectionList]);
    console.log('lisrr', sectionList);
  };

  useEffect(() => {}, [sectionList]);

  const handleAddSection = () => {
    const newArr = [...sectionList];
    setSectionList([
      ...newArr,
      {
        title: '',
        product_items: [{}],
      },
    ]);
  };

  const handleRemoveSection = (index) => {
    setSectionList(sectionList.filter((el, i) => i !== index));
  };

  const handleRemoveProduct = (index, i) => {
    const newArr = [...sectionList[index].product_items];
    sectionList[index].product_items = newArr.filter((el, arrIndex) => arrIndex !== i);

    console.log('after delete', sectionList);
    setSectionList([...sectionList]);
  };

  const modifyBodyElement = () => {
    let newtext = BodyElement[0]?.text;
    let newArr = inputFields.map((a) => a.variable);
    if (!mpm) {
      // if (!mpm) {
      setBodyPar(newArr);
    } else {
      setBodyPar(newtext);
    }
    var count = 0;
    console.log('7777777777777777', newArr);
    const variableRegex = /\{+\d+\}+/g;
    if (workFlow) {
      newtext = newtext.replace(variableRegex, function ($0) {
        if (count === newArr?.length) count = 0;
        return newArr[count++];
      });
    } else {
      const newArr2 = newArr.map((item) => {
        if (item.startsWith('%') && item.endsWith('%') && item.length > 2) {
          const TrimWord = item.substring(1, item.length - 1);
          return attributesList[TrimWord] ?? item;
        } else {
          return item;
        }
      });
      newtext = newtext.replace(variableRegex, function ($0) {
        if (count === newArr2?.length) count = 0;
        return newArr2[count++];
      });
    }

    setModifiedBody(newtext);
  };

  useEffect(() => {}, [headerOption]);

  const handleDragFile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDropFile = async (e, type) => {
    console.log('handle drop triggered ----------------- ');
    e.preventDefault();
    e.stopPropagation();
    console.log('file ===> ', e.dataTransfer.files[0]);
    const file = e.dataTransfer.files[0];
    if (file && type) {
      if (UploadValidator(file, type)) {
        setUpFile(file);
        setHeaderFileName(file.name);
        const base64 = await convertToBase64(file);
        setUpFile64(base64);
        setImageUrl('');
      }
    } else {
      toast.error('Unexpected error occurred, please try again later.', {
        position: 'top-center',
      });
    }
  };

  const handleImageUrl = async (image) => {
    const url = image;
    if (url === '') {
      return;
    }
    if (headerFileName) {
      toast.error('Delete uploaded image and try again', {
        position: 'top-center',
      });
      return;
    }
    // const regex =
    //   /^(https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&//=]*\.(?:png|jpg|jpeg|gif|bmp|svg)))$/i;
    // console.log('img', url);
    // if (!regex.test(url)) {
    //   toast.error('Invalid image URL. Please check the URL and try again.', {
    //     position: 'top-center',
    //
    //   });
    //   return;
    // }
    setImageUrl(url);
    setUpFile(null);
    setUpFile64('');

    if (url) {
      try {
        const response = await fetch(url)
          .then((res) => {
            if (res.ok) {
              return res;
            } else {
              console.error('Error:', res.statusText);
            }
          })
          .catch((error) => {
            console.error('Error:', error);
          });
        const blob = await response.blob();
        const file = new File([blob], 'image_from_url.jpg', { type: blob.type });
        const base64 = await convertToBase64(file);
        setUpFile(file);
        setUpFile64(base64);
        setHeaderFileName(file.name);
      } catch (error) {
        toast.error('Not able to fetch image.Please check your url.', {
          position: 'top-center',
        });
      }
    }
  };

  const handleDropUpload = async (file, type) => {
    // const file = file;
    if (file.size < 5242880) {
      setUpFile(file);
      // setUpFileName(file.name);
      // if (headerType === 'document') {
      setHeaderFileName(file.name);
      // }
      const base64 = await convertToBase64(file);
      setUpFile64(base64);
    } else {
      toast.error('File size limit exceeded. Please check the size of the file to be uploaded.', {
        position: 'top-center',
      });
    }
  };

  const handlefileupload = async (e) => {
    const file = e.target.files[0];
    if (file && headerType) {
      if (UploadValidator(file, headerType)) {
        setUpFile(file);
        setHeaderFileName(file.name);
        const base64 = await convertToBase64(file);
        setUpFile64(base64);
      }
    } else {
      toast.error('Unexpected error occurred, please try again later.', {
        position: 'top-center',
      });
    }

    // setUpFile(file);
    // setHeaderFileName(file.name);
    // const base64 = await convertToBase64(file);
    // setUpFile64(base64);
  };

  const handlefileupload2 = async (e, index, type) => {
    console.log('upload index ', index);
    const file = e.target.files[0];
    if (file && type) {
      if (UploadValidator(file, type)) {
        const base64 = await convertToBase64(file);
        getS3LinkC(file, base64, index, type);
      }
    } else {
      toast.error('Unexpected error occurred, please try again later.', {
        position: 'top-center',
      });
    }
  };

  const getS3LinkC = (file, file64, index, type) => {
    let data = {
      image: file64,
      type: file?.type?.split('/')[0],
      format: file?.type?.split('/')[1],
    };
    let token = authtoken;
    V2WorkflowS3Link(data, token).then((res) => {
      if (res) {
        setCarouselData((prev) => {
          let temparr = [...prev];
          temparr[index].header = {
            type: type,
            text: '',
            fileName: file.name,
            link: res?.data?.url,
          };
          return temparr;
        });
      } else {
        console.log('err s3link ---> ', res);
      }
    });
  };
  const setbodyparams = (bodypar, index) => {
    setCarouselData((prev) => {
      let tempArr = [...prev];
      tempArr[index].bodyParameters = [...bodypar];
      return tempArr;
    });
  };

  const setanncta = (anncta, index) => {
    setCarouselData((prev) => {
      let tempArr = [...prev];
      tempArr[index].cta = [...anncta];
      return tempArr;
    });
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  useEffect(() => {
    if (upFile64) {
      // console.log('upfile =====> ', upFile);
      // getV2MediaIDapicall();
      getS3Link();
    }
  }, [upFile64]);

  const getS3Link = () => {
    let data = {
      image: upFile64,
      type: upFile?.type?.split('/')[0],
      format: upFile?.type?.split('/')[1],
    };
    let token = authtoken;
    toast.promise(
      V2WorkflowS3Link(data, token),
      {
        pending: 'Uploading file...',
        success: {
          render({ data }) {
            setS3Link(data?.data?.url);
            console.log('s3 link success response', data);
            return 'File uploaded successfully!';
          },
          icon: '🟢',
          autoClose: true,
          isLoading: false,

        },
        error: {
          render({ data }) {
            console.log('s3 link error response', data);
            return 'Error occurred while uploading file, please try again later.';
          },
          icon: '🔴',
          autoClose: true,
          isLoading: false,

        },
      },
      {
        position: 'top-center',
      }
    );
  };

  // const getV2MediaIDapicall = () => {
  //   const id = toast.loading("Uploading file. Please wait.");
  //   let form = new FormData();
  //   form.append('file', upFile);
  //   let token = authtoken;
  //   V2MediaIDAPI(form, token).then((res) => {
  //     if (res) {
  //       toast.update(id, { render: "File uploaded.", type: "success", isLoading: false , });
  //       setMediaID(res?.data?.id);
  //     } else {
  //       toast.update(id, { render: "File upload failed!", type: "error", isLoading: false  });
  //     }
  //   });
  // };

  useEffect(() => {
    console.log('header type =====> ', HeadElement);
    if (!HeadElement[0] || (HeadElement[0]?.format === 'TEXT' && !HeadElement[0].example)) {
      setHeaderType('NONE');
    } else if (HeadElement[0]?.format === 'TEXT') {
      setHeaderType('text');
    } else {
      setHeaderType(HeadElement[0]?.format);
    }
  }, [HeadElement]);

  const ctaDropDownOptions = [
    {
      item: 'Shop URL',
      value: '$shopify_shopURL',
    },
    {
      item: 'Checkout Link',
      value: '$shopify_checkoutURL',
    },
    {
      item: 'Fullfilled Order Tracking Link',
      value: '$shopify_tracking_url',
    },
  ];

  const dropDownOptions = [
    {
      item: 'Order Id',
      value: '$shopify_orderId',
    },
    {
      item: 'Customer First name',
      value: '$shopify_userFName',
    },
    {
      item: 'Customer Last name',
      value: '$shopify_userLName',
    },
    {
      item: 'Customer Full name',
      value: '$shopify_userName',
    },
    {
      item: 'Customer Email',
      value: '$shopify_email',
    },
    {
      item: 'Customer Phone Number',
      value: '$shopify_customerPhone',
    },
    {
      item: 'Billing Address',
      value: '$shopify_address_billing',
    },
    {
      item: 'Product names (comma separated)',
      value: '$shopify_productName',
    },
    {
      item: 'Total Price',
      value: '$shopify_productPriceWithTax',
    },
    {
      item: 'Vendor Name',
      value: '$shopify_vendorName',
    },
    {
      item: 'Order Tracking Company (Fullfill)',
      value: '$shopify_tracking_company',
    },
    {
      item: 'Order Tracking Number (Fullfill)',
      value: '$shopify_tracking_number',
    },
    {
      item: 'Buyer Email',
      value: '{{buyerEmail}}',
    },
    {
      item: 'Buyer Number',
      value: '{{buyerNumber}}',
    },
    {
      item: 'Voucher Code',
      value: '{{voucherCode}}',
    },
    {
      item: 'Occasion',
      value: '{{occasion}}',
    },
    {
      item: 'Vendors Name',
      value: '{{vendorsName}}',
    },
    {
      item: 'Vendor Email',
      value: '{{vendorEmail}}',
    },
    {
      item: 'Vendor Number',
      value: '{{vendorContactNo}}',
    },
    {
      item: 'Vendor Company Name',
      value: '{{vendorCompanyName}}',
    },
    {
      item: 'Receiver Name',
      value: '{{receiverName}}',
    },
    {
      item: 'Receiver Number',
      value: '{{receiverNumber}}',
    },
    {
      item: 'Receiver Email',
      value: '{{receiverEmail}}',
    },
  ];

  return (
    <>
      {isLoadingDetails ? (
        <>
          <SpinnerComponent />
        </>
      ) : (
        <>
          <div
            className={mobileWidth ? 'd-flex flex-column' : 'd-flex justify-content-between'}
            style={{ width: '100%' }}
          >
            {/* Details */}
            {(inputFields.length > 0 ||
              HeadElement[0] ||
              URLexists ||
              mpm ||
              (carouselCards && carouselCards?.length > 0)) && (
              <div
                style={{
                  height: '60vh',
                  overflowY: 'auto',
                  scrollbarWidth: 'thin',
                  width: mobileWidth ? '100%' : '60%',
                  paddingInline: '10px',
                }}
              >
                {HeadElement && (
                  <>
                    {HeadElement[0]?.format === 'TEXT' && HeadElement[0].example && (
                      <>
                        <InputGroup className="mb-3" key={'headerinputtext'}>
                          <Form.Label
                            style={{ fontSize: 16, fontWeight: 600, color: colors.black }}
                          >{`Header Text Input`}</Form.Label>
                          <p
                            style={{
                              width: '100%',
                              margin: 0,
                              padding: 0,
                              marginBlockEnd: 8,
                              fontSize: 16,
                              fontWeight: 600,
                              color: colors.greys04,
                            }}
                          >
                            {`${HeadElement[0].text}`}
                          </p>
                          <Form.Control
                            type="text"
                            placeholder={
                              HeadElement[0].example.header_text
                                ? `ex : ${HeadElement[0].example.header_text}`
                                : `Enter here`
                            }
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={headerInputText}
                            onChange={(e) => {
                              setHeaderInputText(e.target.value);
                            }}
                          />
                        </InputGroup>
                      </>
                    )}
                    {!mpm && HeadElement[0] && HeadElement[0]?.format !== 'TEXT' && (
                      // {!mpm && HeadElement[0] && HeadElement[0]?.format !== 'TEXT' && (
                      <>
                        {isShopifyHeader && (
                          <>
                            <InputGroup className="mb-3">
                              <Form.Label
                                style={{ fontSize: 16, fontWeight: 600, color: colors.black }}
                              >{`Header`}</Form.Label>
                              <Form.Select
                                type="text"
                                placeholder="Type/choose"
                                className="w-100 btncustom"
                                name="variable"
                                style={{ borderRadius: 8 }}
                                value={headerOption}
                                onChange={(e) => {
                                  setHeaderOption(e.target.value);
                                }}
                              >
                                <option value="uploadShopify">Upload Product Image</option>
                                {nodeType === 'shopify' && (
                                  <option value="fetchShopify">
                                    Fetch product image from Shopify
                                  </option>
                                )}
                                {nodeType === 'wooWFNode' && (
                                  <option value="fetchWoo">
                                    {' '}
                                    Fetch product image from Woocommerce
                                  </option>
                                )}
                              </Form.Select>
                            </InputGroup>
                          </>
                        )}
                        {headerOption === 'fetchShopify' && (
                          <p style={{ marginBottom: 8, color: 'gray' }}>
                            Note: Ensure that you have granted permission for read_products in the
                            Shopify API access scopes. Without this permission, the message cannot
                            be sent.
                          </p>
                        )}
                        {headerOption === 'fetchWoo' && (
                          <p style={{ marginBottom: 8, color: 'gray' }}>
                            Note: Ensure that your products have images in the WooCommerce store
                          </p>
                        )}
                        {headerOption === 'uploadShopify' && (
                          <InputGroup className="mb-2" style={{ width: '100%' }}>
                            <Form.Label
                              style={{ fontSize: 16, fontWeight: 600, color: colors.black }}
                            >
                              {'Upload file'}
                            </Form.Label>
                            <div
                              className="d-flex flex-column justify-content-around align-items-center"
                              style={{
                                width: '100%',
                                border: `1px dotted ${colors.greys03}`,
                                borderRadius: 16,
                                cursor: 'pointer',
                                height: 230,
                                backgroundColor: colors.white02,
                              }}
                              onDragEnter={handleDragFile}
                              onDragExit={handleDragFile}
                              onDragOver={handleDragFile}
                              onDrop={(e) => {
                                handleDropFile(e, HeadElement[0]?.format);
                              }}
                              onClick={() => {
                                // if(!upFile64){
                                if (!s3Link) {
                                  if (imageUrl || headerFileName) {
                                    toast.error('Delete uploaded image and try again', {
                                      position: 'top-center',
                                    });
                                    return;
                                  }
                                  // console.log("Head element format -------- ",HeadElement)
                                  if (HeadElement[0]?.format === 'IMAGE') {
                                    setHeaderType('image');
                                    upImageRef.current.click();
                                  } else if (HeadElement[0]?.format === 'VIDEO') {
                                    setHeaderType('video');
                                    upVideoRef.current.click();
                                  } else if (HeadElement[0]?.format === 'DOCUMENT') {
                                    setHeaderType('document');
                                    upDocRef.current.click();
                                  }
                                }
                              }}
                            >
                              {HeadElement[0]?.format === 'VIDEO' ? (
                                <>
                                  {s3Link ? (
                                    <>
                                      <video
                                        src={getImagePath(s3Link)}
                                        style={{
                                          width: 140,
                                          height: 90,
                                          objectFit: 'contain',
                                          marginTop: 20,
                                        }}
                                      ></video>
                                    </>
                                  ) : (
                                    <>
                                      <img
                                        src={images.ImgUpIcon}
                                        style={{
                                          width: 140,
                                          height: 90,
                                          objectFit: 'contain',
                                          marginTop: 20,
                                        }}
                                      ></img>
                                    </>
                                  )}
                                </>
                              ) : HeadElement[0]?.format === 'DOCUMENT' ? (
                                <>
                                  {upFile ? (
                                    <>
                                      <embed
                                        src={URL.createObjectURL(upFile)}
                                        style={{
                                          width: '60%',
                                          height: '60%',
                                          objectFit: 'cover',
                                          marginTop: 20,
                                        }}
                                      ></embed>
                                    </>
                                  ) : (
                                    <>
                                      <img
                                        src={s3Link ? images.CATREmail : images.ImgUpIcon}
                                        style={{
                                          width: 140,
                                          height: 90,
                                          objectFit: 'contain',
                                          marginTop: 20,
                                        }}
                                      ></img>
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  <img
                                    src={s3Link ? getImagePath(s3Link) : images.ImgUpIcon}
                                    style={{
                                      width: 140,
                                      height: 90,
                                      objectFit: 'contain',
                                      marginTop: 20,
                                    }}
                                  ></img>
                                </>
                              )}
                              {headerFileName ? (
                                <>
                                  <p
                                    style={{
                                      fontSize: 12,
                                      fontWeight: 600,
                                      color: colors.black,
                                      overflow: 'hidden',
                                      whiteSpace: 'nowrap',
                                      textOverflow: 'ellipsis',
                                      wordBreak: 'break-all',
                                    }}
                                  >
                                    {headerFileName?.substring(0, 20) + '...'}
                                    {headerFileName && (
                                      <img
                                        src={images.Cross}
                                        style={{
                                          height: 18,
                                          width: 18,
                                          marginInlineStart: 24,
                                          cursor: 'pointer',
                                        }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setUpFile(null);
                                          setUpFile64(null);
                                          setS3Link('');
                                          setImageUrl('');
                                          setHeaderFileName('');
                                          // setUpFileName('');
                                        }}
                                      />
                                    )}
                                  </p>
                                </>
                              ) : (
                                <>
                                  <UploaderText
                                    type={HeadElement[0]?.format}
                                    textStyle={{
                                      fontSize: 12,
                                      fontWeight: 600,
                                      color: colors.black,
                                      overflow: 'hidden',
                                      whiteSpace: 'nowrap',
                                      textOverflow: 'ellipsis',
                                      wordBreak: 'break-all',
                                      textAlign: 'center',
                                    }}
                                  />
                                </>
                              )}
                            </div>

                            {HeadElement[0]?.format === 'IMAGE' && (
                              <div
                                className="d-flex flex-column justify-content-around align-items-center"
                                style={{
                                  width: '100%',
                                }}
                              >
                                <p
                                  style={{
                                    fontWeight: '700',
                                    margin: '10px',
                                  }}
                                >
                                  OR
                                </p>
                                <div
                                  className="btncustom"
                                  style={{ marginBottom: '5px', width: '100%' }}
                                >
                                  <TextField
                                    type="url"
                                    className={'restylingLabels'}
                                    size="small"
                                    style={{
                                      borderRadius: 4,
                                      width: '100%',
                                    }}
                                    placeholder="Paste Image Url"
                                    value={imageUrl}
                                    onPaste={(event) => {
                                      const pastedURL = event.clipboardData.getData('text');
                                      handleImageUrl(pastedURL);
                                    }}
                                    onChange={(e) => setImageUrl(e.target.value)}
                                    onKeyDown={(e) => {
                                      if (e.key === 'Enter') {
                                        handleImageUrl(imageUrl);
                                      }
                                      if (e.key === 'Backspace') {
                                        e.stopPropagation();
                                        setUpFile(null);
                                        setUpFile64(null);
                                        setS3Link('');
                                        setHeaderFileName('');
                                        setImageUrl(e.target.value);
                                      }
                                    }}
                                  />
                                </div>
                                <p className='w-100 my-2'>
                                  {"If you're facing issues related to image sizes, "}
                                  <span style={{color:colors.linkblue,cursor:'pointer'}} onClick={()=>{
                                    window.open('https://imgupscaler.com/','_blank')
                                  }}>
                                    {'Try resizing'}
                                  </span>
                                </p>
                              </div>
                            )}
                          </InputGroup>
                        )}
                      </>
                    )}
                  </>
                )}
                <input
                  type="file"
                  style={{ display: 'none' }}
                  ref={upDocRef}
                  accept=".doc,.docx,.ppt,.pptx,.xls,.xlsx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,.pdfx,.csv"
                  onChange={(e) => {
                    handlefileupload(e);
                  }}
                />
                <input
                  type="file"
                  style={{ display: 'none' }}
                  ref={upImageRef}
                  accept="image/*"
                  onChange={(e) => {
                    handlefileupload(e);
                  }}
                />
                <input
                  type="file"
                  style={{ display: 'none' }}
                  ref={upVideoRef}
                  accept="video/*"
                  onChange={(e) => {
                    handlefileupload(e);
                  }}
                />

                {inputFields &&
                  inputFields.length > 0 &&
                  inputFields.map((item, index) => {
                    return inputFieldDropDown ? (
                      <InputGroup className="mb-3" key={index}>
                        <Form.Label
                          style={{ fontSize: 16, fontWeight: 600, color: colors.black }}
                        >{`Variable ${index + 1}`}</Form.Label>
                        {/* <Form.Select
                          type="text"
                          placeholder="Type/choose"
                          className="w-100 btncustom"
                          name="variable"
                          style={{ borderRadius: 8 }}
                          value={item.variable}
                          onChange={(e) => {
                            handleChange(index, e);
                          }}
                        >
                          <option
                            style={{ width: '100%', marginBlock: 4, borderRadius: 8 }}
                            key="blankchoice"
                            hidden
                          >
                            {'Type/choose'}
                          </option>
                          {dropDownOptions.map((item) => (
                            <option value={item?.value}>{item.item}</option>
                          ))}
                        </Form.Select> */}
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          style={{
                            marginBottom: '5px',
                          }}
                          className="w-100 btncustom"
                          size="small"
                          renderInput={(params) => (
                            <TextField
                              name="variable"
                              size={'small'}
                              placeholder={'Type/Select'}
                              className={'restylingLabels'}
                              InputProps={{
                                notched: false,
                                style: {
                                  borderRadius: 4,
                                },
                              }}
                              {...params}
                            />
                          )}
                          value={item.variable}
                          onInputChange={(e, newValue) => {
                            const matchedOption = dropDownOptions.find(
                              (option) => option.item === newValue
                            );
                            setInputField((prev) => {
                              const tempObj = [...prev];

                              tempObj[index].variable = matchedOption
                                ? matchedOption.value
                                : newValue;

                              return tempObj;
                            });
                          }}
                          onChange={(e, newValue) => {
                            setInputField((prev) => {
                              const tempObj = [...prev];

                              tempObj[index].variable = newValue ? newValue.value : '';
                              return tempObj;
                            });
                          }}
                          freeSolo={true}
                          options={dropDownOptions}
                          getOptionLabel={(option) => {
                            if (typeof option == 'object') {
                              return `${option.item}`;
                            } else {
                              return `${option}`;
                            }
                          }}
                        />
                      </InputGroup>
                    ) : (
                      <>
                        {index === 0 && (
                          <>
                            {carouselCards && carouselCards?.length > 0 && (
                              <p
                                style={{
                                  width: '100%',
                                  margin: 0,
                                  padding: 0,
                                  marginBlock: 8,
                                  fontSize: 12,
                                  fontWeight: 400,
                                  color: colors.greys04,
                                }}
                              >
                                <strong>
                                  Note:- Please ensure that the names of the buttons on your cards
                                  are unique. If multiple buttons have the same name, your flow will
                                  only work for one of the buttons.
                                </strong>
                              </p>
                            )}
                            <p
                              style={{
                                width: '100%',
                                margin: 0,
                                padding: 0,
                                marginBlock: 8,
                                fontSize: 16,
                                fontWeight: 400,
                                color: colors.greys04,
                              }}
                            >
                              {`%name% is the value used for dynamically adding the customer name to the variable. In case your first variable isn't name, then replace %name% with the word you would like to send for variable 1 `}
                            </p>
                          </>
                        )}
                        <Form.Label
                          style={{ fontSize: 16, fontWeight: 600, color: colors.black }}
                        >{`Variable ${index + 1}`}</Form.Label>
                        {/* <Form.Control
                          type="text"
                          placeholder="Enter here"
                          className="w-100 btncustom"
                          name="variable"
                          style={{ borderRadius: 8 }}
                          value={item.variable}
                          onChange={(e) => {
                            handleChange(index, e);
                          }}
                        /> */}
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          style={{
                            marginBottom: '5px',
                          }}
                          size="small"
                          renderInput={(params) => (
                            <TextField
                              name="variable"
                              // value={item.variable}
                              // onChange={(e) => {
                              //   console.log('trigger', e.target.value);
                              //   handleChange(index, e);
                              // }}
                              size={'small'}
                              placeholder={'Type/Select'}
                              className={'restylingLabels'}
                              InputProps={{
                                notched: false,
                                style: {
                                  borderRadius: 4,
                                },
                              }}
                              {...params}
                            />
                          )}
                          value={item.variable}
                          onInputChange={(e, newValue) => {
                            setInputField((prev) => {
                              const tempObj = [...prev];

                              tempObj[index].variable = newValue;

                              return tempObj;
                            });

                            //setInputField(tempObj);
                          }}
                          onChange={(e, newValue) => {
                            if (workFlow) {
                              setInputField((prev) => {
                                const tempObj = [...prev];

                                tempObj[index].variable = newValue ? `%${newValue}%` : '';
                                return tempObj;
                              });
                            } else {
                              // setInputField((prev) => {
                              //   const tempObj = [...prev];
                              //   const selectedValue = newValue
                              //     ? typeof newValue === 'object'
                              //       ? newValue.key
                              //       : newValue
                              //     : '';
                              //   tempObj[index].variable = selectedValue;
                              //   tempObj[index].varValue = attributesList[selectedValue];
                              //   console.log('npppp', item.varValue);
                              //   return tempObj;
                              // });
                              setInputField((prev) => {
                                const tempObj = [...prev];

                                tempObj[index].variable = newValue ? `%${newValue}%` : '';
                                return tempObj;
                              });
                            }
                          }}
                          freeSolo={true}
                          options={
                            workFlow
                              ? Object.values(attributesList)
                              : Object.keys(attributesList)
                                  .map((key) => key)
                                  .filter((option) => attributesList[option] !== undefined)
                          }
                          getOptionLabel={(option) => {
                            if (workFlow) {
                              return `${option}`;
                            } else {
                              if (typeof option == 'object') {
                                return `${option.key}`;
                              } else {
                                return `${option}`;
                              }
                            }
                          }}
                        />
                      </>
                    );
                  })}
                {URLexists &&
                  ButtonElement[0]?.buttons
                    ?.filter((e) => {
                      return e?.type === 'URL' && e?.example;
                    })
                    .map((item, index) => {
                      return inputFieldDropDown ? (
                        <InputGroup className="mb-3" key={index}>
                          <Form.Label
                            style={{ fontSize: 16, fontWeight: 600, color: colors.black }}
                          >{`Call to Action URL ${index + 1}`}</Form.Label>
                          {/* <Form.Select
                            type="text"
                            placeholder="Type/choose"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={mcta[index].value}
                            onChange={(e) => {
                              setMcta((prev) => {
                                const newmcta = [...prev];
                                newmcta[index].value = e.target.value;
                                return newmcta;
                              });
                            }}
                          >
                            <option
                              style={{ width: '100%', marginBlock: 4, borderRadius: 8 }}
                              key="blankchoice"
                              hidden
                              value
                            >
                              {'Type/choose'}
                            </option>
                            {ctaDropDownOptions.map((item) => (
                              <option value={item?.value}>{item.item}</option>
                            ))}
                          </Form.Select> */}
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            style={{
                              marginBottom: '5px',
                            }}
                            className="w-100 btncustom"
                            size="small"
                            renderInput={(params) => (
                              <TextField
                                name="variable"
                                size={'small'}
                                placeholder={'Type/Select'}
                                className={'restylingLabels'}
                                InputProps={{
                                  notched: false,
                                  style: {
                                    borderRadius: 4,
                                  },
                                }}
                                {...params}
                              />
                            )}
                            value={mcta[index].value}
                            onInputChange={(e, newValue) => {
                              const matchedOption = dropDownOptions.find(
                                (option) => option.item === newValue
                              );

                              setMcta((prev) => {
                                const newmcta = [...prev];
                                newmcta[index].value = matchedOption
                                  ? matchedOption.value
                                  : newValue;
                                return newmcta;
                              });
                            }}
                            onChange={(e, newValue) => {
                              setMcta((prev) => {
                                const newmcta = [...prev];
                                newmcta[index].value = newValue ? newValue.value : '';
                                return newmcta;
                              });
                            }}
                            freeSolo={true}
                            options={ctaDropDownOptions}
                            getOptionLabel={(option) => {
                              if (typeof option == 'object') {
                                return `${option.item}`;
                              } else {
                                return `${option}`;
                              }
                            }}
                          />
                        </InputGroup>
                      ) : (
                        <>
                          {/* <InputGroup className="mb-3" key={`key_${index}`}> */}
                          <Form.Label
                            style={{ fontSize: 16, fontWeight: 600, color: colors.black }}
                          >{`Call to Action URL ${index + 1}`}</Form.Label>
                          <p>
                            Copy pasting the URL may not work. Please enter the URL you want to use.
                          </p>
                          {/* <Form.Control
                              type="text"
                              placeholder="Enter link here"
                              className="w-100 btncustom"
                              style={{ borderRadius: 8 }}
                              value={mcta[index].value}
                              // onInput={(e)=>{
                              //   let tempmcta = mcta;
                              //   tempmcta[index].value = e.target.value;
                              //   setMcta(tempmcta);
                              // }}
                              onChange={(e) => {
                                setMcta((prev) => {
                                  const newmcta = [...prev];
                                  newmcta[index].value = e.target.value;
                                  return newmcta;
                                });
                              }}
                            /> */}
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            style={{
                              marginBottom: '5px',
                            }}
                            size="small"
                            renderInput={(params) => (
                              <TextField
                                name="variable"
                                // value={item.variable}
                                // onChange={(e) => {
                                //   console.log('trigger', e.target.value);
                                //   handleChange(index, e);
                                // }}
                                size={'small'}
                                placeholder={'Enter link here'}
                                className={'restylingLabels'}
                                InputProps={{
                                  notched: false,
                                  style: {
                                    borderRadius: 4,
                                  },
                                }}
                                {...params}
                              />
                            )}
                            value={mcta[index].value}
                            onInputChange={(e, newValue) => {
                              setMcta((prev) => {
                                const newmcta = [...prev];
                                newmcta[index].value = newValue;
                                return newmcta;
                              });
                            }}
                            onChange={(e, newValue) => {
                              setMcta((prev) => {
                                const newmcta = [...prev];
                                newmcta[index].value = newValue ? `${newValue}` : '';
                                return newmcta;
                              });
                            }}
                            freeSolo={true}
                            options={
                              workFlow
                                ? Object.values(attributesList).map((val) => `%${val}%`)
                                : Object.keys(attributesList)
                                    .map((key) => key)
                                    .filter((option) => attributesList[option] !== undefined)
                            }
                          />
                          {/* </InputGroup> */}
                        </>
                      );
                    })}
                {/* {console.log('mpmabc', mpm)} */}
                {mpm && (
                  // {mpm && (
                  <>
                    <InputGroup className="mb-3">
                      <Form.Label
                        style={{
                          fontSize: 16,
                          fontWeight: 600,
                          color: colors.black,
                          marginTop: '3%',
                        }}
                      >{`Thumbnail Product Id`}</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter here"
                        className="w-100 btncustom"
                        style={{ borderRadius: 8 }}
                        value={thumbnailId}
                        // onChange={(e)=>{setThumbnailId(e.target.value);setHeaderType('text')}}
                        onChange={(e) => {
                          setThumbnailId(e.target.value);
                        }}
                      />
                    </InputGroup>
                    <p
                      style={{
                        alignSelf: 'flex-start',
                        fontSize: '16px',
                        fontWeight: '600',
                        marginTop: '2%',
                      }}
                    >
                      Sections
                    </p>
                    <hr style={{ width: '100%', marginTop: '-5px' }}></hr>

                    {sectionList.map((section, index) => (
                      <div
                        style={{ alignSelf: 'flex-start', width: '100%', marginTop: '2%' }}
                        key={new Date().valueOf}
                      >
                        <div className="d-flex justify-content-between" style={{}}>
                          <div className="d-flex">
                            <InputGroup className="mb-3" key={new Date().valueOf}>
                              <Form.Control
                                type="text"
                                placeholder="Enter Section Name"
                                style={{ borderRadius: 8, boxShadow: 'none' }}
                                value={section.title}
                                onChange={(e) => {
                                  //handleSectionChange(index,e)
                                  setSectionList((prev) => {
                                    const newArr = [...prev];
                                    newArr[index].title = e.target.value;
                                    return newArr;
                                  });
                                  //section.title = e.target.value;
                                  console.log('sec', section);
                                }}
                              />
                            </InputGroup>

                            {/* <img  style={{marginLeft:"8px",height:"50%",paddingTop:"2px"}} src={images.EditSender}></img> */}
                          </div>

                          {sectionList.length > 1 && (
                            <img
                              style={{
                                cursor: 'pointer',
                                marginLeft: '8px',
                                height: '25px',
                                paddingTop: '2px',
                                alignItems: 'flex-end',
                              }}
                              onClick={() => handleRemoveSection(index)}
                              src={images.DeleteBlack}
                            ></img>
                          )}
                        </div>

                        {sectionList[index].product_items.map((product, i) => (
                          <div style={{ marginTop: '-18px' }}>
                            <div class="form-group mt-4" key={product.uniqueId}>
                              <label
                                for="exampleFormControlInput1"
                                style={{ fontWeight: '600', fontSize: '14px', color: 'black' }}
                              >
                                Product Id
                              </label>
                              {sectionList[index].product_items.length > 1 && (
                                <img
                                  style={{
                                    cursor: 'pointer',
                                    marginLeft: '8px',
                                    height: '22px',
                                    marginBottom: '5px',
                                    alignItems: 'flex-end',
                                  }}
                                  onClick={() => handleRemoveProduct(index, i)}
                                  src={images.DeleteBlack}
                                ></img>
                              )}
                              <input
                                name="product_retailer_id"
                                value={product.product_retailer_id}
                                onChange={(e) => {
                                  {
                                    console.log('damn', i);
                                    //handleProductChange(index,e);
                                    setSectionList((prev) => {
                                      const newArr = [...prev];
                                      newArr[index].product_items[i].product_retailer_id =
                                        e.target.value;
                                      return newArr;
                                    });
                                    //product[e.target.name]=e.target.value
                                  }
                                }}
                                class="form-control shadow-none"
                                placeholder="Enter here"
                                style={{ width: '502px' }}
                              />
                            </div>
                            <></>
                          </div>
                        ))}

                        {sectionList[index].product_items.length <= 30 && (
                          <div className="d-flex flex-row-reverse">
                            <CheerioButton
                              className="button"
                              borderStyle={{
                                height: '36px',
                                width: '120px',
                                marginBlockStart: 12,
                                marginBlockEnd: 8,
                                border: 'none',
                              }}
                              textStyle={{ color: 'white', fontSize: 12, fontWeight: 600 }}
                              btnText={'Add Product'}
                              backColor={'black'}
                              onclick={() => handleAddProduct(index)}
                            />
                          </div>
                        )}
                        <hr></hr>
                      </div>
                    ))}
                    {sectionList.length <= 10 && (
                      <div style={{ width: '100%' }} className="d-flex flex-row-reverse">
                        <CheerioButton
                          className="button"
                          borderStyle={{
                            height: '36px',
                            width: '30%',
                            marginBlockStart: 12,
                            marginBlockEnd: 8,
                          }}
                          textStyle={{ color: 'black', fontSize: 12, fontWeight: 600 }}
                          btnText={'Create a section'}
                          backColor={'white'}
                          onclick={handleAddSection}
                        />
                      </div>
                    )}
                  </>
                )}

                {carouselCards &&
                  carouselCards.map((item, index) => {
                    const cHeader = item?.components?.filter((e) => e?.type === 'HEADER');
                    const cBody = item?.components?.filter((e) => e?.type === 'BODY');
                    const cButton = item?.components?.filter((e) => e?.type === 'BUTTON');

                    return (
                      <CarouselCardsComponent
                        data={item}
                        index={index}
                        carouselData={carouselData}
                        handlefileupload={handlefileupload2}
                        setbodyparams={setbodyparams}
                        setanncta={setanncta}
                        existingData={existingData}
                        attributesList={attributesList}
                        workFlow={true}
                      />
                    );
                  })}
              </div>
            )}
            {/* Preview */}
            <div
              className="d-flex flex-column justify-content-center align-items-center"
              style={{
                height: '60vh',
                overflowY: 'auto',
                marginInline: mobileWidth ? '0px' : 10,
                width: mobileWidth
                  ? '100%'
                  : inputFields.length > 0 ||
                    mcta.length > 0 ||
                    HeadElement[0] ||
                    URLexists ||
                    mpm ||
                    (carouselCards && carouselCards.length > 0)
                  ? '50%'
                  : '98%',
                border: '1px solid #CCCCCC',
                borderRadius: '16px',
                padding: '2%',
                scrollbarWidth: 'thin',
                marginBlockEnd: '10px',
              }}
            >
              <WAV2Preview
                // upimage={}
                BodyElement={modifiedBody ? modifiedBody : BodyElement[0]?.text}
                uptype={HeadElement[0]?.format}
                upimage={s3Link ? s3Link : upFile64}
                ButtonList={ButtonList}
                HeaderInputText={headerInputText}
                // BodyElement={BodyElement[0].text}
                CarouselData={carouselCards}
                CarouselInput={carouselData}
                HeadElement={HeadElement}
                upFile={upFile}
                HeaderOption={headerOption}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AnnDetailsWF;
