import React, { useRef, useState, useEffect } from 'react';
import { CardImg, Form, InputGroup } from 'react-bootstrap';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import CheerioButton from '../../../Components/CheerioButton';
// import EventEmitter from '../../../Utils/EventEmitter';
import Helper from '../../../Utils/Helper';
// import SocketConnect from '../../../Components/Socket/SocketConnection';
import { getImagePath } from '../../../Utils';
import SpinnerLoader from '../../../Components/SpinnerLoader';
import BackgroundImage from '../../../Images/creatorNew/Background.png';
import {
  V2WorkflowS3Link,
  getAllRespones,
  getUserApi,
  listInstagramPages,
  saveInstagramPageDetails,
  saveInstagramToken,
  sendWhatsAppTemplate,
  toggleAgent,
  toggleInstaAgent,
  toggleStatus,
} from '../../../Services';
import { useDispatch, useSelector } from 'react-redux';
import SelectPageModal from './SelectPageModal';
import { MatchResponses, SavedResponses } from './savedResponses';
import { MenuItem, Select, Tooltip } from '@mui/material';
import '../styles.css';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import HEImages from '../../CreatorNew/Components/HEImages';
import he from 'he';
import { BsFileEarmark, BsCheck2All } from 'react-icons/bs';
import { BsDownload } from 'react-icons/bs';
import { IoCheckmark, IoLockClosedOutline } from 'react-icons/io5';

import { CiFaceSmile } from 'react-icons/ci';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import InputEmoji from 'react-input-emoji';
let chatArr = [];
let temp = {};
export default function InstagramChatView({
  chatData,
  isChatLoad,
  agentData,
  setInboxList,
  getInboxListing,
  mobileChangeScreen,
  loadingChatScreen,
  socket,
  messages,
  setChatStatus,
  setAgent,
}) {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };

  const messagesEndRef = useRef();
  const imageinputref = useRef();
  const documentRef = useRef();
  const [text, setText] = useState('');
  const [listPageModalOpen, setListPageModalOpen] = useState(false);
  const [selectedPageId, setSelectedPageId] = useState("");
  const [pagesList, setPagesList] = useState([]);
  const [mediaContent, setMediaContent] = useState();
  const [imgBase64, setImgBase64] = useState('');
  const [mediaType, setMediaType] = useState('');
  const authtoken = useSelector((state) => state.main.auth_token);
  const [showSavedResponse, setShowSavedResponses] = useState(false);
  const [values, setValues] = useState([]);
  const [e, setE] = useState(null);
  const [resolvedValue, setResolvedValue] = useState('Select status');
  const [agentName, setAgentName] = useState('');

  const [showEmoji, setShowEmoji] = useState(false);

  const [upFile, setUpFile] = useState();
  const [upFile64, setUpFile64] = useState('');
  const [headerFileName, setHeaderFileName] = useState('');
  const [s3Link, setS3Link] = useState('');
  const [showSideBar, setShowSideBar] = useState(false);
  // const [mediaType, setMediaType] = useState('');
  const textareaRef = useRef(null);
  // const [selected,setSelected] = useState();
  const [comments, setComments] = useState(chatData?.comments);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.main.auth_token);
  const localdata = useSelector((state) => state.main.localdata);
  const MobileWidth = window.innerWidth < 900 ? true : false;
  const navigate = useNavigate();
  // const visible = localdata?._id === "636be67d42c9a5cb33f60d98" || localdata?._id === "6489675b4f598e055ed74e34";

  temp = chatData;
  const [showElement, setShowElement] = useState(true);
  useEffect(() => {
    setTimeout(function () {
      setShowElement(false);
    }, 3000);
  }, []);

  useEffect(() => {
    console.log("List page modal open => ", listPageModalOpen);
  }, [listPageModalOpen])

  const getAllResponsesApiCall = () => {
    getAllRespones(token).then((res) => {
      messagesEndRef.current?.scrollIntoView({ behavior: 'instant' });
      if (res?.flag) {
        setValues(res?.data);
        //setSelected(res?.data[0]);
      } else {
        toast.error(res);
      }
    });
  };
  useEffect(() => {
    //console.log('ch', chatData?.agent[0]?.name);
    if (chatData) {
      if (chatData?.status) {
        setResolvedValue(chatData?.status);
      } else {
        setResolvedValue('Select status');
      }
      if (chatData?.agent) {
        setAgentName(chatData?.agent?.name);
      }
    }
  }, [chatData]);

  const handleStatusChange = (value) => {
    const data = {
      convId: chatData?._id,
      status: value,
    };
    toggleStatus(authtoken, data)
      .then((res) => {
        if (res?.flag) {
          setResolvedValue(value);
          setChatStatus(value);
          // setInboxList([]);
          // getInboxListing(1);
        } else {
          toast.error(res?.message, { position: 'top-center' });
        }
      })
      .catch((err) => {
        toast.error(err?.message, { position: 'top-center' });
      });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleAssignedAgentChange = (e) => {
    const value = Object.values(agentData).filter((item) => item?.name === e.target.value)[0];
    console.log('value', value);
    if (!value) {
      toast.error('No agent found', { position: 'top-center' });
    }
    const data = {
      agentId: value._id,
      conversationId: chatData?._id,
    };
    toggleInstaAgent(authtoken, data)
      .then((res) => {
        if (res?.flag) {
          setAgentName(e.target.value);
          setAgent({
            id: value._id,
            name: value.name,
          });
        } else {
          toast.error(res?.message, { position: 'top-center' });
        }
      })
      .catch((err) => {
        toast.error(err?.message, { position: 'top-center' });
      });
  };

  useEffect(() => {
    getAllResponsesApiCall();
    // if(text.length === 0){
    //   setShowMatchedResponse(false);
    // }
    // else if(text === "/" && text.length === 1){
    //   setShowMatchedResponse(true);
    // }else{

    //   const newArr= values.filter((value)=>{
    //     if(value?.response.match(text)){
    //       return value;
    //     }
    //   })
    //   console.log("newArr",newArr);
    //   if(newArr.length>0){
    //     setValues(newArr);
    //     console.log("modify",values);
    //     setShowMatchedResponse(true);
    //   }else{
    //     setShowMatchedResponse(false);
    //   }
    // }
  }, [showSavedResponse]);

  useEffect(() => {
    if (mediaContent) {
      // console.log("media uploaded file....",mediaContent);

      Helper.base64(mediaContent, function (data) {
        // console.log(data.base64)
        setImgBase64('data:image/png;base64,' + data.base64);
      });
      // const reader = new FileReader();
      // reader.onloadend = () => {
      //     setPreview(reader.result);
      //     console.log(reader);
      // }
      // reader.readAsDataURL(mediaContent);
      // setLoading2(true);
      // s3UpVideoApiCall();
    } else {
      // setPreview(null);
    }
  }, [mediaContent]);


  useEffect(() => {
    if (pagesList.length > 0 && !localdata.metaConfig?.instagramPageToken) {
      setListPageModalOpen(true);
    } else if (!localdata.metaConfig?.instagramPageToken) {
      setListPageModalOpen(false);
    }
  }, [pagesList])

  const getPagesListApiCall = () => {
    let token = authtoken;
    console.log("getting list of all pages api");
    listInstagramPages(token).then((res) => {
      try {
        if (res?.flag) {
          setPagesList(res?.data);
        } else {
          toast.error(res?.message);
        }
      } catch (err) {
        toast.error(err?.message);
      }
    });

  };

  useEffect(() => {
    if (selectedPageId) {
      saveInstagramDetailsApiCall();
    }
  }, [selectedPageId])

  const saveInstagramDetailsApiCall = () => {
    let token = authtoken;
    const data = {
      pageId: selectedPageId,
    }
    console.log("saving instagram page details");
    saveInstagramPageDetails(token, data).then((res) => {
      try {
        if (res?.flag) {
          getuserapicall();
          setListPageModalOpen(false);
        } else {
          toast.error(res?.message);
        }
      } catch (err) {
        toast.error(err?.message);
      }
    });
  }


  const getuserapicall = () => {
    let token = authtoken;
    getUserApi(token).then((res) => {
      if (res?.flag) {
        dispatch({ type: 'addLocaldata', payload: res?.data });
      } else {
        console.log('error get user api ====> ', res);
      }
    });
  };

  const sendNewMessageAction = () => {
    const msg = {
      userId: chatData?.userId,
      receiverId: chatData?.clientId,
    };

    if(text){
      msg.body = text;
      socket.emit('instaChatMessage', msg);
      setText('');
    }else if(s3Link){
      msg.mediaUrl = s3Link;
      msg.mediaType = mediaType;
      socket.emit('instaMediaMessage', msg);
      setUpFile(null);
      setUpFile64('');
      setS3Link('');
      setHeaderFileName('');
    }
    console.log('data sent to msg socket: ', msg);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handlefileupload = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    if (
      file.type === 'image/jpg' ||
      'image/jpeg' ||
      'image/gif' ||
      'image/png' ||
      'video/mp4' ||
      'video/ogg' ||
      'video/avi' ||
      'video/webm' ||
      'video/mov' ||
      'audio/acc' ||
      'audio/mp4' ||
      'audio/wav' ||
      'audio/m4a'
    ) {
      setUpFile(file);
      setHeaderFileName(file.name);
      const base64 = await convertToBase64(file);
      setUpFile64(base64);
    } else {
      toast.error('wrong file format uploaded, please try again later', {
        position: 'top-center',

      });
    }
  };

  useEffect(() => {
    if (upFile?.type?.split('/')[0] === 'image') {
      setMediaType('image');
    } else if (upFile?.type?.split('/')[0] === 'video') {
      setMediaType('video');
    } else if (upFile?.type?.split('/')[0]) {
      setMediaType('audio');
    } else {
      setMediaType('');
    }
  }, [upFile]);

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  useEffect(() => {
    if (upFile64) {
      getS3Link();
    }
  }, [upFile64]);

  const getS3Link = () => {
    const id = toast.loading('Uploading file. Please wait.');
    let data = {
      image: upFile64,
      type: upFile?.type?.split('/')[0],
      format: upFile?.type?.split('/')[1],
    };
    let token = authtoken;
    console.log('file format ===> ', upFile?.type?.split('/')[0]);
    console.log('file format ===> ', upFile?.type?.split('/')[1]);
    V2WorkflowS3Link(data, token).then((res) => {
      if (res) {
        setS3Link(res?.data?.url);
        toast.update(id, {
          render: 'file uploaded successfully',
          type: 'success',
          autoClose: true,
          isLoading: false,


        });
        // toast.success('file uploaded successfully',{position:'top-center'});
      } else {
        toast.update(id, {
          render: 'file uploaded failed, please try again later',
          type: 'error',
          autoClose: true,
          isLoading: false,


        });
      }
    });
  };

  const handleEmojiSelect = (emoji) => {
    const currentText = text;
    const cursorPosition = textareaRef.current.selectionStart;
    const newText =
      currentText.substring(0, cursorPosition) +
      emoji.native +
      currentText.substring(cursorPosition);

    setText(newText);


    const newCursorPosition = cursorPosition + emoji.native.length;
    setTimeout(() => {
      textareaRef.current.focus();
      textareaRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
    }, 0);
  };

  console.log('chatList', isChatLoad);
  if (!chatData && isChatLoad) {
    return (
      <div className="d-flex flex-column align-items-center" style={{ height: '100%' }}>
        <div className="d-flex flex-column align-items-center" style={{ marginTop: '8%' }}>
          <img src={images.WChats} height={280}></img>
          <p style={{ marginTop: '10px', fontWeight: '700', fontSize: '16px' }}>
            Nothing to see yet!
          </p>
          <div style={{ marginTop: '5px', fontWeight: '400', fontSize: '16px', color: '#666666' }}>
            <p>Send & receive messages from your customers directly from the dashboard.</p>
          </div>
        </div>
        <div
          className="mt-auto p-2 bd-highlight"
          style={{ fontWeight: '400', fontSize: '14px', color: '#666666' }}
        >
          <IoLockClosedOutline
            style={{ marginRight: '5px', height: '16px', width: '20px', marginTop: '-5px' }}
          />
          End-to-end-encrypted
        </div>
      </div>
    );
  }
  return (
    <>

      {listPageModalOpen ? (
        <SelectPageModal setListPageModalOpen={setListPageModalOpen} setSelectedPageId={setSelectedPageId} pagesList={pagesList} />
      ) : !isChatLoad ? (
        <div className="d-flex" style={{ width: '100%', height: '100%' }}>
          <div style={{ padding: '20px', border: '' }}>
            {localdata?.premium_details?.plan_type !== '12 Months' && <div style={{ display: "flex", alignItems: "center", gap: 7, backgroundColor: colors.primary01, padding: 8, borderRadius: 10, marginBottom: 15 }}>
              <img src={images.InfoCircle} alt="" />
              <p>This is a limited time free trial of Instagram inbox. If you're not on our annual plan you can get it as an add on. Please get in touch with us at <span style={{ color: colors.linkblue }}>admin@cheerio.in</span> to get permanent access to this feature.</p>
            </div>}
            <p style={{ fontSize: '20px', fontWeight: '700' }}>What you need?</p>
            <p style={{ marginTop: '10px', fontWeight: '700', fontSize: '16px', color: '#666666' }}>
              Pre-Installation
            </p>
            <div style={{ marginTop: '10px', color: '#666666' }}>
              <p>Before you can install Instagram Messaging, you need to have:</p>
              <div style={{ paddingLeft: '10px' }}>
                <p>
                  1. An Instagram Business account.<br></br>
                  2. A linked Facebook page with admin access.<br></br>
                  3. Messaging permissions enabled in instagram.
                </p>
              </div>
              <p
                style={{ marginTop: '15px', fontWeight: '700', fontSize: '16px', color: '#666666' }}
              >
                Installation
              </p>
              <p>
                Log in below and select your account in the modal. Please ensure that your Instagram
                business account is associated to your Facebook Business page.
              </p>

              <div className="d-flex" style={{ marginTop: '20px' }}>
                <p style={{ fontWeight: '700', fontSize: '16px', color: 'black' }}>Need help?</p>
                <p
                  onClick={() => {
                    window.open(
                      'https://developers.facebook.com/docs/messenger-platform/instagram/features/webhook',
                      '_blank'
                    );
                  }}
                  style={{
                    marginLeft: '5px',
                    fontWeight: '400',
                    fontSize: '16px',
                    color: '#3366CC',
                    cursor: 'pointer',
                  }}
                >
                  Read documentation
                </p>
              </div>
              <div className="d-flex" style={{ marginTop: '20px' }}>
                <p style={{ fontWeight: '700', fontSize: '16px', color: 'black' }}>Need help?</p>
                <p
                  onClick={() => {
                    window.open(
                      'https://drive.google.com/file/d/143AbuuyYos-DWccqUYTTrP99S0D4VN5Y/view',
                      '_blank'
                    );
                  }}
                  style={{
                    marginLeft: '5px',
                    fontWeight: '400',
                    fontSize: '16px',
                    color: '#3366CC',
                    cursor: 'pointer',
                  }}
                >
                  How to enable Instagram messaging permissions
                </p>
              </div>
              <img
                src={images.InstaContinueButton}
                style={{
                  height: '20%',
                  borderRadius: '10px',
                  width: '23%',
                  marginTop: '2%',
                  border: 'none',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  if (localdata?.metaConfig?.instagramPageName && localdata?.metaConfig?.instagramPageName !== "") {
                    getPagesListApiCall();
                  } else {
                    window.FB.login(
                      function (response) {
                        console.log({
                          response,
                        });
                        console.log("WAITING FOR INSTAGRAM TOKEN");
                        if (response.authResponse) {
                          let token = authtoken;
                          console.log("RECEIVED INSTAGRAM TOKEN", response.authResponse);
                          const fbToken = response.authResponse.accessToken;
                          saveInstagramToken(token, { token: fbToken })
                            .then((res) => {
                              console.log("SAVED INSTAGRAM TOKEN");
                              if (res?.flag) {
                                getPagesListApiCall();
                              } else {
                                toast.error(res?.message, { position: 'top-center' });
                              }
                            })
                            .catch((e) => {
                              console.log(e.message);
                            });
                        } else {
                          console.log('User cancelled login or did not fully authorize.');
                        }
                      },
                      {
                        scope:
                          'pages_show_list,business_management,pages_messaging,instagram_basic,instagram_manage_messages,pages_manage_metadata,pages_read_engagement,instagram_manage_comments,instagram_manage_insights,catalog_management,instagram_content_publish',
                      }
                    );
                  }
                }}
              />
            </div>
          </div>
        </div>
      ) : loadingChatScreen ? (
        <div className="spinner_loader" style={{ marginTop: '30%' }}>
          <SpinnerLoader />
        </div>
      ) : messages.length > 0 ? (
        <>
          <div
            style={{
              display: 'flex',
              flex: 1,
              height: '100%',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <div
              className="d-flex justify-content-between"
              style={{
                alignItems: 'center',
                backgroundColor: colors.white,
                height: 60,
                borderBottom: '1px solid #E6E6E6',
              }}
            >
              {MobileWidth && (
                <img
                  src={images.BackArrowBlack}
                  style={{
                    height: 24,
                    width: 24,
                    objectFit: 'contain',
                    marginInlineStart: 10,
                  }}
                  onClick={() => {
                    mobileChangeScreen('inbox');
                  }}
                ></img>
              )}
              <div
                style={{
                  margin: 0,
                  fontWeight: 700,
                  color: colors.black,
                  fontSize: 16,
                  marginLeft: 12,
                  cursor: 'pointer',
                }}
              >
                {chatData?.clientName}
              </div>
              <div style={{ padding: '0px', marginLeft: '10px', marginTop: '4px' }}>
                <Select
                  value={agentName}
                  onChange={(e) => {
                    handleAssignedAgentChange(e);
                  }}
                  sx={{
                    height: '25px',
                    color: 'black',
                    width: '8vw',
                    borderRadius: '47px',
                    fontSize: '12px',
                    backgroundColor: 'white',
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: '1px solid #000000',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      border: '1px solid #000000',
                    },
                    '& .MuiSvgIcon-root': {
                      color: 'black',
                    },
                  }}
                >
                  <MenuItem hidden value={''}>
                    {'Select an agent'}
                  </MenuItem>

                  {agentData &&
                    Object.values(agentData).map((option) => (
                      <MenuItem value={option?.name}>{option?.name}</MenuItem>
                    ))}
                </Select>
              </div>
            </div>
            <div
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                overflowX: 'hidden',
                overflowY: 'scroll',
                scrollbarWidth: 'thin',
                backgroundImage: `url(${BackgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundColor: '#F5F5F5',
              }}
              className="verythinscrollnew"
            >
              {messages?.length > 0 &&
                messages?.map((_msg) => {
                  const isSend = _msg?.senderId == chatData?.ownerInstagramId;
                  return (
                    <div>
                      <div
                        key={_msg?._id}
                        className={`d-flex flex-row  ${isSend ? `justify-content-end` : 'justify-content-start'
                          }  px-2 py-2 mx-auto`}
                        style={{ width: '95%' }}
                      >
                        <div
                          style={{
                            maxWidth: '70%',
                            backgroundColor: (_msg?.messageType === "carousel") ? null : isSend ? '#5068F2' : colors?.white,
                            borderRadius: 8,
                            padding: 10,
                            alignItems: 'center',
                          }}
                        >
                          {_msg?.storyId !== '' && _msg?.storyURL ? (
                            <>
                              <video
                                src={getImagePath(_msg?.storyURL)}
                                controls={true}
                                style={{ maxHeight: 200, objectFit: 'cover', marginBottom: 8 }}
                              ></video>
                            </>
                          ) : (_msg?.messageType === 'audio') &&
                          _msg?.attachmentURL ? (
                          <>
                            <audio
                              src={getImagePath(_msg.attachmentURL)}
                              controls={true}
                              style={{
                                borderRadius: '5px',
                                border: '1px solid rgb(241, 243, 244)',
                                background: 'rgb(241, 243, 244)',
                              }}
                            ></audio>
                          </>
                          ) : (_msg?.attachmentType === 'story_mention' || _msg?.messageType === 'story_mention') && _msg?.attachmentURL ? (
                            <>
                              <video
                                src={getImagePath(_msg?.attachmentURL)}
                                controls={true}
                                style={{ maxHeight: 200, objectFit: 'cover', marginBottom: 8 }}
                              ></video>
                            </>
                          ) : (_msg?.attachmentType === 'video' || _msg?.messageType === 'video') && _msg?.attachmentURL ? (
                            <>
                              <video
                                src={getImagePath(_msg?.attachmentURL)}
                                controls={true}
                                style={{ maxHeight: 200, objectFit: 'cover', marginBottom: 8 }}
                              ></video>
                            </>
                          ) : (_msg?.messageType === 'image' || _msg?.attachmentType === 'image') && _msg?.attachmentURL ? (
                            <CardImg
                              src={getImagePath(_msg?.attachmentURL)}
                              style={{
                                maxHeight: 200,
                                objectFit: 'cover',
                                borderRadius: 8,
                                marginBottom: 8,
                              }}
                            />
                          ) : _msg?.messageType === 'carousel' && _msg?.products?.length > 0 ? (
                            <div
                              style={{
                                display: 'flex',
                                overflowX: 'scroll',
                                scrollSnapType: 'x mandatory',
                                gap: _msg?.products?.length === 1 ? '0px' : '10px',
                                padding: _msg?.products?.length === 1 ? '0px' : '10px',
                                scrollbarWidth: 'none',
                              }}
                            >
                              {_msg?.products.map((product, index) => (
                                <div
                                key={index}
                                style={{
                                  flex: _msg?.products.length === 1 ? '0 0 100%' : '0 0 80%',  
                                  maxWidth: _msg?.products.length === 1 ? '100%' : '60%',  
                                  backgroundColor: isSend ? '#5068F2' : '#fff',
                                  color: isSend ? '#fff' : '#000',
                                  borderRadius: '10px',
                                  overflow: 'hidden',
                                  scrollSnapAlign: _msg?.products.length === 1 ? null : 'start',
                                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  height: 'auto',
                                }}
                              >
                                  {product?.image_url && (
                                    <img
                                      src={product.image_url}
                                      alt={product.name || 'Product'}
                                      style={{
                                        width: '100%',
                                        height: '150px',
                                        objectFit: 'cover',
                                      }}
                                    />
                                  )}
                          
                                  <div
                                    style={{
                                      padding: '10px',
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'space-between',
                                      flexGrow: 1,
                                    }}
                                  >
                                    {product?.name && (
                                      <h4
                                        style={{
                                          fontSize: '16px',
                                          margin: '0 0 10px',
                                          color: isSend ? '#fff' : '#000',
                                          height: '40px', 
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                          whiteSpace: 'normal',
                                        }}
                                      >
                                        {product.name}
                                      </h4>
                                    )}
                          
                                    {product?.description && (
                                      <p
                                        style={{
                                          fontSize: '14px',
                                          margin: '0 0 10px',
                                          color: isSend ? '#ccc' : '#555',
                                          height: '60px', 
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                          whiteSpace: 'normal', 
                                        }}
                                      >
                                        {product.description}
                                      </p>
                                    )}
                          
                                    {product?.price && (
                                      <p
                                        style={{
                                          fontSize: '16px',
                                          fontWeight: 'bold',
                                          margin: '0 0 10px',
                                          color: isSend ? '#fff' : '#000',
                                        }}
                                      >
                                        {product.price}
                                      </p>
                                    )}
                        
                                    <button
                                      style={{
                                        width: '100%',
                                        padding: '10px',
                                        border: 'none',
                                        borderRadius: '5px',
                                        backgroundColor: '#fff',
                                        color: '#000',
                                        cursor: product?.image_url ? 'pointer' : 'default',
                                        fontWeight: 'bold',
                                      }}
                                      onClick={() => {
                                        if (product?.image_url) {
                                          window.open(product?.image_url, '_blank');
                                        }
                                      }}
                                    >
                                      {'View Product'}
                                    </button>
                                  </div>
                          
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignSelf: 'flex-end',
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                      marginLeft: 'auto',
                                      justifyContent: 'flex-end',
                                      padding: '5px'
                                    }}
                                  >
                                    <p
                                      style={{
                                        margin: 0,
                                        textAlign: 'end',
                                        fontSize: 12,
                                        color: isSend ? colors.white : colors.black,
                                      }}
                                    >
                                      {Helper.getTime(_msg?.messageTime)}
                                    </p>
                                    {isSend ? (
                                      <BsCheck2All style={{ color: 'white' }} />
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : null}

                          <div
                            style={{
                              display: 'flex',
                              alignSelf: 'end',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <p
                              style={{
                                fontSize: 14,
                                color: isSend ? colors.white01 : colors.black,
                                fontWeight: 'normal',
                                margin: 0,
                                padding: 0,
                                alignSelf: 'flex-start',
                                marginRight: 20,
                                whiteSpace: 'pre-line',
                                flexWrap: 'wrap',
                              }}
                            >
                              {_msg?.messageText}
                            </p>
                          </div>
                        {_msg.buttons && _msg.buttons.length > 0 && _msg.buttons.map((button) => (
                          <div
                            className={`d-flex flex-row ${isSend ? 'justify-content-end' : 'justify-content-start'} px-1 py-1 mx-auto`}
                            style={{
                              width: '95%',
                              paddingRight: '0.75rem !important',
                              paddingLeft: '0.5rem !important',
                              display: 'flex',
                              justifyContent: 'center', 
                            }}
                          >
                            <div
                              style={{
                                width: '100%', 
                                maxWidth: '100%',
                                backgroundColor: isSend ? 'white' : '#5068F2', 
                                borderRadius: 8,
                                padding: 10,
                                alignSelf: 'center',
                                textAlign: 'center',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden', 
                                textOverflow: 'ellipsis', 
                                cursor: button?.type === 'web_url' ? 'pointer' : 'default',
                                justifyContent: 'center',
                              }}
                              onClick={() => {
                                if (button?.type === 'web_url' && button?.url) {
                                  window.open(button?.url, '_blank'); 
                                }
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignSelf: 'end',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                }}
                              >
                                <p
                                  style={{
                                    fontSize: 14,
                                    color: isSend ? 'black' : 'white', 
                                    fontWeight: 'normal',
                                    margin: 0,
                                    whiteSpace: 'nowrap', 
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden', 
                                  }}
                                >
                                  {button.title}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}                          
                        {_msg?.messageType!== "carousel" && <div
                            style={{
                              display: 'flex',
                              alignSelf: 'flex-end',
                              flexDirection: 'row',
                              alignItems: 'center',
                              marginLeft: 'auto',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <p
                              style={{
                                margin: 0,
                                textAlign: 'end',
                                fontSize: 12,
                                color: isSend ? colors.white : colors.black,
                              }}
                            >
                              {Helper.getTime(_msg?.messageTime)}
                            </p>
                            {isSend ? (
                              <BsCheck2All style={{ color: 'white' }} />
                            ) : (
                              <></>
                            )}
                          </div>}
                        </div>
                      </div>
                      {_msg?.repliedBy === 'workflows' && (
                        <p
                          style={{
                            textAlign: 'end',
                            fontStyle: 'italic',
                            fontSize: 12,
                            width: '97%',
                            marginTop: '-2px',
                            color: 'black',
                          }}
                        >
                          Sent via {_msg?.repliedBy}
                        </p>
                      )}
                      {_msg?.storyURL && (
                        <p
                          style={{
                            textAlign: 'start',
                            fontStyle: 'italic',
                            fontSize: 14,
                            width: '97%',
                            marginBottom: '4px',
                            color: '#666666',
                            marginLeft: '30px',
                          }}
                        >
                          Replied to your story
                        </p>
                      )}
                      {(_msg?.attachmentType === 'story_mention' || _msg?.messageType === "story_mention") && _msg?.attachmentURL && (
                        <p
                          style={{
                            textAlign: 'start',
                            fontStyle: 'italic',
                            fontSize: 14,
                            width: '97%',
                            marginBottom: '4px',
                            color: '#666666',
                            marginLeft: '30px',
                          }}
                        >
                          Mentioned you in story
                        </p>
                      )}
                    </div>
                  );
                })}
              <div style={{ height: 20, width: 100 }} ref={messagesEndRef}></div>
              {/* </div> */}
              {/* </div> */}
              {/* </div> */}
            </div>
            <div style={{ bottom: 2, width: '100%', borderTop: '1px solid #E6E6E6' }}>
              {
                <div>
                  {s3Link ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        paddingLeft: '5%',
                        paddingTop: '1%',
                      }}
                    >
                      { }
                      {mediaType === 'video' ? (
                        <>
                          <video
                            src={getImagePath(s3Link)}
                            style={{ height: 80, width: 'auto', objectFit: 'contain' }}
                            controls
                          />
                        </>
                      ) : mediaType === 'audio' ? (
                        <>
                          <audio
                            src={getImagePath(s3Link)}
                            style={{ maxWidth: '60%' }}
                            controls
                          />
                        </>
                      ) : (
                        <>
                          <img
                            src={getImagePath(s3Link)}
                            style={{ height: 80, width: 'auto', objectFit: 'contain' }}
                            alt="Preview"
                          />
                        </>
                      )}

                      <p style={{ margin: 0, marginLeft: 16 }}>{headerFileName}</p>
                      <img
                        onClick={() => {
                          setUpFile(null);
                          setUpFile64('');
                          setS3Link('');
                          setHeaderFileName('');
                        }}
                        src={images.CMClose}
                        style={{
                          height: 24,
                          width: 24,
                          marginLeft: 'auto',
                          marginRight: '15%',
                          objectFit: 'contain',
                        }}
                      />
                    </div>
                  ) : (
                    <></>
                  )}

                  <div
                    className="d-inline-flex flex-row justify-content-around align-self-center align-items-center px-2 py-2"
                    style={{ width: '100%', marginBottom: '-2px' }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        borderBottomWidth: 1,
                        borderColor: colors.black,
                        alignItems: 'center',
                        backgroundColor: colors.white,
                      }}
                    >
                      <Tooltip title="Save your responses here">
                        <CardImg
                          id="SavedResponses"
                          onClick={() => setShowSavedResponses(true)}
                          src={images.IconResponse}
                          style={{
                            height: 20,
                            width: 20,
                            marginRight: 20,
                            marginLeft: 20,
                            cursor: 'pointer',
                          }}
                        />
                      </Tooltip>
                      {showSavedResponse && (
                        <SavedResponses
                          setText={setText}
                          setShow={setShowSavedResponses}
                        ></SavedResponses>
                      )}
                      <CiFaceSmile
                        style={{
                          width: '28px',
                          height: '28px',
                          marginLeft: '-10px',
                          cursor: 'pointer',
                        }}
                        onClick={() => setShowEmoji(!showEmoji)}
                      ></CiFaceSmile>

                      {text !== '' && !showSavedResponse && e && (
                        <MatchResponses
                          text={text}
                          values={values}
                          setText={setText}
                          sendMessage={sendNewMessageAction}
                          event={e}
                        ></MatchResponses>
                      )}
                      {showEmoji && (
                        <div className="emojiMenu">
                          <Picker
                            data={data}
                            emojiSize={20}
                            theme={'light'}
                            previewPosition={'none'}
                            onEmojiSelect={handleEmojiSelect}
                          ></Picker>
                        </div>
                      )}
                      {!s3Link && 
                        <InputGroup className="mx-0 px-0 ">
                          <Form.Control
                            className="mainInput"
                            as="textarea"
                            placeholder="Type a message"
                            aria-label="Type message"
                            aria-describedby="input text area"
                            required
                            value={text}
                            ref={textareaRef}
                            onChange={(e) => {
                              setText(e.target.value);
                            }}
                            onKeyDown={(event) => {
                              if (event.key === 'Enter' && event.shiftKey) {
                              } else if (event.code === 'Enter') {
                                event.preventDefault();
                                sendNewMessageAction();
                              }
                              setE(event);
                            }}
                            style={{
                              resize: 'vertical',
                              borderWidth: 0,
                              color: colors.black,
                              // minHeight: 40,
                              height: 40,
                              borderBottom: '1px solid #666666',
                              width: '100%',
                              borderRadius: '0px',
                            }}
                          />
                        </InputGroup>
                      }
                      <input
                        id="MediaUpload"
                        type="file"
                        style={{ display: 'none' }}
                        ref={imageinputref}
                        accept="image/png, image/gif, image/jpg, image/jpeg, video/mp4, video/ogg, video/avi, video/webm, video/mov, audio/acc, audio/mp4, audio/wav, audio/m4a"
                        onChange={(e) => {
                          handlefileupload(e);
                        }}
                      />
                      <CardImg
                        onClick={() => imageinputref.current.click()}
                        src={images.CRphotoSelect}
                        style={{
                          cursor: 'pointer',
                          height: MobileWidth ? 18 : 24,
                          width: MobileWidth ? 18 : 24,
                          marginLeft: MobileWidth ? 4 : 5,
                          marginRight: MobileWidth ? 4 : 5,
                        }}
                      />
                    </div>
                    <CheerioButton
                      btnText={'Send'}
                      textStyle={{ color: colors.white }}
                      icon={images.msgSent}
                      backColor={colors.black}
                      borderStyle={{ borderWidth: 0, marginInlineEnd: 8 }}
                      id="SendWhatsAppMsgButton"
                      onclick={sendNewMessageAction}
                    />
                  </div>
                </div>
              }
            </div>
          </div>
        </>
      ) : (
        <div className="d-flex justify-content-center" style={{ marginTop: '30vh' }}>
          <p style={{ margin: 0, fontSize: 16, color: colors.darkgrey }}>No data available </p>
        </div>
      )
      }
    </>
  );
}

