import React, { useCallback, useEffect, useState } from 'react'
import colors from '../../../Utils/colors';
import { Handle, Position } from 'reactflow';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import images from '../../../Utils/images';
import { getImagePath } from '../../../Utils';
import CustomHandle from './CustomHandle';
import { BiCube } from 'react-icons/bi';

const handleStyle = {backgroundColor: '#8134AF',height:8,width:8,borderRadius:4};

const InstagramResponseNode = ({data, isConnectable}) => {

    useEffect(()=>{
        console.log("responseModalData ===> ",data);
    },[data])
    
    // const [optionsArr, setOptionsArr] = useState([{InputValue : 'Input 1'},{InputValue :'Input 2'},{InputValue :'Input 3'},{InputValue :'Input 4'}]);

    // useEffect(()=>{
    //     if(data?.options?.length > 0){
    //         setOptionsArr(data?.options);
    //     }else if(data?.options?.length === 0){
    //         setOptionsArr([])
    //     }
    // },[data?.options])

  return (
    <>
    
    <div className='d-flex flex-column justify-content-between align-items-center' style={{border:`1px solid ${colors.borderwhite}`,borderRadius:16,backgroundColor:colors.white01,width:250}}>
        <CustomHandle type='target' position={Position.Left} isConnectable={isConnectable} style={handleStyle} />
        <div className='w-100 d-flex flex-column justify-content-start align-items-center' style={{paddingInline:16,paddingBlock:8,background:'linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)',borderTopLeftRadius:16,borderTopRightRadius:16}}>
        {/* <div className='w-100 d-flex flex-column justify-content-start align-items-center' style={{paddingInline:16,paddingBlock:8,background:'#8134AF',borderTopLeftRadius:16,borderTopRightRadius:16}}> */}
            <div style={{height:4,width:'20%',borderRadius:2,backgroundColor:colors.white,marginBlockEnd:4}}></div>
            <div className='d-flex flex-row justify-content-between align-items-center w-100'>
                <p style={{padding:0,margin:0,fontSize:16,fontWeight:700,color:colors.white}}>
                { 
  data?.option 
    ? data?.option === 'replyinDM' 
      ? 'Reply in DM' 
      :  'Reply in Comments' 
    : 'Instagram DM Response' 
}
                </p>
                <img src={images.Edit} style={{height:20,width:20,objectFit:'contain',cursor:'pointer'}}></img>
            </div> 
        </div>
        <div className='w-100 d-flex flex-column justify-content-start align-items-center' style={{paddingInline:16,paddingBlockStart:8,paddingBlockEnd:16}}>
            {/* {(!data?.header || data?.header?.mediaFile) && 
            <>
            <div className='d-flex flex-row justify-content-start align-items-center w-100'>
                <p style={{padding:0,margin:0,marginBlock:4,fontSize:10,fontWeight:600,color:colors.black}}>
                    {data?.header?.mediaType ? data?.header?.mediaType : 'Image'}
                </p> 
                <img src={images.DownArrowTip} style={{height:14,width:14,objectFit:'contain',marginInlineStart:8}}></img>
            </div>
            <div className='my-1 d-flex flex-column justify-content-center align-items-center w-100' style={{height:80,border:`2px dashed #dde1e5`,borderRadius:8}}>
                {data?.header?.mediaFile ? (
                    <>
                    {data?.header?.mediaType === 'Image' ? (
                        <>
                        <img src={getImagePath(data?.header?.mediaFile)} style={{height:'100%',width:'100%',objectFit:'contain'}}></img>
                        </>
                    ): data?.header?.mediaType === 'Document' ? (
                        <>
                        <img src={images.CATREmail} style={{height:'100%',width:'100%',objectFit:'contain'}}></img>
                        </>
                    ): data?.header?.mediaType === 'Video' && (
                        <>
                        <video src={getImagePath(data?.header?.mediaFile)} style={{height:'100%',width:'80%',objectFit:'contain'}}></video>
                        </>
                    )}
                    </>
                ):(
                    <>
                    <img src={images.Upload} style={{height:30,width:40,objectFit:'contain'}}></img>
                    <p style={{padding:0,margin:0,fontSize:10,fontWeight:400,color:colors.greys04}}>
                        {'Upload Image'}
                    </p>
                    </>
                )}
                
            </div>
            </>
            } */}
            

            <p style={{padding:0,margin:0,marginBlock:4,fontSize:10,fontWeight:600,color:colors.black,width:'100%'}}>
            {data?.type === "text" ? (
        <>
            {data?.message?.length > 100 ? (
                <>
                    {data?.message.slice(0, 100)}...
                    <span style={{ color: "blue", cursor: "pointer" }}>
                        Read more
                    </span>
                </>
            ) : (
                data?.message
            )}
        </>
    ) : data?.type === "image" ? (
        <div>
            <img
                src={data?.mediaUrl}
                alt="Uploaded content"
                style={{ maxWidth: "100%", borderRadius: "8px" }}
            />
        </div>
    ) : 
    data?.type === "video" ? (
        <div>
            <video
                src={data?.mediaUrl}
                alt="Uploaded content"
                style={{ maxWidth: "100%", borderRadius: "8px" }}
            />
        </div>
    ):
    data?.type === "audio" ? (
        <div>
            <audio
                src={data?.mediaUrl}
                alt="Uploaded content"
                style={{ maxWidth: "100%", borderRadius: "8px" }}
                controls
            />
        </div>
    ):
    data.type === "card" ? (
        <div>
            <div>
                    <div
                    style={{
                        fontWeight:400,
                    }}
                    >
 {data?.description?.length > 100 ? (
                <>
                    {data?.description.slice(0, 100)}...
                    <span style={{ color: "blue", cursor: "pointer" }}>
                        Read more
                    </span>
                </>
            ) : (
                data?.description
            )}
                    </div>
                {data.buttons.map((button, index) => (
                    <div key={index} style={{
                        background: '#FAFAFA',
                        color: 'black',
                        padding: '4px 8px',
                        borderRadius: '4px',
                        fontSize: '12px',
                        display:'flex',
                        justifyContent:'center',
                        fontWeight:400,
                        alignItems:'center',
                        marginTop: '4px',
                        cursor: 'pointer'
                    }}>
                        {button.type === 'postback' && (
                            <CustomHandle
                                type="source"
                                position={Position.Right}
                                id={`child_node_${index}`}
                                style={{...handleStyle,top:'auto',bottom:`calc( ${index}*16 )`}}
                                isConnectable={1}
                            />
                        )}
                        {button.title}
                    </div>
                ))}
            </div>
        </div>
    ):
    data.type === "products" ? (
        <div>
              <div>
      <div style={{
        fontWeight: 400,
        marginBottom: '8px'
      }}>
        <BiCube size={12} style={{
            marginBottom:2
        }}/> {data.productDetails.length} carousel product{data.productDetails.length !== 1 ? 's' : ''} catalog
      </div>

      {data.productDetails.length > 0 && data.productDetails.find(p => p.id === data.productDetails[0].id) && (
        <div style={{
          display: 'flex',
          flexDirection:'column',
          gap: '12px',
          background: '#FAFAFA',
          padding: '8px',
          borderRadius: '8px',
          marginBottom: '8px'
        }}>
          <img 
            src={data?.productDetails[0]?.image_url}
            alt=""
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: '4px'
            }}
          />
          <div>
            <div style={{ fontWeight: 500 }}>
              {data?.productDetails[0]?.name}
            </div>
            <div style={{ color: '#666', fontSize: '12px' }}>
              {data?.productDetails[0]?.price}
            </div>
          </div>
        </div>
      )}
    </div>
        </div>
    ):
    data.option === "replyinComments" ? (
        <div>
            <div>
                {data.comments.map((comment, index) => (
                    <div key={index} style={{
                        background: '#FAFAFA',
                        color: 'black',
                        padding: '4px 8px',
                        borderRadius: '4px',
                        fontSize: '12px',
                        display:'flex',
                        justifyContent:'center',
                        fontWeight:400,
                        alignItems:'center',
                        marginTop: '4px',
                        cursor: 'pointer'
                    }}>
                        {comment?.text ? comment.text : "Comment Text"}
                    </div>
                ))}
            </div>
        </div>
    )  

    :(
        <p>Select Action</p>
    )}
            </p>

            {/* <InputGroup className="my-1">
                <Form.Control as="textarea" rows={4} placeholder={'Enter here'} className='w-100 btncustom'
                style={{borderRadius:8,fontSize:10,fontWeight:400,color:colors.black}}
                // isInvalid={(renewalText.length >= 640)}
                maxLength={640}
                value={data?.message ? data?.message : ''}
                // onChange={onChange}
                />
            </InputGroup> */}

            {/* <div className='d-flex flex-row justify-content-between align-items-center w-100'>
                <p style={{padding:0,margin:0,marginBlock:4,fontSize:10,fontWeight:600,color:colors.black}}>
                    {'Configure Input options'}
                </p>
                <div className='d-flex justify-content-center align-items-center'>
                    <div style={{height:10,width:10,border:`1px solid ${colors.black}`,marginInlineEnd:6}}></div>
                    <p style={{padding:0,margin:0,marginBlock:4,fontSize:10,fontWeight:600,color:colors.black}}>
                        {'Skip'}
                    </p>
                </div>
            </div> */}

            {/* {optionsArr && optionsArr.map((item,index)=>{
                return(
                    <>
                    <div key={`key=${index+1}`} className='d-flex flex-row justify-content-start align-items-center w-100'  style={{borderRadius:8,backgroundColor:colors.white03,marginBlock:4,paddingBlock:6,paddingInline:16}}>
                        <p style={{padding:0,margin:0,fontSize:12,fontWeight:600,color:colors.black}}>
                            {`${index+1}. ${item?.InputValue}`}
                        </p>
                        <CustomHandle
                            type="source"
                            // position={Position.Right}
                            position={Position.Right}
                            id={`child_node_${index}`}
                            style={{...handleStyle,top:'auto',bottom:`calc( ${index}*16 )`}}
                            isConnectable={1}
                        />
                    </div>
                    
                    </>
                )
            })} */}

            {/* {optionsArr.length !== 0 && 
            <div className='d-flex flex-row justify-content-center align-items-center w-100'  style={{borderRadius:8,backgroundColor:colors.white03,marginBlock:4,paddingBlock:6,paddingInline:16}}>
                <p style={{padding:0,margin:0,fontSize:12,fontWeight:600,color:colors.black}}>
                    {'Add option'}
                </p>
            </div>
            } */}
            

            {(data.type !== "card" || !data.buttons || data.buttons.length === 0) && 
    <CustomHandle 
        type="source" 
        position={Position.Right} 
        style={{...handleStyle}} 
        isConnectable={1}
    />
}
            

        </div>
    </div>
    </>
  )
}

export default InstagramResponseNode