import React, { useEffect, useRef, useState } from 'react';
import colors from '../../../Utils/colors';
import { FiShoppingCart } from 'react-icons/fi';
import { BsBank } from 'react-icons/bs';
import { RiGraduationCapLine } from 'react-icons/ri';
import { FaRegBuilding } from 'react-icons/fa';
import { FaRegPlusSquare } from 'react-icons/fa';
import { BsPeople } from 'react-icons/bs';
import { FiVideo } from 'react-icons/fi';
import { RiCarLine } from 'react-icons/ri';
import { Button } from 'react-bootstrap';
import CheerioButton from '../../../Components/CheerioButton';
import { useNavigate } from 'react-router-dom';
import images from '../../../Utils/images';
import { emailTemplateData } from './EmailTemplateDesign';
import { useDispatch, useSelector } from 'react-redux';
import { V2EmailBuilderAdd, V2EmailBuilderList, getEmailBuilderListAll,getEmailBuilderDetailsByID } from '../../../Services';
import { toast } from 'react-toastify';
import EmailBuild from './EmailBuild';

const EmailTemplates = () => {
  const [selectedIndustry, setSelectedIndustry] = useState('All');
  const [filteredData, setFilteredData] = useState([]);
  const [designs, setDesigns] = useState();
  const authtoken = useSelector((state) => state.main.auth_token);
  // const [json, setJson] = useState();
  // const [showBuild, setShowBuild] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getDesignAPIcall();
  }, []);

  const getDesignAPIcall = () => {
    let token = authtoken;
    getEmailBuilderListAll(token,true).then((res) => {
      if (res?.flag) {
        setDesigns(res?.data);
      } else {
        console.log('error response ===> ', res);
      }
    });
  };

  const SidemenuItem = ({ ImageComponent, ItemText }) => {
    return (
      <>
        <div className="w-100 px-2 mt-3">
          <div
            className="d-flex flex-row justify-content-start align-items-center w-100 px-2 py-2"
            style={{
              borderRadius: 10,
              backgroundColor: selectedIndustry === ItemText ? colors.primary01 : '',
              cursor: 'pointer',
            }}
            onClick={() => {
              setSelectedIndustry(ItemText);
            }}
          >
            {ImageComponent}
            <h4
              style={{
                color: selectedIndustry === ItemText ? colors.primary04 : colors.black,
                marginInlineStart: 8,
              }}
            >
              {ItemText}
            </h4>
          </div>
        </div>
      </>
    );
  };

  // const duplicateEmail = (item) => {
  //   let data = {
  //     emailJSON: item.emailJSON,
  //     emailName: `${item.emailName} copy`,
  //   };
  //   let token = authtoken;
  //   V2EmailBuilderAdd(data, token).then((res) => {
  //     if (res?.flag) {
  //       console.log('success response ===> ', res);
  //       dispatch({ type: 'addEmailTemplate', payload: res?.data });
  //       navigate('/creator/design');
  //     } else {
  //       console.log('error response ===> ', res);
  //       toast(`Error occured while saving template. Please try again later`, {
  //         position: 'top-center',
  //       });
  //     }
  //   });
  // };

  const TemplateItem = ({ item }) => {
    return (
      <>
        <div className="w-50 px-2 py-2" style={{}}>
          <div
            className=" align-items-center w-100 h-100"
            style={{
              borderRadius: 16,
              border: `1px solid ${colors.borderwhite}`,
              backgroundColor: colors.white01,
            }}
          >
            <div
              className="d-flex flex-column justify-content-center align-items-center h-100"
              style={{ paddingBlock: 12, paddingInline: 16 }}
            >
              {/* <h4>{item?.templateName}</h4>
              <p
                className="mt-2 w-100"
                style={{
                  color: colors.greys04,
                  whiteSpace: 'pre-line',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {item?.body}
              </p> */}
              <img style={{ width: '50%', height: '80vh' }} src={item?.image}></img>
            </div>
            <div
              style={{ paddingBlock: 12, paddingInline: 16 }}
              className="d-flex flex-row justify-content-end"
            >
              <CheerioButton
                borderStyle={{ width: '30%' }}
                textStyle={{ color: colors.white }}
                btnText={'Use Template'}
                backColor={colors.black}
                onclick={async () => {
                  const elem = designs?.filter((val) => val.emailName === item?.templateName);
                  if (elem?.length > 0) {
                    try {
                      let token = authtoken;  
                      const templateId = elem[0]._id; 
                      const response = await getEmailBuilderDetailsByID({ _id: templateId, useTemplate: true }, token);
                      if (response && response.status === 200 && response.flag && response.data) {
                        const detailedTemplate = response.data;
                        const obj = {
                          ...detailedTemplate, 
                          emailName: `${detailedTemplate.emailName} copy`,
                        };

                        dispatch({ type: 'addSelectedEmailJson', payload: obj });
                        navigate('/creator/emailTemplates/edit');
                      } else {
                        toast.error('There is an error in using this template!', {
                          position: 'top-center',
                        });
                      }
                    } catch (error) {
                      toast.error('Failed to load template details. Please try again.', {
                        position: 'top-center',
                      });
                    }
                  } else {
                    toast.error('Template not found!', {
                      position: 'top-center',
                    });
                  }
                }}
              />

            </div>
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    if (selectedIndustry === 'All') {
      setFilteredData(emailTemplateData);
    } else if (selectedIndustry) {
      setFilteredData(emailTemplateData.filter((item) => item.industry === selectedIndustry));
    }
  }, [selectedIndustry]);

  return (
    <>
      {
        <div className="d-flex flex-row justify-content-between align-items-center w-100 h-100">
          <div
            id="WTSidemenu"
            className="h-100 d-flex flex-column justify-content-start align-items-center"
            style={{ width: 220, overflowY: 'auto', border: `1px solid ${colors.borderwhite}` }}
          >
            <div
              className="d-flex justify-content-center align-items-center w-100 py-3"
              style={{ borderBottom: `1px solid ${colors.borderwhite}`, cursor: 'pointer' }}
              onClick={() => {
                setSelectedIndustry('All');
              }}
            >
              <h4 style={{ color: selectedIndustry === 'All' ? colors.primary04 : colors.black }}>
                {'All templates'}
              </h4>
            </div>
            {/* <SidemenuItem
            ImageComponent={
              <FiShoppingCart
                size={18}
                color={selectedIndustry === 'Ecommerce' ? colors.primary04 : colors.black}
              />
            }
            ItemText={'Ecommerce'}
          />
          <SidemenuItem
            ImageComponent={
              <BsBank
                size={18}
                color={selectedIndustry === 'Banking' ? colors.primary04 : colors.black}
              />
            }
            ItemText={'Banking'}
          />
          <SidemenuItem
            ImageComponent={
              <RiGraduationCapLine
                size={18}
                color={selectedIndustry === 'Education' ? colors.primary04 : colors.black}
              />
            }
            ItemText={'Education'}
          />
          <SidemenuItem
            ImageComponent={
              <FaRegBuilding
                size={18}
                color={selectedIndustry === 'Real Estate' ? colors.primary04 : colors.black}
              />
            }
            ItemText={'Real Estate'}
          />
          <SidemenuItem
            ImageComponent={
              <FaRegPlusSquare
                size={18}
                color={selectedIndustry === 'Healthcare' ? colors.primary04 : colors.black}
              />
            }
            ItemText={'Healthcare'}
          /> */}
            {/* <SidemenuItem 
                ImageComponent={<BsPeople size={18} color={selectedIndustry === 'Services' ? colors.primary04 : colors.black}/>}
                ItemText={'Services'}
            /> */}
            {/* <SidemenuItem
            ImageComponent={
              <FiVideo
                size={18}
                color={selectedIndustry === 'Webinar' ? colors.primary04 : colors.black}
              />
            }
            ItemText={'Webinar'}
          /> */}
            {/* <SidemenuItem 
                ImageComponent={<RiCarLine size={18} color={selectedIndustry === 'Automobile' ? colors.primary04 : colors.black}/>}
                ItemText={'Automobile'}
            /> */}
          </div>
          <div
            id="WTWorkspace"
            className="h-100 py-2 px-2 d-flex flex-row justify-content-start align-items-start"
            style={{
              width: 'calc( 100% - 220px )',
              flexWrap: 'wrap',
              overflowY: 'auto',
              alignContent: 'flex-start',
            }}
          >
            {filteredData &&
              filteredData.map((item, index) => {
                return <TemplateItem item={item} />;
              })}
          </div>
        </div>
      }
    </>
  );
};

export default EmailTemplates;
