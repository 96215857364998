import React, { useEffect, useState } from 'react'
import colors from '../../../Utils/colors'
import images from '../../../Utils/images'
import CheerioButton from '../../../Components/CheerioButton'
import { TextField } from '@mui/material';
import SpinnerLoader from '../../../Components/SpinnerLoader';
import { useSelector } from 'react-redux';
import { sendExcelAnnouncement } from '../../../Services';

const ExcelAnnouncementModal = ({ setIsModalOpen , labelData }) => {
    const authtoken = useSelector((state) => state.main.auth_token);
    const [currentScreen, setCurrentScreen] = useState('Start');
    const [CName, setCName] = useState('')
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(()=>{
        console.log("111111111 ",labelData);
    },[])

    const sendExcelAnnouncementAPIcall = () => {
        setCurrentScreen('Loading')
        let token = authtoken;
        let data = {
            labelId: labelData?._id,
            campaignName: CName,
            foreignKey: "loid"
        }
        const successTimeout = setTimeout(() => {
            setCurrentScreen('Success');
        }, 3000);
        sendExcelAnnouncement(data,token).then((res)=>{
            clearTimeout(successTimeout);
            if(res?.flag){
                // setTimeout(() => {
                //     setCurrentScreen('Success')
                // }, 1000);
            }else{
                // setErrorMessage(res?.message ?? 'Unexpected error occurred')
                // setTimeout(() => {
                //     setCurrentScreen('Failure')
                // }, 1000);
            }
            setTimeout(() => {
                setCurrentScreen('Success');
            }, 1000);
        })
    }


  return (
    <>
    <div className='DarkBg'>
        <div className='Center'>
            <div style={{
                width: 582,
                height: 400,
                backgroundColor: colors.white01,
                borderRadius:16,
                zIndex:0,
                border:`1px solid ${colors.borderwhite}`,
                paddingBlock:30,
                paddingInline:40,
                overflowY: 'auto',
            }}>
                <div className='Column w-100 h-100 justify-content-between'>
                    <div className='Row w-100 justify-content-between' style={{
                        borderBottom:`1px solid ${colors.borderwhite}`,
                        paddingBlockEnd:16,
                        marginBlockEnd:16
                    }}>
                        <h3>
                            {'Run a campaign'}
                        </h3>
                        {currentScreen !== 'Loading' && 
                        <img src={images.CMClose} style={{
                            height:20,
                            width:20,
                            objectFit:'contain',
                            cursor:'pointer',
                        }} onClick={()=>{setIsModalOpen(false)}}></img>
                        }
                    </div>
                    {currentScreen == 'Start' ? (
                        <>
                        <div className='Column w-100' style={{
                            flex:1,
                            justifyContent:'flex-start',
                        }}>
                            <p style={{fontWeight:500,width:'100%'}}>
                                {'Campaign Name'}
                            </p>
                            <TextField
                                name="campaignName"
                                value={CName}
                                onChange={(e)=>{setCName(e.target.value)}}
                                type='text'
                                size='small'
                                style={{
                                    marginBlockStart: 8,
                                    marginBlockEnd:16,
                                    width: '100%',
                                }}
                                className='btncustom'
                            />
                            <p style={{fontWeight:500,width:'100%'}}>
                                {'Label'}
                            </p>
                            <TextField
                                name="label"
                                value={labelData?.listName}
                                type='text'
                                size='small'
                                style={{
                                    marginBlockStart: 8,
                                    marginBlockEnd:16,
                                    width: '100%',
                                }}
                                className='btncustom' 
                                inputProps={{readOnly:true}}
                            />
                        </div>
                        </>
                    ) : currentScreen == 'Loading' ? (
                        <>
                        <div className='Column justify-content-center w-100' style={{
                            flex:1,
                        }}>
                            <SpinnerLoader />
                            <h3>
                                {'Sending messages'}
                            </h3>
                            <p>
                                {"Please wait we’re sending messages"}
                            </p>
                        </div>
                        </>
                    ) : currentScreen == 'Success' ? (
                        <>
                        <div className='Column justify-content-center w-100' style={{
                            flex:1,
                        }}>
                            <img src={images.CircleCheckGreen} style={{
                                height:140,
                                width:140,
                                objectFit:'contain',
                            }}></img>
                            <h3 className='my-2'>
                                {'Messages are being sent'}
                            </h3>
                            <p>
                                {'We’re sending your messages. Once it is completed we will send you a notification'}
                            </p>
                        </div>
                        </>
                    ) : currentScreen == 'Failure' && (
                        <>
                        <div className='Column justify-content-center w-100' style={{
                            flex:1,
                        }}>
                            <img src={images.ErrorImg} style={{
                                height:140,
                                width:140,
                                objectFit:'contain',
                            }}></img>
                            <h3 className='my-2'>
                                {'Something went wrong! Please Retry'}
                            </h3>
                            <p>
                                {errorMessage}
                            </p>
                        </div>
                        </>
                    )}
                    {(currentScreen === 'Start' || currentScreen === 'Failure') && 
                        <CheerioButton 
                            borderStyle={{borderWidth:0,width:'100%',marginBlockStart:16}} 
                            textStyle={{color:colors.white01}}
                            backColor={colors.primary03}
                            btnText={currentScreen === 'Start' ? 'Run Campaign' : currentScreen === 'Failure' && 'Retry Campaign'}
                            onclick={()=>{
                                sendExcelAnnouncementAPIcall();
                            }}
                            disabled={CName && labelData?.listName ? false : true}
                        />
                    }
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default ExcelAnnouncementModal