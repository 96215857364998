import React, { useState, useEffect } from 'react';
import { PremiumOptionModal } from '../../Components/Modal/PremiumOptionModal';
import { useSelector } from 'react-redux';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { fetchGlobalAnalytics } from '../../Services/index.js';
import { Tooltip } from '@mui/material';
import { toast } from 'react-toastify';
import { trackPages } from '../../Utils/brevo';
import colors from '../../Utils/colors';
import images from '../../Utils/images';
import CheerioButton from '../../Components/CheerioButton';
import { MailRecordsCsv } from './MailRecordsCsv';
import { hiddenName, hiddenPhone } from '../ContactModule/ContactsAll/variableDependencies';
import {getLocaltime} from'../../Utils/localdate'
import BetaFeatureBanner from '../../Components/BetaFeatureBanner';
const GlobalAnalytics = ({ excepted }) => {
  const localdata = useSelector((state) => state.main.localdata);
  const isPremiumUser = localdata?.premium;
  const authtoken = useSelector((state) => state.main.auth_token);
  const [recordsData, setRecordsData] = useState([]);
  const [showMailRecords, setShowMailRecords] = useState(false);
  const agentdata = useSelector((state) => state.main.agentdata);
  const [pagination, setPagination] = useState({
    page: 0,
    limit: 20,
    totalRows: 1,
  });
  const [loading, setLoading] = useState(true);
  const [searchBy, setSearchBy] = useState('Name');
  const [searchQuery, setSearchQuery] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const getData = () => {
    const data = {
      page: pagination.page, limit: pagination.limit, searchField: mapSearchField(searchBy),
      searchQuery: searchQuery
    };
    fetchGlobalAnalytics(data, authtoken)
      .then((res) => {
        if (res.flag) {
          setRecordsData(res?.data?.docs);
          console.log('res?.data?', res?.data);
          setPagination({
            ...pagination,
            totalRows: res?.data?.total ?? 0,
          });
          setLoading(false);
        } else {
          setLoading(false);
          toast.error('Something went wrong');
        }
      })
      .catch((e) => {
        setLoading(false);
        toast.error('Something went wrong');
      });
  };

  useEffect(() => {
    trackPages('analytics_global_analytics_page', localdata);
    if (agentdata && !agentdata?.permission?.globalAnalytics) {
      toast.error(
        'Feature access denied. Kindly request the owner to grant you permission to access this feature'
      );
      navigate('/creator');
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    getData();
  }, [pagination.page, pagination.limit, searchBy, searchQuery]);
  const mapSearchField = (field) => {
    switch (field) {
      case 'Name':
        return 'user_name';
      case 'Mobile':
        return 'phoneNumber';
      case 'Error':
        return 'errorLog';
      default:
        return '';
    }
  };
  const columns = [
    {
      field: 'user_name',
      headerName: 'Name',
      width: 180,
      valueFormatter: (params) => {
        return params?.value === '' ? 'NA' : params?.value;
      },
      renderCell: (params) => {
        return params?.value === '' ? 'NA' : excepted ? hiddenName(params?.value) : params?.value;
      },
      editable: false,
    },
    {
      field: 'phoneNumber',
      headerName: 'Mobile',
      width: 180,
      valueFormatter: (params) => {
        return params?.value === '' ? 'NA' : params?.value;
      },
      renderCell: (params) => {
        return excepted ? hiddenPhone(params?.value) : params?.value === '' ? 'NA' : params?.value;
      },
      editable: false,
    },
    {
      field: 'msg',
      headerName: 'Message',
      width: 180,
      valueFormatter: (params) => {
        return params?.value === '' ? 'NA' : params?.value;
      },
      renderCell: (params) => {
        <Tooltip
          sx={{
            '& .MuiTooltip-tooltip': {
              padding: '8px !important',
              borderRadius: '8px !important',
            },
          }}
          title={params.value}
        >
          <span>{params.value}</span>
        </Tooltip>;
      },
      editable: false,
    },
    {
      field: 'status_updatedAt.sentAt',
      headerName: 'Sent',
      type: 'string',
      width: 200,
      editable: false,
      valueGetter: (params) => {
        return params?.row?.status_updatedAt?.sentAt;
      },
      valueFormatter: (params) => params?.value ?? 'NA',
      renderCell: (params) => {
        if (!params?.value) return 'NA';    
        const formattedDate = getLocaltime(params?.value);
        return (
          <div
          style={{
            whiteSpace: 'normal',   
            wordWrap: 'break-word',     
          }}
          >
            {formattedDate}
          </div>
        );
    },            
    },
    {
      field: 'status_updatedAt.deliveryAt',
      headerName: 'Delivery',
      type: 'string',
      width: 200,
      editable: false,
      valueGetter: (params) => {
        return params?.row?.status_updatedAt?.deliveryAt;
      },
      valueFormatter: (params) => params?.value ?? 'NA',
      renderCell: (params) => {
        if (!params?.value) return 'NA';    
        const formattedDate = getLocaltime(params?.value);
        return (
          <div
          style={{
            whiteSpace: 'normal',   
            wordWrap: 'break-word',     
          }}
          >
            {formattedDate}
          </div>
        );
    },    
    },
    {
      field: 'status_updatedAt.readAt',
      headerName: 'Opened',
      type: 'string',
      width: 200,
      editable: false,
      valueGetter: (params) => {
        return params?.row?.status_updatedAt?.readAt;
      },
      valueFormatter: (params) => params?.value ?? 'NA',
      renderCell: (params) => {
        if (!params?.value) return 'NA';    
        const formattedDate = getLocaltime(params?.value);
        return (
          <div
          style={{
            whiteSpace: 'normal',   
            wordWrap: 'break-word',     
          }}
          >
            {formattedDate}
          </div>
        );
    },  
    },
    {
      field: 'status_updatedAt.clickAt',
      headerName: 'Click',
      type: 'string',
      width: 200,
      editable: false,
      valueGetter: (params) => {
        return params?.row?.status_updatedAt?.clickAt;
      },
      valueFormatter: (params) => params?.value ?? 'NA',
      renderCell: (params) => {
        if (!params?.value) return 'NA';    
        const formattedDate = getLocaltime(params?.value);
        return (
          <div
          style={{
            whiteSpace: 'normal',   
            wordWrap: 'break-word',       
          }}
          >
            {formattedDate}
          </div>
        );
      },
    },
    {
      field: 'errorLog',
      headerName: 'Error Log',
      width: 240,
      valueFormatter: (params) => {
        return params?.value === '' ? 'NA' : params?.value;
      },
      renderCell: (params) => {
        <Tooltip
          sx={{
            '& .MuiTooltip-tooltip': {
              padding: '8px !important',
              borderRadius: '8px !important',
            },
          }}
          title={params.value ?? 'NA'}
        >
          <span>{params.value ?? 'NA'}</span>
        </Tooltip>;
      },
      editable: false,
    },
  ];

  return (
    <>
      {!isPremiumUser && (
        <PremiumOptionModal
          option={'Global Analytics'}
          openPremiumModal={true}
          setOpenPremiumModal={() => { }}
        ></PremiumOptionModal>
      )}

      <div className="announcement_container">
        <div className="announcement_container__header_container">
          {/* <div className="" style={{ ...Styles.Row, width: '100%' }}> */}
          <h3>{'Global Analytics'}</h3>
          {/* <CheerioButton
                    borderStyle={{}}
                    textStyle={{ color: colors.white }}
                    btnText={'New Campaign'}
                    backColor={colors.black}
                    onclick={() => {
                        setOpenANModal(true);
                    }}
                    icon={images.IPlusWhite}
                /> */}
          <div>
            {!excepted && (agentdata?.permission?.globalAnalyticsReport || !agentdata) && (
              <CheerioButton
                borderStyle={{ border: "none" }}
                textStyle={{ color: colors.white }}
                btnText={'Export Report'}
                backColor={colors.darkPurple}
                onclick={() => {
                  //   getOverallAnalyticsAPICall(userID);
                  setShowMailRecords(true);
                }}
                icon={images.ANExport}
              />
            )}
          </div>
        </div>
        {/* <BetaFeatureBanner 
          BannerText={"We are experiencing some issues in analytics and campaign data. Please do not re-run your campaigns. Inconvenience is regretted and issue will be resolved soon."}
        /> */}
        <div style={{ display: 'flex', alignItems: 'center', gap: '17px', paddingLeft: '68%' }}>
          <div style={{ position: 'relative' }}>
            <button
              style={{
                backgroundColor: '#FAFAFA',
                border: '1px solid #ccc',
                borderRadius: '8px',
                padding: '12px 12px',
                fontSize: '14px',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              Search by: <span style={{ marginLeft: '8px', color: '#666666' }}>{searchBy}</span>
              <span style={{ marginLeft: '5px', fontSize: '10px' }}>▼</span>
            </button>
            {isDropdownOpen && (
              <div style={{
                position: 'absolute',
                top: '100%',
                left: '0',
                backgroundColor: 'white',
                border: '1px solid #ccc',
                borderRadius: '8px',
                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                zIndex: 1,
              }}>
                <div style={{ padding: '8px 12px', cursor: 'pointer' }} onClick={() => { setSearchBy('Name'); setIsDropdownOpen(false); }}>Name</div>
                <div style={{ padding: '8px 12px', cursor: 'pointer' }} onClick={() => { setSearchBy('Mobile'); setIsDropdownOpen(false); }}>Mobile</div>
                <div style={{ padding: '8px 12px', cursor: 'pointer' }} onClick={() => { setSearchBy('Error'); setIsDropdownOpen(false); }}>Error</div>
              </div>
            )}
          </div>
          <div style={{ display: 'flex', alignItems: 'center', border: '1px solid #ccc', borderRadius: '8px', overflow: 'hidden' }}>
            <input
              type="text"
              placeholder="Search"
              style={{
                border: 'none',
                outline: 'none',
                padding: '12px 12px',
                backgroundColor: '#FAFAFA',
                fontSize: '14px',
                flexGrow: 1,
              }}
              value={searchQuery}
              onChange={(e) => {
                const sanitizedValue = e.target.value.replace(/[^a-zA-Z0-9 ]/g, '');
                setSearchQuery(sanitizedValue);
              }}
            />
            <button
              style={{
                backgroundColor: '#FAFAFA',
                border: 'none',
                padding: '8px 12px',
                cursor: 'pointer',
              }}
              onClick={() => getData()}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <circle cx="11" cy="11" r="8"></circle>
                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
              </svg>
            </button>
          </div>
        </div>
        {showMailRecords && (
          <MailRecordsCsv show={showMailRecords} setShow={setShowMailRecords}></MailRecordsCsv>
        )}

        <div className="w-100 h-100 px-0" style={{ paddingInline: 0, paddingBlock: 20 }}>
          <DataGrid
            rows={recordsData}
            columns={columns}
            getRowId={(row) => row._id}
            loading={loading}
            // slots={{ toolbar: GridToolbar }}
            rowCount={pagination.totalRows}
            pageSizeOptions={[10, 20, 25, 50, 75, 100]}
            paginationMode="server"
            paginationModel={{
              page: pagination.page,
              pageSize: pagination.limit,
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: 'name', sort: 'desc' }],
              },
            }}
            slotProps={{
              toolbar: {
                csvOptions: {
                  allColumns: true,
                  fields: ['Name', 'Mobile', 'Sent', 'Delivered', 'Opened', 'Clicked', 'Replied'],
                },
              },
            }}
            onPaginationModelChange={(params) => {
              setPagination({
                ...pagination,
                page: params.page,
                limit: params.pageSize,
              });
            }}
          />
        </div>
      </div>
    </>
  );
};

export default GlobalAnalytics;
