import React from 'react';
import { saveFbToken } from '../../Services';
import CheerioButton from '../../Components/CheerioButton';
import { toast } from 'react-toastify';
import colors from '../../Utils/colors';
import images from '../../Utils/images';

export const MessengerReplies = () => {
  return (
    <div className="d-flex" style={{ width: '100%', height: '100%' }}>
      <div
        className="d-flex flex-column align-items-center"
        style={{
          width: '30%',
          paddingTop: '20%',
          textAlign: 'center',
          color: 'grey',
          borderRight: '1px solid #CDCDCD',
        }}
      >
        Your chats will appear here
      </div>
      <div style={{ padding: '20px', border: '' }}>
        <p style={{ fontSize: '20px', fontWeight: '700' }}>What you need?</p>
        <p style={{ marginTop: '10px', fontWeight: '700', fontSize: '16px', color: '#666666' }}>
          Pre-Installation
        </p>
        <div style={{ marginTop: '10px', color: '#666666' }}>
          <p>Before you can install Facebook Messenger Messaging, you need to have:</p>
          <div style={{ paddingLeft: '10px' }}>
            <p>
              1. An Facebook Business account.<br></br>
              2. A linked Facebook page with admin access.<br></br>
              3. Messaging permissions enabled.
            </p>
          </div>
          <p style={{ marginTop: '15px', fontWeight: '700', fontSize: '16px', color: '#666666' }}>
            Installation
          </p>
          <p>
            Log in below and select your account in the modal. Please ensure that your Facebook
            business account is associated to your Facebook Business page.
          </p>

          <div className="d-flex" style={{ marginTop: '20px' }}>
            <p style={{ fontWeight: '700', fontSize: '16px', color: 'black' }}>Need help?</p>
            <p
              onClick={() => {
                window.open(
                  'https://developers.facebook.com/docs/messenger-platform/#messenger-platform',
                  '_blank'
                );
              }}
              style={{
                marginLeft: '5px',
                fontWeight: '400',
                fontSize: '16px',
                color: '#3366CC',
                cursor: 'pointer',
              }}
            >
              Read documentation
            </p>
          </div>
          <img
            src={images.InstaContinueButton}
            style={{
              height: '20%',
              borderRadius: '10px',
              width: '23%',
              marginTop: '2%',
              border: 'none',
              cursor: 'pointer',
            }}
            onClick={() => {
              window.FB.login(
                function (response) {
                  console.log({
                    response,
                  });
                  if (response.authResponse) {
                    toast.success('Successfully got the token', { position: 'top-center' });
                  } else {
                    console.log('User cancelled login or did not fully authorize.');
                  }
                },
                {
                  scope:
                    'pages_show_list,business_management,pages_messaging,instagram_basic,instagram_manage_messages,pages_manage_metadata,pages_read_engagement,instagram_manage_comments,instagram_manage_insights,catalog_management,instagram_content_publish',
                }
              );
            }}
          />
          <div>
            <p>After process completion it will look like this:</p>
            <img style={{ width: '70%' }} src={images.InstaPreview}></img>
          </div>
        </div>
      </div>
    </div>
  );
};
