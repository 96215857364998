import { useEffect, useState } from 'react';
import './index.scss';
import '../../sass/pages/_contacts.scss';
import CheerioButton from '../../Components/CheerioButton';
import images from '../../Utils/images';
import colors from '../../Utils/colors';
import { AiOutlineSearch } from 'react-icons/ai';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FiEdit2, FiTrash2 } from 'react-icons/fi';
import { Tooltip } from '@mui/material';
import AddKeywordAction from './Components/AddKeywordAction';
import { useSelector } from 'react-redux';
import { deleteKeywordsAction, getKeywordsByID, getKeywordsList } from '../../Services';
import { toast } from 'react-toastify';
import Helper from '../../Utils/Helper';
import { trackPages } from '../../Utils/brevo';
import { eventsend } from '../../Config/analyticsFunctions';
import { useNavigate } from 'react-router-dom';

const columns = [
  { id: 'keywords', label: 'Keyword', align: 'left', minWidth: 100, width: 200 },
  // { id: 'Triggered', label: 'Triggered', align: 'center', minWidth: 100, width: 120 },
  { id: 'keywordCriteria', label: 'Matching', align: 'center', minWidth: 100, width: 120 },
  { id: 'action', label: 'Reply Action', align: 'left', maxWidth: 100, width: 150 },
  { id: 'Action', label: 'Action', align: 'center', maxWidth: 100, width: 120 },
];

// const rows = [
//     {
//         Keyword: ['Hello',],
//         Triggered: 2,
//         Matching: 'Exact',
//         ReplyAction: ['Send Message',],
//     },
//     {
//         Keyword: ['GoodbyeGoodbye', 'Bye', 'Goodbye', 'ByeBye', 'Goodbye', 'Bye'],
//         Triggered: 95,
//         Matching: 'Partial',
//         ReplyAction: ['Send'],
//     },
//     {
//         Keyword: ['HelpHelpHelp'],
//         Triggered: 2525,
//         Matching: 'Exact',
//         ReplyAction: ['GoodbyeGoodbye', 'Bye', 'Goodbye', 'ByeBye', 'Goodbye', 'Bye'],
//     },
// ];

const Keywords = () => {
  const [actionButtons, setActionButtons] = useState([<FiEdit2 />, <FiTrash2 />]); // [edit, delete
  const [searchKeyword, setSearchKeyword] = useState('');
  const [addKeywordAction, setAddKeywordAction] = useState(false);
  const [rows, setRows] = useState([]);
  const authtoken = useSelector((state) => state.main.auth_token);
  const [existingDataKA, setExistingDataKA] = useState({});
  const localdata = useSelector((state) => state.main.localdata);
  const isPremiumUser = localdata?.premium;
  const agentdata = useSelector((state) => state.main.agentdata);
  const navigate = useNavigate();

  useEffect(() => {
    trackPages('automation_keywords_page', localdata);
    if (agentdata && !agentdata?.permission?.keywordAutomation) {
      toast.error(
        'Feature access denied. Kindly request the owner to grant you permission to access this feature'
      );
      navigate('/creator');
    }
    // if (!isPremiumUser) {
    //   const defaultData = [
    //     {
    //       _id: '6662970755a55bbac6d4d8f7',
    //       action: 'assignWorkflow',
    //       keywords: ['Efyd1'],
    //       keywordCriteria: 'exact match',
    //       data: {
    //         key: 'Workflow',
    //         workflowId: '656864b2dbd034fe17c5bf8c',
    //       },
    //       status: true,
    //       createdAt: '2024-06-07T05:13:43.470Z',
    //       updatedAt: '2024-06-07T05:25:01.928Z',
    //       __v: 0,
    //     },
    //   ];
    //   setRows(defaultData);
    // }
    getKeywordsListAPICall();
  }, []);

  const getKeywordsListAPICall = () => {
    let token = authtoken;
    getKeywordsList(token).then((res) => {
      if (res?.flag) {
        setRows(res?.data);
        // success
      } else {
        toast.error(res, { position: 'top-right' });
      }
    });
  };

  const handleSearchChange = (e) => {
    setSearchKeyword(e.target.value);
  };
  const filteredRows =
    searchKeyword.trim() === ''
      ? rows
      : rows?.filter((row) => {
        const keywords = row?.keywords?.map((kw) => kw?.toLowerCase()) || [];
        return keywords.some((keyword) =>
          keyword?.toLowerCase()?.includes(searchKeyword?.toLowerCase())
        );
      });

  const onclickAction = (i, row) => {
    if (i == 0) {
      handleEditClick(row);
      eventsend("Edit_keyword_Keywords_page_clicked");
    } else if (i == 1) {
      handleDeleteClick(row);
      eventsend("Delete_keyword_Keywords_page_clicked");
    }
  };

  const handleEditClick = (row) => {
    // Handle edit click for the row
    console.log('Edit clicked for row:', row);
    Helper.HelperKeywordActionID = row?._id;
    Helper.HelperEditKeywordAction = true;
    setExistingDataKA(row);
    setAddKeywordAction(true);
    // let data = {
    //     _id: row?._id,
    // }
    // getKeywordsByID(authtoken,data).then((res)=>{
    //     if(res?.flag){
    //         console.log("success by ID ----> ",res);
    //     }else{
    //         console.log("error by ID -----> ",res);
    //     }
    // })
  };

  const handleDeleteClick = (row) => {
    let data = {
      _id: row?._id,
    };
    deleteKeywordsAction(authtoken, data).then((res) => {
      if (res?.flag) {
        toast.success('Keyword action deleted successfully', {

          position: 'top-right',
        });
        getKeywordsListAPICall();
      } else {
        toast.error('Failed to delete keyword action, please try again later', {

          position: 'top-right',
        });
      }
    });
  };

  return (
    <>
      {addKeywordAction ? (
        <>
          <AddKeywordAction
            setAddKeywordAction={setAddKeywordAction}
            saveSuccessAction={() => {
              setTimeout(() => {
                getKeywordsListAPICall();
                setAddKeywordAction(false);
              }, 1500);
            }}
            existingDataKA={existingDataKA}
          />
        </>
      ) : (
        <>
          <div className="KeywordsDiv">
            <div className="flex">
              <div className="pt-2">
                <h1>Keyword Triggers</h1>
                <p className="note">
                  Note: when a message matches keyword template/ flow gets triggered and send
                  message
                </p>
              </div>

              <div className="search_container">
                <div
                  className="search_upload_row flex"
                  style={{ flexWrap: 'wrap', paddingBlock: 4, gap: '16px' }}
                >
                  <div className="logged_input">
                    <AiOutlineSearch size={18} />
                    <input
                      type={'text'}
                      className="search_input"
                      placeholder={`Search Keyword`}
                      value={searchKeyword}
                      onChange={handleSearchChange}
                    />
                  </div>
                  <div>
                    <CheerioButton
                      borderStyle={{
                        marginInlineStart: 6,
                        paddingInline: 10,
                        paddingBlock: 7,
                        borderRadius: 8,
                        border: "none"
                      }}
                      textStyle={{
                        fontWeight: 600,
                        color: colors.white01,
                      }}
                      btnText={'Add Keyword'}
                      icon={images.AddIcon}
                      backColor={colors.darkPurple}
                      onclick={() => {
                        Helper.HelperEditKeywordAction = false;
                        setExistingDataKA({});
                        eventsend("Add_keyword_Keywords_page_clicked");
                        setAddKeywordAction(true);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="table">
              <TableContainer sx={{ height: '100%', overflow: 'auto' }}>
                {/* <TableContainer sx={{ flex: 1, height: "520px", overflow: "auto" }}> */}
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            width: column.width,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                            backgroundColor: colors.white04,
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredRows.map((row, index) => (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <>
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{
                                  minWidth: column.minWidth,
                                  width: column.width,
                                }}
                              >
                                {column.id === 'keywords' ? (
                                  <div
                                    style={{
                                      color: colors.black,
                                      fontSize: 14,
                                      display: 'flex',
                                      flexWrap: 'wrap',
                                    }}
                                    className="keywordData"
                                  >
                                    {value.map((v, i) => (
                                      <Tooltip title={v} key={i}>
                                        <span className={'keyword'}>
                                          {v.length > 20 ? v.slice(0, 18) + '...' : v}
                                        </span>
                                      </Tooltip>
                                    ))}
                                  </div>
                                ) : column.id === 'action' ? (
                                  <span className="replyAction">{value}</span>
                                ) : column.id === 'Action' ? (
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      gap: 15,
                                    }}
                                  >
                                    {actionButtons.map((btn, i) => (
                                      <Tooltip title={i === 0 ? 'Edit' : 'Delete'} key={i}>
                                        <div
                                          style={{ cursor: 'pointer' }}
                                          onClick={() => onclickAction(i, row)}
                                        >
                                          {btn}
                                        </div>
                                      </Tooltip>
                                    ))}
                                  </div>
                                ) : (
                                  <p style={{ color: colors.black, fontSize: 14 }}>{value}</p>
                                )}
                              </TableCell>
                            </>
                          );
                        })}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Keywords;
