import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';
import colors from '../../../Utils/colors';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import SpinnerLoader from '../../../Components/SpinnerLoader';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const GraphComponent = ({ graphTimeframe, setGraphTimeframe, graphData, graphIsLoading, isShopify=false}) => {

    const [labels, setLabels] = useState([]);

    const graphTimeframeArr = [
        {id: '1w', label: '1 week'},
        {id: '4w', label: '4 weeks'},
        {id: '3m', label: '3 months'},
        {id: '6m', label: '6 months'},
    ]

    const options = {
        responsive: true,
        maintainAspectRatio: isShopify ? false : true,
        plugins: {
          legend: {
            position: 'top',
          },
        },
    };

    useEffect(()=>{
        if(graphTimeframe === '1w'){
            setLabels(['Day 1','Day 2','Day 3','Day 4','Day 5','Day 6','Day 7'])
        }else if(graphTimeframe === '4w'){
            setLabels(['Week 1','Week 2','Week 3','Week 4'])
        }else if(graphTimeframe === '3m'){
            setLabels(['Week 1-2','Week 3-4','Week 5-6','Week 7-8','Week 8-10','Week 10-12'])
        }else if(graphTimeframe === '6m'){
            setLabels(['Month 1','Month 2','Month 3','Month 4','Month 5','Month 6'])
        }
    },[graphTimeframe])

    const data = {
        labels,
        datasets: [
          {
            label: isShopify ? 'Returns' : 'Created',
            data: graphData?.length > 0 && isShopify ? graphData.map((item,index)=> item.revenueGenerated).reverse() : graphData?.length > 0 && graphData.map((item,index)=> item.totalTickets).reverse(),
            backgroundColor: '#3EE831',
          },
          {
            label: isShopify ? 'Invested' : 'Resolved',
            data: graphData?.length > 0 && isShopify ? graphData.map((item,index)=> item.costSpent).reverse() : graphData?.length > 0 && graphData.map((item,index)=> item.resolvedTickets).reverse(),
            backgroundColor: '#7C33E2',
          },
        ],
      };

  return (
    <>
    <div className='graph_component_container' style={{width:isShopify && '100%'}}>
        <div className='graph_component_div shadow_small'>
            <div className='graph_component_div__row'>
                <h3>
                    {isShopify ? 'Return on Investment' : 'Ticket Management'}
                </h3>
                {isShopify ? (
                    <>
                    <p style={{fontSize: 14, fontWeight: 400, color: colors.greys01}}>
                        {'Last 7 days'}
                    </p>
                    </>
                ):(
                    <Form.Select
                        type="text"
                        placeholder="time"
                        className="btncustom dropdown"
                        style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.greys01, width:'auto' }}
                        value={graphTimeframe}
                        onChange={(e) => {
                            setGraphTimeframe(e.target.value);
                        }}
                    >
                        {graphTimeframeArr.map((item,index)=>{
                            return(
                                <option 
                                    style={{marginTop: 4, marginBottom: 4, borderRadius: 8}}
                                    value={item.id}
                                    key={item.id}
                                >
                                    {item.label}
                                </option>
                            )
                        })}
                    </Form.Select> 
                )}    
            </div>
            <div className='graph_component_div__graph'>
                {graphIsLoading ? (
                    <>
                    <SpinnerLoader />
                    </>
                ):(
                    <>
                    <Bar options={options} data={data} height={isShopify && '300px'}/>
                    </>
                )}
            </div>
        </div>
    </div>
    </>
  )
}

export default GraphComponent