export function getLocaltime(params){  
    const gmtDate = new Date(params);
    const localDateTime = gmtDate.toLocaleString();
    const timeZoneAbbreviation = gmtDate.toLocaleTimeString(undefined, { timeZoneName: 'short' }).split(' ').pop();    
    return `${localDateTime} ${timeZoneAbbreviation}`;
}

export const getUserDateTimeSettings = () => {
    try {
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return { timeZone: timeZone || 'UTC' };
    } catch (error) {
      console.error('Error getting timezone:', error);
      return { timeZone: 'UTC' };
    }
  };