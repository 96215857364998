import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import CheerioButton from '../../Components/CheerioButton';
import images from '../../Utils/images';
import { useNavigate } from 'react-router';
import { mailRecords } from '../../Services';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { ValidateEmail } from '../Chatbot/Setupbot';
import { NewLoader } from '../../Components/SpinnerLoader';
import DatePicker from 'react-datepicker';
import {getUserDateTimeSettings} from "../../Utils/localdate"
export const MailRecordsCsv = ({
    show,
    setShow
}) => {
    const [success, setSuccess] = useState(false);
    const auth_token = useSelector((state) => state.main.auth_token);
    const localdata = useSelector((state) => state.main.localdata);
    const [email, setEmail] = useState(localdata?.email ? localdata?.email : '');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [maxDateTime, setMaxDateTime] = useState('');
    const { timeZone } = getUserDateTimeSettings();

    //const [isLoading,setIsLoading] = useState(false);

    useEffect(() => {
        if (show) {
            const currentDateTime = new Date().toISOString().slice(0, 16);
            setMaxDateTime(currentDateTime);
            console.log(currentDateTime);
        }
    }, [show]);

    const formatDateTime = (dateTime) => {
        const date = new Date(dateTime);
        return date.toISOString().split('.')[0] + 'Z';
    };


    const mailRecordsCsvApiCall = () => {
        const data = {
            email: email,
            startTime: formatDateTime(startDate),
            endTime: formatDateTime(endDate),
            timeZone
        };
        mailRecords(data, auth_token).then((res) => {
            if (res?.flag) {
                console.log('global analytics csv', res);
                setSuccess(true);
            } else {
                console.log('err in global analytics csv', res);
            }
        });
    };

    return (
        <div>
            <Modal
                show={show}
                aria-labelledby="example-custom-modal-styling-title"
                dialogClassName="my-modal"
                contentClassName="mailCsv"
            >
                <Modal.Body style={{ paddingX: '20px' }}>
                    {/* { isLoading ? <div className="d-flex flex-column align-items-center" style={{height:"100%",margin:"30%"}}>
        <NewLoader></NewLoader>
      </div> :<> */}
                    <div className="d-flex justify-content-between ">
                        <p style={{ fontWeight: '700', fontSize: '24px' }}>Export Report</p>

                        <div className="d-flex flex-row">
                            <img
                                src={images.CMClose}
                                onClick={() => setShow(false)}
                                style={{
                                    cursor: 'pointer',
                                    height: 22,
                                    width: 22,
                                    marginInlineEnd: 8,
                                }}
                            ></img>
                        </div>
                    </div>
                    <hr style={{ marginTop: '-2px' }}></hr>
                    {success ? (
                        <>
                            <div className="d-flex align-items-center flex-column" style={{ marginTop: '50px' }}>
                                <img
                                    src={images.CircleCheckGreen}
                                    style={{
                                        alignItems: 'center',
                                        marginInlineEnd: 8,
                                    }}
                                ></img>
                                <p style={{ fontWeight: '700', fontSize: '16px', marginTop: '20px' }}>
                                    Report Shared Successfully
                                </p>
                                <p style={{ fontSize: '14px', color: 'grey', marginTop: '' }}>
                                    Kindly check your email.
                                </p>
                            </div>

                            <div style={{ marginLeft: '10px', marginTop: '30px' }}>
                                <p style={{ fontSize: '12px', color: 'grey' }}>
                                    <i>
                                        Please Note:-Please anticipate a potential delay in the delivery of the email,
                                        especially in the case of a substantial report(~approx 30mins). We kindly
                                        request your patience during this period... If not received try again.
                                    </i>
                                </p>
                                <CheerioButton
                                    className="button"
                                    borderStyle={{
                                        height: '36px',
                                        borderRadius: '9px',
                                        width: '95%',
                                        marginBlockStart: 12,
                                        marginBlockEnd: 8,
                                        marginBottom: '20px',
                                    }}
                                    textStyle={{ color: 'black', fontSize: 16, fontWeight: 600 }}
                                    btnText={'Resend Email'}
                                    backColor={'white'}
                                    onclick={() => {
                                        mailRecordsCsvApiCall();

                                        setShow(false);
                                        toast.success('Report Sent', { position: 'top-center' });
                                    }}
                                />
                            </div>
                        </>
                    ) : (
                        <div>
                            <div class="form-group mt-4">
                                <label
                                    for="exampleFormControlInput1"
                                    style={{ fontWeight: '700', fontSize: '14px', color: 'black' }}
                                >
                                    Email Id
                                </label>
                                <input
                                    class="form-control shadow-none"
                                    placeholder="john@cheerio.in"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <label
                                    style={{ color: 'grey', fontSize: '14px', fontWeight: '400', marginLeft: '10px' }}
                                >
                                    You will receive your report on this email
                                </label>
                            </div>
                            <div className="form-group mt-4">
                                <label
                                    htmlFor="startDatePicker"
                                    style={{ fontWeight: '700', fontSize: '14px', color: 'black' }}
                                >
                                    Start Date
                                </label>
                                <input
                                    type="datetime-local"
                                    id="startDatePicker"
                                    className="form-control shadow-none"
                                    value={startDate}
                                    max={maxDateTime}
                                    onChange={(e) => setStartDate(e.target.value)}
                                />
                            </div>
                            <div className="form-group mt-4">
                                <label
                                    htmlFor="endDatePicker"
                                    style={{ fontWeight: '700', fontSize: '14px', color: 'black' }}
                                >
                                    End Date
                                </label>
                                <input
                                    type="datetime-local"
                                    id="endDatePicker"
                                    className="form-control shadow-none"
                                    value={endDate}
                                    max={maxDateTime}
                                    onChange={(e) => setEndDate(e.target.value)}
                                />
                            </div>

                            <div style={{ marginLeft: '10px', marginTop: '10rem' }}>
                                <CheerioButton
                                    className="button"
                                    borderStyle={{
                                        height: '36px',
                                        borderRadius: '9px',

                                        marginBlockStart: 12,
                                        marginBlockEnd: 8,
                                        marginBottom: '20px',
                                        border: 'none',
                                    }}
                                    disabled={!startDate || !endDate}
                                    textStyle={{ color: 'white', fontSize: 16, fontWeight: 600 }}
                                    btnText={'Send Report'}
                                    backColor={'#8E53F7'}
                                    onclick={() => {
                                        mailRecordsCsvApiCall();
                                        setSuccess(true);
                                    }}
                                />
                            </div>
                        </div>
                    )}
                    {/* </>} */}
                </Modal.Body>
            </Modal>
        </div>
    );
};
