import React, { useEffect, useState } from 'react';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import { GetTemplatesV2API, fetechTemplatesApi } from '../../../Services';
import { useSelector } from 'react-redux';
import { AnnTemplateItem, SpinnerComponent } from './NewComponents';
import { Autocomplete, InputAdornment, TablePagination, TextField } from '@mui/material';
import '../styles.css';
import { AiOutlineSearch } from 'react-icons/ai';
import { eventsend } from '../../../Config/analyticsFunctions';

const AnnTemplate = ({ onclickAction }) => {
  const authtoken = useSelector((state) => state.main.auth_token);
  const localdata = useSelector((state) => state.main.localdata);
  const [isRightButtonEnabled, setIsRightButtonEnabled] = useState(false);
  const [isLoadingTemplates, setIsLoadingTemplates] = useState(false);
  const [templateData, setTemplateData] = useState(null);
  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [before, setBefore] = useState('');
  const [after, setAfter] = useState('');
  const [searchValue, setSearchValue] = useState('');
  //const [tempData,setTempData] = useState();

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };

  useEffect(() => {
    eventsend("WhatsApp_templates_Campaign_pop_up_clicked");
    setIsLoadingTemplates(true);
    GetTemplatesV2APIcall();
  }, []);

  //  useEffect(()=>{
  //   if(templateData){
  // //     const data = templateData;

  // //   if(searchValue!==""){

  // //     const newArr = templateData.filter((data)=>searchValue === data.name);
  // //     if(newArr.length>0){
  // //       setTemplateData(newArr);
  // //     }else{
  // //       setTemplateData(tempData);
  // //     }

  // //   }else{
  // //     setTemplateData(tempData);

  // console.log("searc",searchValue);
  //   }

  //  },[searchValue])

  const GetTemplatesV2APIcall = () => {
    let token = authtoken;
    const data = {
      limit: '500',
    };
    GetTemplatesV2API(data, token).then((res) => {
      if (res?.data) {
        setTemplateData(res?.data);
        const isNextPageAvailable = res?.data?.length + 1 === parseInt(data.limit);
        setIsRightButtonEnabled(isNextPageAvailable);
        //setTempData(res?.data);
        if (res?.paginate) {
          setBefore(res?.paginate?.before);
          setAfter(res?.paginate?.after);
        }
      } else {
        console.log('errror: ' + res);
      }
      setIsLoadingTemplates(false);
    });
  };

  const fetechTemplatesApiCall = (af, bef) => {
    const data = {
      before: bef,
      after: af,
      limit: '100',
    };
    fetechTemplatesApi(data, authtoken).then((res) => {
      if (res?.data.length) {
        console.log('success response: ', res);

        setTemplateData(res?.data);
        if (res?.paginate) {
          setBefore(res?.paginate?.before);
          setAfter(res?.paginate?.after);
        }
        setIsLoadingTemplates(false);
      } else if (res?.data.length === 0) {
        setTemplateData(res?.data);
        setIsLoadingTemplates(false);
        // if(data.after == ""){
        //   setAfter("");
        // }else{
        //   setBefore("");
        // }
      } else {
        console.log('error response: ', res);
      }
    });
  };

  return (
    <>
      {isLoadingTemplates ? (
        <>
          <SpinnerComponent />
        </>
      ) : (
        <>
          <div className="w-100 verythinscrollnew" style={{ flex: 1, overflowY: 'scroll' }}>
            {/* {templateData &&
      //        <TablePagination
      //   rowsPerPageOptions={[10, 25, 100]}
      //   component="div"
      //   count={templateData.length}
      //   rowsPerPage={rowsPerPage}
      //   page={page}
      //   onPageChange={handleChangePage}
      //   onRowsPerPageChange={handleChangeRowsPerPage}
      //   class='paginate'
      // />
    } */}
            {templateData && (
              <div
                className="d-flex justify-content-between"
                style={{ height: '25px', width: '95%', marginBottom: '20px' }}
              >
                <div style={{ width: '90%', height: '30px', marginLeft: '16px' }}>
                  {/* <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        disableClearable
        size='small'
        
        options={templateData.map((option) => option.name)}
        renderInput={(params) => (
          
        )}
      /> */}

                  {/* <TextField
          label="Search Campaign"
          fullWidth
            InputProps={{
              type: 'search', 
              startAdornment: (
                <InputAdornment position="start">
                  <img height="18px " src={images.searchBox}></img>
                </InputAdornment>
              ),
            }}
            value={searchValue}
             id="standard-size-small"
            size='small'
            onChange={(e)=>{
              setSearchValue(e.target.value)}}
            
          /> */}
                  <div className={'loggedInput'}>
                    <AiOutlineSearch size={18} />
                    <input
                      style={{ width: '100%' }}
                      className="input"
                      type={'text'}
                      placeholder={`Search for templates`}
                      onChange={(e) => {
                        setSearchValue(e.target.value);
                      }}
                    />
                  </div>
                </div>
                {localdata?.whatsapp_credentials?.is_ai_sensy_user && (
                  <div className="d-flex" style={{ width: '7%', marginTop: '6px' }}>
                    <img
                      src={images.Left}
                      style={{
                        color: '#3366CC',
                        cursor: 'pointer',
                        height: '24px',
                        marginRight: '16px',
                      }}
                      onClick={() => {
                        const af = '';
                        const bef = before;
                        fetechTemplatesApiCall(af, bef);
                      }}
                    ></img>
                    {/* <p style={{marginLeft:"5px",marginRight:"5px"}}>{"<-->"}</p> */}
                    <img
                      src={images.Right}
                      style={{ color: '#3366CC', cursor: 'pointer', height: '24px' }}
                      onClick={() => {
                      if (isRightButtonEnabled) { 
                        const af = after;
                        const bef = '';

                        fetechTemplatesApiCall(af, bef);
                      }
                      }}
                    ></img>
                  </div>
                )}
              </div>
            )}

            <div
              className="d-flex flex-row justify-content-between align-items-start w-100"
              style={{ flexWrap: 'wrap' }}
            >
              {localdata?._id === '636be67d42c9a5cb33f60d98' ? (
                <>
                  {templateData &&
                    templateData
                      .filter((value) => {
                        if (searchValue === '') {
                          return true;
                        }
                        if (value?.name.includes(searchValue)) {
                          return value;
                        } else {
                          return false;
                        }
                      })
                      .map((item) => {
                        return (
                          <>
                            <AnnTemplateItem item={item} onclickAction={onclickAction} />
                          </>
                        );
                      })}
                </>
              ) : (
                <>
                  {templateData && templateData.length > 0 ? (
                    templateData
                      .filter((item)=>{
                        return !item.components.some(component => {
                          if (component.type === 'BUTTONS') {
                              return component.buttons.some(button => {
                                  return button.type === 'URL' &&
                                         button.example && button.example.length > 0 &&
                                         button.url !== 'https://web.cheerio.in/ss/{{1}}';
                              });
                          }
                          return false;
                        });
                      })
                      .filter((value) => {
                        if (searchValue === '') {
                          return true;
                        }
                        if (value?.name.includes(searchValue)) {
                          return value;
                        } else {
                          return false;
                        }
                      })
                      // .filter((e) => {
                      //   return (
                      //     e.name !== 'customer_enquiry' &&
                      //     e.name !== 'renewal_remainder' &&
                      //     e.name !== 'renewal_reminder' &&
                      //     e.name !== 'share_info' &&
                      //     e.name !== 'thank_you_customer' &&
                      //     e.name !== 'additional_discount_offer' &&
                      //     e.name !== 'repeatcustomer_offer' &&
                      //     e.name !== 'repeatcustomer_offer2' &&
                      //     e.name !== 'customer_win_back' &&
                      //     e.name !== 'business_info_newsletter' &&
                      //     e.name !== 'welcome_insta_message_2' &&
                      //     e.name !== 'loyal_customers_new' &&
                      //     e.name !== 'discount_all_products_new' &&
                      //     e.name !== 'do_you_know' &&
                      //     e.name !== 'maintainance_update' &&
                      //     e.name !== 'new_product_service_marathi' &&
                      //     e.name !== 'reply_multiple' &&
                      //     e.name !== 'how_to_video' &&
                      //     e.name !== 'share_an_offer_media_button' &&
                      //     e.name !== 'new_product_media_button'
                      //   );
                      // })
                      .sort((a) => {
                        if (a.status === 'APPROVED') {
                          return -1;
                        }
                        if (a.status === 'REJECTED') {
                          return 1;
                        }
                        return 0;
                      })
                      .map((item) => {
                        return (
                          <>
                            <AnnTemplateItem item={item} onclickAction={onclickAction} />
                          </>
                        );
                      })
                  ) : (
                    <div style={{ textAlign: 'center', width: '100%' }}>No data found</div>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AnnTemplate;
