import React, { useEffect, useState } from 'react';
import './styles.css';
import colors from '../../Utils/colors';
import images from '../../Utils/images';
import SidemenuSS from './Components/Sidemenu';
import Setup from './Components/Setup';
import MessageTemplate from './Components/MessageTemplate';
import CustomersData from './Components/CustomersData';
import Flows from './Components/Flows';
import ShopifyAnalytics from './Components/ShopifyAnalytics';
import { useSelector } from 'react-redux';
import { PremiumOptionModal } from '../../Components/Modal/PremiumOptionModal';
import { eventsend } from '../../Config/analyticsFunctions';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { CodPrepaidFlow } from './Components/CodPrepaidFlow';

const ShopifyStore = () => {
  const [openSetup, setOpenSetup] = useState(true);
  const [openFlow, setOpenFlow] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState('');
  const [selectedCampaignFlow, setSelectedCampaignFlow] = useState('');
  const localdata = useSelector((state) => state.main.localdata);
  const isPremiumUser = localdata?.premium;
  const location = useLocation();
  const urlParams = location.pathname.split('/');
  const param = decodeURIComponent(urlParams[urlParams.length - 1]);
  console.log('Param', param);

  useEffect(() => {
    eventsend('shopify_store_pageview_web');
  }, []);

  useEffect(() => {
    if (urlParams.length < 5) {
      setSelectedMenuItem('Integration');
    } else if (param !== selectedMenuItem) {
      setSelectedMenuItem(param);
    }
  }, [urlParams]);

  console.log('selected menu item', selectedMenuItem);

  return (
    <>
      {!isPremiumUser && (
        <PremiumOptionModal
          option={'Shopify Store'}
          openPremiumModal={true}
          setOpenPremiumModal={() => {}}
        ></PremiumOptionModal>
      )}

      <div className="Row w-100 h-100" style={{}}>
        {/* Shopify Sidemenu */}
        <SidemenuSS
          openSetup={openSetup}
          setOpenSetup={setOpenSetup}
          openFlow={openFlow}
          setOpenFlow={setOpenFlow}
          selectedMenuItem={selectedMenuItem}
          setSelectedMenuItem={setSelectedMenuItem}
        />

        {/* Shopify Body */}

        <div className="Column justify-content-start h-100" style={{ flex: 1 }}>
          <Routes>
            <Route path="/" element={<Setup setSelectedMenuItem={setSelectedMenuItem} />} />

            {selectedMenuItem == 'Integration' && (
              <Route
                path="Integration"
                element={<Setup setSelectedMenuItem={setSelectedMenuItem} />}
              ></Route>
            )}
            {selectedMenuItem == 'Message template' && (
              <Route path="Message template" element={<MessageTemplate />}></Route>
            )}
            {selectedMenuItem == 'Customers data' && (
              <Route path="Customers data" element={<CustomersData />}></Route>
            )}
            {selectedMenuItem == 'Abandoned cart' && (
              <Route
                path="Abandoned cart"
                element={
                  <Flows
                    selectedMenuItem={selectedMenuItem}
                    setSelectedMenuItem={setSelectedMenuItem}
                    setSelectedCampaignFlow={setSelectedCampaignFlow}
                  />
                }
              ></Route>
            )}
            {selectedMenuItem == 'Flows' && (
              <Route
                path="Flows/*"
                element={
                  <Flows
                    selectedMenuItem={selectedMenuItem}
                    setSelectedMenuItem={setSelectedMenuItem}
                    setSelectedCampaignFlow={setSelectedCampaignFlow}
                  />
                }
              ></Route>

              // <FlowsNew></FlowsNew>
            )}
            {selectedMenuItem == 'Order confirmation' && (
              <Route path="flows" element={<Flows selectedMenuItem={selectedMenuItem} />}></Route>
            )}
            {selectedMenuItem == 'Analytics' && (
              <Route
                path="Analytics"
                element={<ShopifyAnalytics />}
              ></Route>
            )}
          </Routes>
        </div>
        {/* <EnableFlowModal></EnableFlowModal> */}
      </div>
    </>
  );
};

export default ShopifyStore;
